@import "../../../../common//assets/scss//main.scss";

// Payments Settings
.theme--dark .payment-module {
  background-color: #0a1929;
}

.arrow-text {
  padding-top: 5%;
  margin-bottom: 5%;
  font-weight: 600;
  font-family: "SegoeUI-SemiBold";
}

.theme--dark .arrow-text {
  color: white;
}

.arrow-payments {
  float: left;
  margin-right: 5%;
  width: 9%;
  height: 9%;
}

.img-foodorder {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  // background-color: #f0f0f0;
  display: flex;
  // justify-content: center;
  align-items: center;
  margin-right: 15px;
}
.food-payments {
  display: flex;
  align-items: center;
  padding: 2px;
}

.food-items {
  display: flex;
  align-items: center;
  padding: 8px 0;
  border-bottom: 1px solid #F5F6FB !important;
}

.food-itemslast {
  display: flex;
  align-items: center;
  padding: 8px 0;
}

.text-content {
  flex: 1;
}

.payments-orderpay {
  background-color: #fff !important;
  border-radius: 10px;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.15) !important;
  position: relative;
  padding: 8px;
  margin-top: 6%;
  margin-bottom: 5%;
}

.theme--dark .payments-orderpay {
  background-color: #02111f !important;
  color: white !important;
}

.payment-tittle {
  font-weight: 500;
  margin-left: 3%;
  font-family: "SegoeUI";
}

.cards-texts {
  text-align: center;
  color: #B0B4C0;
  margin-bottom: 5%;
  font-family: "SegoeUI";
}

.addtext-payment {
  font-weight: 500;
  color: #006B04;
  margin-bottom: 0%;
  font-family: "SegoeUI";
}

.link-testpayment {
  font-size: 12px;
  font-weight: 500;
  color: #B0B4C0;
  margin-left: 3%;
}

.close-btnmodal {
  float: right;
}

.form-group {
  margin-bottom: 20px;
  border-bottom: 1px solid #E8E9EE;
}

.form-control {
  width: 100%;
  padding: 10px;
  border: none !important;
}

.theme--dark .form-control {
  background-color: #0a1929 !important;
}

.addcard-btn {
  background-color: #006B04 !important;
  color: white;
  width: 100%;
  border: #006b04 !important;
}

.bottom-modal {
  position: fixed;
  bottom: -100%;
  left: 0;
  right: 0;
  margin: auto;
  animation: slide-up 0.5s forwards;
}

.modal-payments {
  // background-color: rgb(197, 191, 191);
  border-top-left-radius: 25px !important;
  border-top-right-radius: 25px !important;
}

.modal-paymentsdrk {
  background-color: #0a1929;
  color: white;
}

.theme--dark .bottom-modal {
  background-color: #02111f;
  position: fixed;
  bottom: -100%;
  left: 0;
  right: 0;
  margin: auto;
  animation: slide-up 0.5s forwards;
}

@keyframes slide-up {
  from {
    bottom: -100%;
  }

  to {
    bottom: 0%;
  }
}

.modalpayment-popup{
  width: 100% !important;
  max-width: 100%;
}
@media only screen and (min-width: 768px) and (max-width: 2440px) {

  .payments-orderpay {
    background-color: #FAFAFA!important;
    border-radius: 10px;
    position: relative;
    padding: 4% !important;
    margin-top: 6%;
    margin-bottom: 5%;
    box-shadow: none !important;
  }
  .food-items {
    display: flex;
    align-items: center;
    padding: 8px 0;
    border-bottom: none !important;
  }
 
 }