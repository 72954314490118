@import '../../common/assets/scss/main.scss';

.refund_expired{
    padding: 30px 0px;
    @include themed(){
        background-color: t("dark5");
        }

    .refund_img{
        width: 65%;
        display: flex;
        margin: 0px auto;
    }
    .refund_cnt{
       
        h4{
            text-align: center;
            color: #000000;

            @include themed(){
                color: t("whitecolor");
                 }
        }
    }
    .refund_sub{
        p{
            text-align: center;
            margin-top: 25px;
            
            @include themed(){
                color: t("whitecolor");
                 }
        }
    }
    .refund_btn{
        button{
            display: flex;
            margin: 0px auto;
            margin-top: 15px;
           background-color: #F62861;
           border: none;
           color: white;
            padding: 2px 15px;
            border-radius: 5px;
        }
    }
}
