@import "../../../common/assets/scss/main.scss";
$new-green: #9fe870;
$new-green-dark: #173303;

// common style
.card-title h5 {
  width: 50%;
  font-family: map-get($map: $geist-fonts, $key: ui-semibold) !important;
}

.fus-order-summary {
  color: #868686;

  .fus-order-summary-text {
    font-family: map-get($map: $geist-fonts, $key: ui-reqular) !important;
    color: #868686;
  }
}

.fus-bill-text {
  color: #868686;
  font-family: map-get($map: $geist-fonts, $key: ui-reqular) !important;
}

.payment-tab {
  .header-content {
    font-family: map-get($map: $geist-fonts, $key: ui-semibold) !important;
    color: #868686;
    font-size: 14px;
    margin: 0px 10px 0px 10px;

    .order-data {
      p {
        margin-bottom: 0px;
      }
    }
  }

  .checkoutimgs {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .checkoutimgschild {
      img {
        width: 25px;
        height: 25px;
        margin-left: 5px;
      }
    }
  }
}

.input-textarea {
  margin: 10px;

  textarea form textarea {
    border: 1px solid #cdcdcd;
    box-shadow: none;
    font-family: SegoeUI;
    font-size: 15px;
    height: 25px;
    outline: none;
  }
}

.fusion-voucher {
  border-bottom: 3px solid #eee;
  border-top: 3px solid #eee;
  padding-bottom: 10px;
  padding-top: 10px;

  .fus-voucher {
    color: #868686;
    font-family: map-get($map: $geist-fonts, $key: ui-reqular) !important;

    .voucher-form {
      display: flex;
      justify-content: space-between;
      background-color: #eee;

      input {
        padding: 5px 30px;
        display: block;
        color: #254452 !important;
        font-family: "Geist-UltraLight";
        font-size: 14px;
        outline: none;
        border: none;
        box-shadow: none;
        height: 38px;
        background-color: #eeeeee;
        border-radius: 5px;
      }

      .apply {
        cursor: pointer;
        border: none;
        padding: 5px 30px;
        font-size: 16px;
        color: #244453 !important;
        font-family: "Geist-SemiBold";
        border-radius: 6px;
        background-color: #eeeeee;
      }
    }

  }
}

//mobile header
.fus-checkout-page {
  @include themed() {
    background-color: t("dark4");
  }

  overflow: hidden;

  .fus-top-title {
    @include themed() {
      background-color: t("dark4") !important;
      color: t("whitecolor") !important;
      border: none !important;
    }
  }

  .scroll-header {
    position: fixed;
    top: 0px;
    z-index: 1021;
    width: 60%;
    background: #fff;
    padding: 17px 0px;
    height: 5%;

    // transform: translate(0px, -75px);
    // -webkit-transition: all 0.3s ease;
    // -moz-transition: all 0.3s ease;
    // -o-transition: all 0.3s ease;
    // -ms-transition: all 0.3s ease;
    // transition: all 0.3s ease;
    // opacity: 0.1;
    &.scroll-header-visible {
      transform: translate(0px, 0px);
      opacity: 1;
    }

    @include themed() {
      background-color: t("header-bg");
    }

    .scroll-header-content {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 10px;

      button {
        border: none;
        outline: none;
        background-color: transparent;
        border-radius: 50px;
        height: 25px;
        width: 25px;
        padding: 3px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #9ee86f;
        margin-top: 0px;

        svg {
          font-size: 18px;
          fill: #173303;
          margin-right: 2px;
        }
      }

      h3.resta-name {
        margin: 0px;
        font-family: map-get($map: $geist-fonts, $key: ui-semibold);
        line-height: normal;
        font-size: 17px;
        color: #173303;
        margin-bottom: 1px;
        text-transform: capitalize;

        @include themed() {
          color: t("whitecolor");
        }
      }
    }
  }
}

.deliveryCard {
  @include themed() {
    background-color: t("dark5");
    box-shadow: t("menu-shadow");
    border: none;
  }

  .preorder-box {
    .card-title {
      font-family: map-get($map: $geist-fonts, $key: ui-semibold);

      @include themed() {
        color: t("whitecolor") !important;
      }
    }
  }

  .delivery-detail {
    svg {
      @include themed() {
        color: t("whitecolor") !important;
      }
    }

    margin-bottom: 5px;

    .delivery-text {
      .delivery-text-postcode {
        font-family: map-get($map: $geist-fonts, $key: ui-semibold);

        @include themed() {
          color: t("whitecolor") !important;
        }
      }

      .delivery-sub-text-postcode {
        color: #868686 !important;
        font-family: "Geist-Regular";

        @include themed() {
          // color: #b2bac2 !important;
          margin-bottom: 3px;
        }
      }
    }
  }
}

.estimateCard {
  @include themed() {
    background-color: t("dark5");
    box-shadow: t("menu-shadow");
    border: none;
  }

  .estimateBody {
    h4 {
      text-align: left;
    }

    .estimateTitle {
      font-family: map-get($map: $geist-fonts, $key: ui-semibold) !important;

      @include themed() {
        color: t("whitecolor") !important;
      }
    }

    .estimate-detail {
      img {
        height: 32px;
        width: 32px;
        margin: 10px;

        @include themed() {
          color: t("whitecolor");
        }
      }

      svg {
        @include themed() {
          color: t("whitecolor") !important;
        }
      }

      .estimate-text {
        .estimate-text-postcode {
          @include themed() {
            color: t("whitecolor") !important;
          }

          font-family: map-get($map: $geist-fonts, $key: ui-reqular);
        }

        .estimate-text-sub {
          font-family: map-get($map: $geist-fonts, $key: ui-reqular);
          color: #868686;

          @include themed() {
            color: t("whitecolor") !important;
          }
        }
      }
    }
  }
}

.paymentCard {
  @include themed() {
    background-color: t("dark5");
    box-shadow: t("menu-shadow");
    border: none;
  }

  .paymentCardBody {
    .paymentCardTitle {
      font-family: map-get($map: $geist-fonts, $key: ui-semibold) !important;

      @include themed() {
        color: t("whitecolor") !important;
      }
    }

    .payment-detail {
      svg {
        @include themed() {
          color: t("whitecolor") !important;
        }
      }

      .payment-text {
        .payment-text-postcode {
          font-family: map-get($map: $geist-fonts, $key: ui-semibold);

          @include themed() {
            color: t("whitecolor") !important;
          }
        }
      }
    }
  }

  .payment-tab {
    .tabs-div {
      label {
        @include themed() {
          color: t("hovercolor");
          background-color: t("hoverbg") !important;
          border-color: t("b-color");
        }
      }
    }

    .selected-tab {
      .card-tab {
        .header {
          h5 {
            @include themed() {
              color: t("whitecolor");
            }
          }
        }

        .selected-tab-accord.accordion {
          .accordion-header {
            button {
              @include themed() {
                color: t("hovercolor") !important;
                background-color: t("hoverbg") !important;
                border-color: t("b-color");
              }
            }
          }

          .accordion-body {
            @include themed() {
              color: t("hovercolor");
              background-color: t("hoverbg") !important;
              border-color: t("b-color");
            }

            .form-label {
              font-family: map-get($map: $geist-fonts, $key: ui-thin);
            }
          }
        }
      }
    }
  }
}

.requestCard {
  @include themed() {
    background-color: t("dark5");
    box-shadow: t("menu-shadow");
    border: none;
  }

  .requestBody {
    .requestTitle {
      font-family: map-get($map: $geist-fonts, $key: ui-semibold) !important;

      @include themed() {
        color: t("whitecolor") !important;
      }

      img {
        height: 25px;

        @include themed() {
          color: t("whitecolor") !important;
        }
      }
    }

    .tip-text {
      img {
        height: 25px;

        @include themed() {
          color: t("whitecolor") !important;
        }
      }

      .tip-subtext {
        color: #868686;
        font-family: map-get($map: $geist-fonts, $key: ui-thin) !important;

        @include themed() {
          color: t("whitecolor") !important;
        }
      }
    }

    .request-detail {
      svg {
        @include themed() {
          color: t("whitecolor") !important;
        }
      }

      form.tips-form {
        label {
          @include themed() {
            color: #244453 !important;
          }
        }
      }

      .donate-change {
        .content {
          .form-check {
            .form-check-label {
              font-family: map-get($map: $geist-fonts, $key: ui-thin) !important;
              color: #868686 !important;

              @include themed() {
                color: t("whitecolor") !important;
              }
            }
          }
        }
      }
    }
  }
}

.fus-payment-bar {
  .fus-payment-card {
    @include themed() {
      background-color: t("dark5");
      box-shadow: t("menu-shadow");
      border: none;

      .card-body {
        .fus-bill-title {
          @include themed() {
            color: t("whitecolor") !important;
          }

          .fus-order-summary-title-text {
            @include themed() {
              color: t("whitecolor") !important;
            }
          }
        }
      }

      .fus-order-summary-title-text {
        @include themed() {
          color: t("whitecolor") !important;
        }
      }

      .fus-bill-title {
        @include themed() {
          color: t("whitecolor") !important;
        }
      }
    }
  }

  .fus-order-summary-title-text {
    @include themed() {
      color: t("whitecolor") !important;
    }
  }
}

.theme--dark .border-style .borders {
  @include themed() {
    border-color: #001e3c !important;
  }
}

//end common style
//xs device
@media only screen and (min-width: 320px) and (max-width: 420px) {
  .scroll-header-content {
    display: none;
  }

  .fus-checkout-page {
    background-color: #eee;

    .fus-checkout {
      background: rgb(243, 243, 243) !important;

      .fus-top-title {
        display: flex !important;
        padding: 11px 0px 10px 19px;

        .fus-client-img {
          width: 4rem;
          height: 60px;
        }

        .fus-client-title {
          font-family: map-get($map: $geist-fonts, $key: ui-semibold) !important;
          text-align: center;
          margin: 1rem;
        }

        .fus-payment-btn1 {

          //    margin-top: 1rem;
          button {
            background: var(--theme-background);
            width: 100%;
            border: 1px;
            line-height: 34px;
            font-size: 18px;
            bottom: 0;
            position: fixed;
            font-family: map-get($map: $geist-fonts, $key: ui-semibold) !important;
            color: var(--theme-color);
          }
        }
      }

      .fus-payment-btn {

        //    margin-top: 1rem;
        button {
          background: #9ee86f;
          width: 100%;
          border: 1px;
          line-height: 34px;
          font-size: 18px;
          bottom: 0;
          position: fixed;
          font-family: map-get($map: $geist-fonts, $key: ui-semibold) !important;
          color: var(--theme-color);
        }
      }

      .fus-delivery-bar {
        .fus-delivery-title {
          font-weight: bold;
          font-family: map-get($map: $geist-fonts, $key: ui-semibold) !important;
          margin-bottom: 1rem;
        }

        //    delivery card
        .deliveryCard {
          border-radius: 0;
          border-right: none;
          border-color: #fff;

          .preorder-box {
            display: flex;
            flex-direction: column-reverse;
            gap: 10px;

            .card-title {
              width: 100% !important;
              font-family: map-get($map: $geist-fonts, $key: ui-semibold);
              font-size: 16px;
              margin-top: 10px;
            }

            ul.nav.nav-tabs.nav-fill {
              width: 100% !important;
              background-color: #dee2e6;
              border-radius: 35px;
              padding: 4px;
              //   li.nav-item{
              //     &:first-child{
              //         border-radius: 50px 0 0 50px;
              //         border-right: none;
              //       }
              //       &:last-child{
              //         border-radius: 0 50px 50px 0;
              //         border-right: none;
              //       }
              //       button.nav-link {
              //         border-color: #173303 !important;
              //         border: 1px solid;
              //         border-radius: 50px;
              //         color: #173303;
              //         font-family: map-get($map:$geist-fonts, $key: ui-semibold) !important;
              //         font-size: 15px;
              //         height: 100%;
              //         line-height: normal;
              //       }
              //       button.nav-link.active {
              //         background: #173303;
              //         color:#fff;
              //       }

              //   }
              li.nav-item {
                &:first-child {
                  button.nav-link {
                    border: none;

                    &.disabled {
                      border: none !important;
                    }
                  }
                }

                &:last-child {
                  button.nav-link {
                    &.disabled {
                      border-left: 1px solid #6d6d6d !important;
                    }
                  }
                }

                button.nav-link {
                  // border-color: $new-green-dark !important;
                  // border: 1px solid;
                  border-radius: 50px;
                  color: $new-green-dark;
                  font-family: map-get($map: $geist-fonts, $key: ui-semibold);
                  font-size: 15px;
                  line-height: normal;
                  height: 100%;

                  &.disabled {
                    border: 1px solid #e0e0e0 !important;
                    color: #e0e0e0;
                  }

                  &.active {
                    // background-color: $new-green-dark;
                    // border: 1px solid $new-green-dark;
                    // color: $new-green;
                  }

                  &:hover {
                    // border-color: #102402 !important;
                    // background-color: #102402 !important;
                    // color: $new-green;
                  }
                }
              }

              .list-group-item.active {
                background: #fff !important;
                border-color: var(--theme-background) !important;
              }
            }
          }

          .delivery-detail {
            display: flex;
            // justify-content: space-between;
            //    padding: 2px;
            // margin-top: 10px;
            gap: 10px;
            margin-bottom: 13px;
            align-items: center;

            svg {
              margin: 5px;
              font-size: 20px;
            }

            img {
              height: 32px;
              width: 30px;

              @include themed() {
                color: t("whitecolor");
              }
            }

            .delivery-icons {
              width: 10%;
            }

            .delivery-text {
              width: 80%;
              font-family: map-get($map: $geist-fonts, $key: ui-semibold) !important;
              font-size: 14px;

              .delivery-text-postcode {
                margin-bottom: 2px;
                font-family: map-get($map: $geist-fonts, $key: ui-semibold) !important;
                font-size: 14px;
              }

              .delivery-sub-text-postcode {
                font-family: map-get($map: $geist-fonts, $key: ui-reqular) !important;
                color: #868686 !important;
                font-size: 12px;
                margin-bottom: 3px;
              }
            }

            .delivery-action-btn {
              width: 30%;
              text-align: end;

              button {
                width: 100%;
                border-radius: 51px;
                text-align: center;
                line-height: 29px;
                border: none;
                font-size: 14px;
                font-family: map-get($map: $geist-fonts, $key: ui-semibold) !important;
                background-color: #dee2e6;
                color: #000;
              }
            }

            .border-style {
              background-color: rgb(243, 243, 243);
              border: 1px solid rgb(110 104 104);
            }
          }

          .border-style {
            display: flex;
            justify-content: end;

            .borders {
              background-color: rgb(243, 243, 243);
              width: 100%;
              border-bottom: 1px solid rgb(243, 243, 243);
            }
          }
        }

        //    estimate card
        .estimateCard {
          margin-top: 4px !important;
          border-radius: 0px !important;
          border-color: #fff;

          .estimateBody {
            .estimateTitle {
              font-family: map-get($map: $geist-fonts, $key: ui-semibold) !important;
              font-size: 16px;
            }

            .estimate-detail {
              display: flex;
              justify-content: space-between;
              padding: 5px;
              border: 2px solid #f3f3f3;
              border-radius: 17px;
              margin-top: 11px;

              img {
                height: 32px;
                width: 32px;
                margin: 10px;

                @include themed() {
                  color: t("whitecolor");
                }
              }

              svg {
                margin: 1rem;
                font-size: 20px;
              }

              .estimate-icons {
                width: 10%;
              }

              .estimate-text {
                width: 50%;

                .estimate-text-postcode {
                  font-weight: 600;
                  margin-bottom: 0.2rem;
                  font-size: 14px;
                  // display: none;
                }

                .estimate-text-sub {
                  font-size: 12px;
                  color: #868686;
                  font-family: map-get($map: $geist-fonts, $key: ui-semibold) !important;
                  // margin-bottom: 2px !important;
                  margin: 3px;
                }
              }

              .estimate-action-btn {
                width: 40%;
                text-align: end;

                button {
                  border-radius: 51px;
                  text-align: center;
                  line-height: 29px;
                  border: none;
                  font-size: 14px;
                  font-family: map-get($map: $geist-fonts, $key: ui-semibold) !important;
                }
              }
            }

            .estimate-detail.active {
              border: 2px solid #3f6e21;

              svg {
                @include themed() {
                  color: t("whitecolor") !important;
                }
              }
            }
          }
        }

        //    payment card
        .paymentCard {
          margin-top: 4px !important;
          border-radius: 0px !important;
          border-color: #fff;
          display: none;

          .paymentCardBody {
            .paymentCardTitle {
              font-family: map-get($map: $geist-fonts, $key: ui-semibold) !important;
              font-size: 16px;
            }

            .payment-detail {
              display: flex;
              justify-content: space-between;
              padding: 5px;
              gap: 5px;
              align-items: center;

              img {
                width: 20px;
                height: 20px;
                margin-top: 10px;
              }

              svg {
                font-size: 30px;
              }

              .payment-icons {
                width: 10%;
              }

              .payment-text {
                width: 80%;
                margin-top: 6px;
                font-size: 14px;

                .payment-text-postcode {
                  margin-bottom: 0.4rem;
                }
              }

              .payment-action-btn {
                width: 40%;
                text-align: end;

                button {
                  border-radius: 51px;
                  text-align: center;
                  line-height: 29px;
                  border: none;
                  width: 50%;
                  font-size: 14px;
                  font-family: map-get($map: $geist-fonts, $key: ui-semibold) !important;
                }
              }
            }

            .border-style {
              display: none;
              justify-content: end;

              .borders {
                background-color: rgb(243, 243, 243);
                width: 100%;
                border-bottom: 1px solid rgb(243, 243, 243);
              }
            }
          }
        }

        //    request card
        .requestCard {
          margin-top: 4px !important;
          border-radius: 0px !important;
          border-color: #fff;

          .requestBody {
            .requestTitle {
              font-family: "Geist-SemiBold" !important;
              font-size: 16px;

              img {
                height: 25px;

                @include themed() {
                  color: t("whitecolor") !important;
                }
              }
            }

            .tip-text {
              display: flex;
              // align-items: center;
              gap: 6px;
              margin-bottom: 0px !important;

              img {
                height: 25px;

                @include themed() {
                  color: t("whitecolor") !important;
                }
              }

              svg {
                margin-bottom: 1rem;
                font-size: 19px;

                @include themed() {
                  color: t("whitecolor") !important;
                }
              }

              .tip-subtext {
                font-size: 14px;
                color: #868686;
                font-family: map-get($map: $geist-fonts, $key: ui-semibold) !important;
              }
            }

            .request-detail {
              display: flex;
              justify-content: space-between;

              //  padding: 10px;
              svg {
                margin: 5px;
                font-size: 20px;
              }

              .request-icons {
                width: 10%;
              }

              .request-text {
                width: 50%;
                font-size: 15px;

                .request-text-postcode {
                  margin-bottom: 0.4rem;
                  font-size: 15px;
                }
              }

              .request-action-btn {
                width: 40%;
                text-align: end;

                button {
                  border-radius: 51px;
                  text-align: center;
                  line-height: 29px;
                  border: none;
                  font-size: 14px;
                  font-family: map-get($map: $geist-fonts, $key: ui-semibold) !important;
                }
              }

              form.tips-form {
                display: flex;
                flex-direction: row;
                align-items: center;
                gap: 4px;

                label {
                  @include themed() {
                    border-color: t("b-color");
                    color: t("whitecolor");
                  }

                  height: 30px;
                  /* width: 40px; */
                  /* vertical-align: middle; */
                  cursor: pointer;
                  /* border: 1px solid #d4d4d4; */
                  padding: 1px 15px;
                  font-size: 12px;
                  color: #244453;
                  font-family: map-get($map: $geist-fonts, $key: ui-semibold) !important;
                  display: flex;
                  flex-direction: row;
                  align-items: center;
                  border-radius: 6px;
                  /* gap: 13px; */
                  background-color: #eee;
                  text-align: center;

                  &:first-child {
                    border-right: none;
                  }

                  &:nth-child(3) {
                    border-left: none;
                    border-right: none;
                  }

                  &:last-child {
                    border-left: none;
                  }

                  &.active {
                    background-color: #9ee86f;
                    color: var(--theme-color) !important;
                    border-color: #9ee86f;

                    @include themed() {
                      border-color: t("b-color");
                      background-color: t("hoverbg");
                      color: t("hovercolor") !important;
                    }
                  }

                  &.tip-label {
                    padding: 0px;
                    border-right: 1px solid #d4d4d4;

                    @include themed() {
                      border-color: t("b-color");
                    }

                    input[type="text"] {
                      padding: 5px 30px;
                      display: block;
                      color: #000000;
                      font-family: map-get($map: $geist-fonts, $key: ui-thin);
                      font-size: 16px;
                      outline: none;
                      border: none;
                      box-shadow: none;
                      height: 38px;
                      background-color: transparent;
                      border-radius: 50px;

                      @include themed() {
                        background-color: t("dark5");
                        color: t("whitecolor");
                      }
                    }
                  }

                  &.label-btn {
                    color: #244453;
                    background-color: #eeeeee;
                  }

                  input {
                    display: none;
                  }

                  span {
                    vertical-align: middle;
                  }
                }
              }

              .custom-tip {
                .accordion {
                  margin-top: 15px;

                  &.without-img {
                    margin-top: 10px;
                    margin-bottom: 20px;
                  }

                  .accordion-item {
                    @include themed() {
                      background-color: t("dark5");
                    }

                    border: none;

                    h2.accordion-header {
                      button {
                        font-family: map-get($map: $geist-fonts, $key: ui-semibold);
                        font-size: 15px;
                        padding: 0px;
                        gap: 10px;
                        border: none;
                        box-shadow: none;
                        outline: none;
                        margin-bottom: 0px;
                        color: #333333;

                        @include themed() {
                          color: t("hovercolor");
                          background-color: t("dark5");
                        }

                        &:focus {
                          border: none;
                          box-shadow: none;
                          outline: none;
                          background-color: transparent;
                        }

                        &::after {
                          display: none;
                        }

                        &:not(.collapsed) {
                          border: none;
                          box-shadow: none;
                          outline: none;
                          background-color: transparent;
                        }

                        span {
                          padding: 0px;
                          border: 1px solid #333333;
                          display: flex;
                          justify-content: center;
                          align-items: center;

                          @include themed() {
                            border-color: t("hovercolor");
                          }

                          svg {
                            fill: #333333;
                            font-size: 13px;

                            @include themed() {
                              fill: t("hovercolor");
                            }
                          }
                        }
                      }
                    }

                    .accordion-collapse {
                      .accordion-body {
                        padding: 10px 0px 0px 0px;

                        form.custom-tips-form {
                          display: flex;
                          flex-direction: row;
                          align-items: center;
                          gap: 10px;
                          margin-left: 0;

                          input {
                            color: #000000;
                            font-family: map-get($map: $geist-fonts, $key: ui-thin);
                            font-size: 16px;
                            outline: none;
                            border: 1px solid #d4d4d4;
                            box-shadow: none;
                            height: 40px;
                            width: auto;

                            @include themed() {
                              background-color: t("hoverbg");
                              border-color: t("b-color");
                              color: t("whitecolor");
                            }
                          }

                          button {
                            background-color: #000;
                            padding: 6px 25px 8px 25px;
                            color: #fff;
                            line-height: normal;
                            font-size: 15px;
                            font-family: map-get($map: $geist-fonts, $key: ui-semibold);
                            border: none;
                            outline: none;
                            box-shadow: none;
                            border-radius: 5px;
                            height: 38px;

                            @include themed() {
                              background-color: t("themelight");
                              color: t("themedark");
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }

              .donate-change {
                background-color: #ffffff;
                border-radius: 5px;
                box-shadow: rgb(149 157 165 / 8%) 0px 8px 24px;
                position: relative;

                @include themed() {
                  background-color: t("dark5");
                  // box-shadow: t("menu-shadow");
                }

                h3.donate-title {
                  color: #333333;
                  font-family: map-get($map: $geist-fonts, $key: ui-semibold);
                  font-size: 20px;
                  margin: 0px 0px 0px 0px;

                  @include themed() {
                    color: t("whitecolor");
                  }
                }

                .content {
                  display: flex;
                  flex-direction: row;
                  justify-content: flex-start;
                  align-items: center;
                  gap: 10px;

                  .form-check {
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    padding: 0px;
                    gap: 10px;

                    input[type="checkbox"] {
                      width: 30px;
                      border: 1px solid black;
                      border-radius: 0px;
                      margin: 0px;
                      cursor: pointer;
                      color: #9fe870;
                      font-family: "SegoeUI";
                      font-size: 16px;
                      outline: none;
                      box-shadow: none;

                      @include themed() {
                        color: t("themelight");
                        border: t("themelight");
                      }

                      &:checked {
                        background-color: #9ee86f;

                        @include themed() {
                          color: t("themelight");
                          background-color: t("themelight");
                        }
                      }
                    }

                    label {
                      color: #868686;
                      font-family: map-get($map: $geist-fonts, $key: ui-thin);
                      font-size: 14px;
                      margin: 0px;
                      line-height: normal;

                      @include themed() {
                        color: t("light-text");
                      }
                    }
                  }

                  span {
                    width: 35px;
                    height: 35px;
                    background-color: #28be21;
                    border-radius: 5px;
                    padding: 3px;
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    @include themed() {
                      background-color: t("themelight");
                    }

                    svg {
                      color: #ffffff;
                      font-size: 20px;
                    }
                  }
                }

                a {
                  color: var(--theme-background);
                  line-height: normal;
                  font-size: 16px;
                  font-family: map-get($map: $geist-fonts, $key: ui-semibold);
                  text-decoration: none;
                  text-align: right;
                  display: flex;
                  justify-content: flex-end;

                  @include themed() {
                    color: t("themelight");
                  }
                }
              }
            }

            .border-style {
              display: flex;
              justify-content: end;

              .borders {
                background-color: rgb(243, 243, 243);
                width: 100%;
                border-bottom: 1px solid rgb(243, 243, 243);
              }
            }
          }
        }

        //   order card
        .orderCard {
          border-radius: 0;
          border-right: none;
          margin-top: 2px !important;
          border-color: #fff;

          .order-summary-title {
            display: flex;
            justify-content: space-between;

            .order-summary-btn {
              width: 40%;
              text-align: end;

              button {
                border-radius: 51px;
                width: 100%;
                text-align: center;
                line-height: 29px;
                border: none;
                font-size: 14px;
                font-family: map-get($map: $geist-fonts, $key: ui-semibold) !important;
              }
            }
          }

          .order-detail {
            display: flex;
            justify-content: space-between;
            padding: 2px;
            margin-top: 10px;

            .order-count {
              min-width: 24px;
              min-height: -17px;
              /* line-height: 8px; */
              background-color: rgb(232, 232, 232);
              height: 26px;
              text-align: center;
            }

            svg {
              margin: 5px;
              font-size: 20px;
            }

            .order-icons {
              width: 10%;
            }

            .order-text {
              width: 50%;
              margin-left: 16px;

              .order-text-postcode {
                margin-bottom: 0.4rem;
              }
            }

            .order-action-btn {
              width: 63%;
              text-align: end;

              button {
                border-radius: 51px;
                text-align: center;
                line-height: 29px;
                border: none;
                font-size: 14px;
                font-family: map-get($map: $geist-fonts, $key: ui-semibold) !important;
              }
            }

            .border-style {
              background-color: rgb(243, 243, 243);
              border: 1px solid rgb(110 104 104);
            }
          }

          .border-style {
            display: flex;
            justify-content: end;

            .borders {
              background-color: rgb(243, 243, 243);
              width: 100%;
              border-bottom: 1px solid rgb(243, 243, 243);
            }
          }
        }
      }

      .fus-payment-bar {
        margin-top: 2px;

        .fus-payment-card {
          border-radius: 0;
          border-right: none;
          border-color: #fff;

          // margin-bottom: 2rem;
          .fusion-cart-summary {
            display: none;
          }

          .fus-bill-title {
            font-weight: bold;
            font-family: map-get($map: $geist-fonts, $key: ui-semibold) !important;
            margin-bottom: 1rem;
            display: flex;
            font-size: 16px;

            @include themed() {
              color: t("whitecolor") !important;
            }

            // justify-content: space-around;
            .fus-order-summary-title-text {
              width: 50%;
              font-family: map-get($map: $geist-fonts, $key: ui-semibold) !important;
              font-size: 16px;
            }

            .fus-order-summary-btn {
              width: 50%;
              /* float: right; */
              text-align: end;

              .fus-order-summary-sub-btn {
                border-radius: 51px;
                text-align: center;
                line-height: 29px;
                border: none;
                font-size: 14px;
                font-family: map-get($map: $geist-fonts, $key: ui-semibold) !important;
                width: 80%;
                background-color: #dee2e6;
                color: #000;
              }
            }
          }

          .fus-order-summary {
            display: flex;

            .fus-order-summary-count {
              min-width: 24px;
              min-height: -17px;
              // background-color: rgb(232, 232, 232);
              height: 26px;
              // text-align: center;
              width: 10%;
            }

            .fus-order-summary-text {
              margin-bottom: 0.4rem;
              font-family: "Geist-Regular";
              width: 60%;
              color: #868686;

              .fus-order-summary-image {
                margin-right: 10px;
              }
            }

            .fus-order-summary-price {
              width: 30%;
              text-align: right;
            }
          }

          .fus-bill-text {
            display: flex;
            justify-content: space-between;
            margin-bottom: 1px;
          }

          .fus-bill-total {
            display: flex;
            justify-content: space-between;
            border-top: 1px solid #d2d2d2;
            padding-top: 10px;

            h3 {
              font-size: 18px;
              font-family: map-get($map: $geist-fonts, $key: ui-semibold) !important;

              @include themed() {
                color: t("whitecolor") !important;
              }
            }
          }
        }
      }

      .order-place {
        margin: 0px;
        position: fixed;
        bottom: 0px;
        width: 100%;
        left: 0px;
        background-color: #ffffff;
        box-shadow:
          rgba(255, 255, 255, 0.412) 0px 1px 0px 0px,
          rgba(6, 77, 147, 0.4117647059) 0px 0px 0px 1px;
        border: solid 1px rgba(255, 255, 255, 0.412);
        border-radius: 25px 25px 0px 0px;

        @include themed() {
          background-color: t("hoverbg");
          box-shadow: t("div-shadow");
          color: t("whitecolor");
          // border: solid 1px rgba(6, 77, 147, 0.4117647059);
        }

        button {
          margin: 15px;
          width: 92%;
          border-radius: 0px;
          padding: 10px 15px;
          font-size: 17px;
          border-radius: 10px;
          border: none;
          color: #000;
          background: #9ee86f;
          font-family: 'SegoeUI-Bold';
        }

        & button:disabled {
          margin: 15px;
          width: 92%;
          border-radius: 0px;
          padding: 10px 15px;
          font-size: 17px;
          border-radius: 10px;
          background-color: #dfdfdf;
        }

        .payment-btn {
          margin: 7px 11px 11px 11px;

          @include themed() {
            color: t("whitecolor");
          }

          .spinner-container {
            display: flex;
            justify-content: center;
            align-items: center;
          }
        }

        p {
          font-family: map-get($map: $geist-fonts, $key: ui-thin);
        }

        .terms-text {
          display: block;
        }

        .total-price {
          padding: 10px 10px 0px 15px;
          display: flex;
          // margin: 0px -15px;
          display: flex;
          justify-content: space-between;
          align-items: center;

          span {
            font-family: map-get($map: $geist-fonts, $key: ui-semibold);
            font-size: 17px;
            color: #214457;

            @include themed() {
              color: t("hovercolor") !important;
            }
          }

          .paytype {
            font-size: 18px;
            margin: 0;
            color: #000;
            font-family: map-get($map: $geist-fonts, $key: ui-semibold) !important;

            @include themed() {
              color: t("whitecolor");
            }
          }

          .total-pricechild p {
            color: #000;
            text-align: left;
            font-family: "SegoeUI";
            font-size: 16px;
            font-style: normal;
            font-weight: 100;
            line-height: normal;
            margin: 5px 2px 0px 0px;

            @include themed() {
              color: t("whitecolor");
            }
          }
        }
      }
    }
  }

  //estimate popup
  .estimateTimetable {
    height: 300px;
    overflow-y: scroll !important;
    margin-top: 1rem;
  }
}

//sm device
@media only screen and (min-width: 421px) and (max-width: 767px) {
  // .fus-checkout-page{
  //     background-color: #eee;
  //    .fus-checkout{
  //     background: rgb(243, 243, 243);
  //     .fus-top-title{
  //        display: flex !important;
  //        margin: 10px;
  //        .fus-client-img{
  //            width: 4rem;
  //            height: 60px;
  //        }
  //        .fus-client-title{
  //            font-family: map-get($map:$geist-fonts, $key: ui-semibold) !important;
  //            text-align: center;
  //            margin: 1rem;
  //        }
  //     }
  //     .fus-payment-btn{
  //     //    margin-top: 1rem;
  //        button{
  //            background: #9fe870;
  //            width: 100%;
  //            border: 1px;
  //            line-height: 34px;
  //            font-size: 18px;
  //            bottom: 0;
  //            position: fixed;
  //        }
  //     }
  //     .fus-delivery-bar{
  //       margin-top: 1rem;
  //       .fus-delivery-title{
  //        font-weight: bold;
  //        font-family: map-get($map:$geist-fonts, $key: ui-semibold) !important;
  //        margin-bottom: 1rem;
  //       }
  //    //    delivery card
  //       .deliveryCard{
  //         border-radius: 0;
  //         border-right: none;
  //         border-color: #fff;
  //       .delivery-detail{
  //        display: flex;
  //        justify-content: space-between;
  //     //    padding: 2px;
  //        margin-top: 10px;
  //        svg{
  //            margin: 5px;
  //            font-size: 20px;
  //        }
  //        .delivery-icons{
  //            width: 10%;
  //        }
  //        .delivery-text{
  //            width: 80%;
  //            font-family: map-get($map:$geist-fonts, $key: ui-semibold) !important;
  //            .delivery-text-postcode{
  //                margin-bottom: 0.4rem;
  //                font-weight: 600;
  //                font-family: map-get($map:$geist-fonts, $key: ui-semibold) !important;
  //             }
  //             .delivery-sub-text-postcode{
  //               font-family: map-get($map:$geist-fonts, $key: ui-semibold) !important;
  //               font-size: 14px;
  //             }
  //        }
  //        .delivery-action-btn{
  //            width: 20%;
  //            text-align: end;
  //            button{
  //                width: 100%;
  //                border-radius: 51px;
  //                text-align: center;
  //                line-height: 29px;
  //                border: none;
  //                font-size: 14px;
  //                font-family: map-get($map:$geist-fonts, $key: ui-semibold) !important;
  //            }
  //        }
  //        .border-style{
  //            background-color: rgb(243, 243, 243);
  //            border: 1px solid rgb(110 104 104);
  //          }
  //       }
  //       .border-style{
  //        display: flex;
  //        justify-content: end;
  //        .borders{
  //            background-color: rgb(243, 243, 243);
  //            width: 100%;
  //            border-bottom: 1px solid rgb(243, 243, 243);
  //        }
  //      }
  //    }
  //     //    estimate card
  //       .estimateCard{
  //        margin-top: 4px !important;
  //        border-radius: 0px !important;
  //        border-color: #fff;
  //        .estimate-detail{
  //            display: flex;
  //            justify-content: space-between;
  //            padding: 5px;
  //            border: 2px solid rgb(243, 243, 243);
  //            border-radius: 17px;
  //            margin-top: 11px;
  //            svg{
  //                margin: 1rem;
  //                font-size: 20px;
  //            }
  //         .estimate-icons{
  //             width: 10%;
  //         }
  //         .estimate-text{
  //             width: 50%;
  //             .estimate-text-postcode{
  //                font-weight: 600;
  //                margin-bottom: 0.2rem;
  //             }
  //             .estimate-text-sub{
  //                margin-bottom: 0.1rem;
  //             }
  //         }
  //         .estimate-action-btn{
  //             width: 40%;
  //             text-align: end;
  //             button{
  //                 border-radius: 51px;
  //                 text-align: center;
  //                 line-height: 29px;
  //                 border: none;
  //                 font-size: 14px;
  //                 font-family: map-get($map:$geist-fonts, $key: ui-semibold) !important;
  //             }
  //         }
  //        }
  //      }
  //    //    payment card
  //      .paymentCard{
  //        margin-top: 4px !important;
  //        border-radius: 0px !important;
  //        border-color: #fff;
  //      .payment-detail{
  //        display: flex;
  //        justify-content: space-between;
  //        padding: 5px;
  //    svg{
  //        margin: 5px;
  //        font-size: 20px;
  //    }
  //    .payment-icons{
  //        width: 10%;
  //    }
  //    .payment-text{
  //        width: 80%;
  //        margin-top: 2px;
  //        .payment-text-postcode{
  //            font-weight: 600;
  //            margin-bottom: 0.4rem;
  //         }
  //    }
  //    .payment-action-btn{
  //        width: 40%;
  //        text-align: end;
  //        button{
  //            border-radius: 51px;
  //            text-align: center;
  //            line-height: 29px;
  //            border: none;
  //            width: 50%;
  //            font-size: 14px;
  //            font-family: map-get($map:$geist-fonts, $key: ui-semibold) !important;
  //        }
  //    }
  //      }
  //      .border-style{
  //        display: flex;
  //        justify-content: end;
  //        .borders{
  //            background-color: rgb(243, 243, 243);
  //            width: 100%;
  //            border-bottom: 1px solid rgb(243, 243, 243);
  //        }
  //      }
  //    }

  //     //    payment card
  //     .requestCard{
  //        margin-top: 4px !important;
  //        border-radius: 0px !important;
  //        border-color: #fff;
  //      .request-detail{
  //        display: flex;
  //        justify-content: space-between;
  //        padding: 10px;
  //    svg{
  //        margin: 5px;
  //        font-size: 20px;
  //    }
  //    .request-icons{
  //        width: 10%;
  //    }
  //    .request-text{
  //        width: 50%;
  //        font-size:15px;
  //        .request-text-postcode{
  //            font-weight: 600;
  //            margin-bottom: 0.4rem;
  //            font-size:15px;
  //         }
  //    }
  //    .request-action-btn{
  //        width: 40%;
  //        text-align: end;
  //        button{
  //            border-radius: 51px;
  //            text-align: center;
  //            line-height: 29px;
  //            border: none;
  //            font-size: 14px;
  //            font-family: map-get($map:$geist-fonts, $key: ui-semibold) !important;
  //        }
  //    }
  //      }
  //      .border-style{
  //        display: flex;
  //        justify-content: end;
  //        .borders{
  //            background-color: rgb(243, 243, 243);
  //            width: 100%;
  //            border-bottom: 1px solid rgb(243, 243, 243);
  //        }
  //      }
  //      }

  //      .orderCard{
  //         border-radius: 0;
  //         border-right: none;
  //         margin-top: 2px !important;
  //         border-color: #fff;
  //        .order-summary-title{
  //         display: flex;
  //         justify-content: space-between;
  //         .order-summary-btn{
  //            width: 40%;
  //            text-align: end;
  //            button{
  //                border-radius: 51px;
  //                width: 100%;
  //                text-align: center;
  //                line-height: 29px;
  //                border: none;
  //                font-size: 14px;
  //                font-family: map-get($map:$geist-fonts, $key: ui-semibold) !important;
  //            }
  //        }
  //        }
  //      .order-detail{
  //        display: flex;
  //        justify-content: space-between;
  //        padding: 2px;
  //        margin-top: 10px;
  //        .order-count{
  //            min-width: 24px;
  //            min-height: -17px;
  //            /* line-height: 8px; */
  //            background-color: rgb(232, 232, 232);
  //            height: 26px;
  //            text-align: center;
  //        }
  //        svg{
  //            margin: 5px;
  //            font-size: 20px;
  //        }
  //        .order-icons{
  //            width: 10%;
  //        }
  //        .order-text{
  //            width: 50%;
  //            margin-left: 16px;
  //            .order-text-postcode{
  //                font-weight: 600;
  //                margin-bottom: 0.4rem;
  //             }
  //        }
  //        .order-action-btn{
  //            width: 63%;
  //            text-align: end;
  //            button{
  //                border-radius: 51px;
  //                text-align: center;
  //                line-height: 29px;
  //                border: none;
  //                font-size: 14px;
  //                font-family: map-get($map:$geist-fonts, $key: ui-semibold) !important;
  //            }
  //        }
  //        .border-style{
  //            background-color: rgb(243, 243, 243);
  //            border: 1px solid rgb(110 104 104);
  //          }
  //       }
  //       .border-style{
  //        display: flex;
  //        justify-content: end;
  //        .borders{
  //            background-color: rgb(243, 243, 243);
  //            width: 100%;
  //            border-bottom: 1px solid rgb(243, 243, 243);
  //        }
  //      }
  //    }

  //     }
  //       .fus-payment-bar{
  //        margin-top: 2px;
  //        .fus-payment-card{
  //         border-radius: 0;
  //         border-right: none;
  //         border-color: #fff;
  //        .fus-bill-title{
  //        font-weight: bold;
  //        font-family: map-get($map:$geist-fonts, $key: ui-semibold) !important;
  //        margin-bottom: 1rem;
  //        }
  //        .fus-bill-text{
  //            display: flex;
  //            justify-content: space-between;
  //            margin-bottom: 1px;
  //        }
  //        .fus-bill-total{
  //            display: flex;
  //            justify-content: space-between;
  //            border-top: 1px solid #d2d2d2;
  //            padding-top: 10px;
  //            h3{
  //                font-size: 18px;
  //                font-family: map-get($map:$geist-fonts, $key: ui-semibold) !important;
  //                }
  //        }
  //      }
  //       }
  //    }
  // }

  .scroll-header-content {
    display: none;
  }

  .fus-checkout-page {
    background-color: #eee;

    .fus-checkout {
      background: rgb(243, 243, 243) !important;

      .fus-top-title {
        display: flex !important;
        margin: 10px;

        .fus-client-img {
          width: 4rem;
          height: 60px;
        }

        .fus-client-title {
          font-family: map-get($map: $geist-fonts, $key: ui-semibold) !important;
          text-align: center;
          margin: 1rem;
        }

        .fus-payment-btn1 {

          //    margin-top: 1rem;
          button {
            background: var(--theme-background);
            width: 100%;
            border: 1px;
            line-height: 34px;
            font-size: 18px;
            bottom: 0;
            position: fixed;
            font-family: map-get($map: $geist-fonts, $key: ui-semibold) !important;
            color: var(--theme-color);
          }
        }
      }

      .fus-payment-btn {

        //    margin-top: 1rem;
        button {
          background: #9ee86f;
          width: 100%;
          border: 1px;
          line-height: 34px;
          font-size: 18px;
          bottom: 0;
          position: fixed;
          font-family: map-get($map: $geist-fonts, $key: ui-semibold) !important;
          color: var(--theme-color);
        }
      }

      .fus-delivery-bar {
        .fus-delivery-title {
          font-weight: bold;
          font-family: map-get($map: $geist-fonts, $key: ui-semibold) !important;
          margin-bottom: 1rem;
        }

        //    delivery card
        .deliveryCard {
          border-radius: 0;
          border-right: none;
          border-color: #fff;

          .preorder-box {
            display: flex;
            flex-direction: column-reverse;
            gap: 20px;

            ul.nav.nav-tabs.nav-fill {
              width: 100% !important;
              background-color: #dee2e6;
              border-radius: 35px;
              padding: 4px;
              //   li.nav-item{
              //     &:first-child{
              //         border-radius: 50px 0 0 50px;
              //         border-right: none;
              //       }
              //       &:last-child{
              //         border-radius: 0 50px 50px 0;
              //         border-right: none;
              //       }
              //       button.nav-link {
              //         border-color: #173303 !important;
              //         border: 1px solid;
              //         border-radius: 50px;
              //         color: #173303;
              //         font-family: map-get($map:$geist-fonts, $key: ui-semibold) !important;
              //         font-size: 15px;
              //         height: 100%;
              //         line-height: normal;
              //       }
              //       button.nav-link.active {
              //         background: #173303;
              //         color:#fff;
              //       }

              //   }
              li.nav-item {
                &:first-child {
                  button.nav-link {
                    // border-radius: 50px 0px 0px 50px;
                    border: none;

                    &.disabled {
                      border: none !important;
                    }
                  }
                }

                &:last-child {
                  button.nav-link {

                    // border-radius: 0px 50px 50px 0px;
                    &.disabled {
                      border-left: 1px solid #6d6d6d !important;
                    }
                  }
                }

                button.nav-link {
                  // border-color: $new-green-dark !important;
                  // border: 1px solid;
                  border-radius: 50px;
                  color: $new-green-dark;
                  font-family: map-get($map: $geist-fonts, $key: ui-semibold);
                  font-size: 15px;
                  line-height: normal;
                  height: 100%;

                  &.disabled {
                    border: 1px solid #e0e0e0 !important;
                    color: #e0e0e0;
                  }

                  &.active {
                    // background-color: $new-green-dark;
                    // border: 1px solid $new-green-dark;
                    // color: $new-green;
                  }

                  &:hover {
                    // border-color: #102402 !important;
                    // background-color: #102402 !important;
                    color: $new-green;
                  }
                }
              }
            }
          }

          .delivery-detail {
            display: flex;
            justify-content: space-between;
            //    padding: 2px;
            // margin-top: 10px;
            margin-bottom: 13px;
            align-items: center;

            img {
              height: 32px;
              width: 30px;

              @include themed() {
                color: t("whitecolor");
              }
            }

            svg {
              margin: 5px;
              font-size: 20px;
            }

            .delivery-icons {
              width: 10%;
            }

            .delivery-text {
              width: 80%;
              font-family: map-get($map: $geist-fonts, $key: ui-semibold) !important;

              .delivery-text-postcode {
                margin-bottom: 2px;
                font-weight: 600;
                font-family: map-get($map: $geist-fonts, $key: ui-semibold) !important;
              }

              .delivery-sub-text-postcode {
                font-family: "Geist-Regular" !important;
                font-size: 14px;
                color: #868686 !important;
                margin-bottom: 3px;
              }
            }

            .delivery-action-btn {
              width: 30%;
              text-align: end;

              button {
                width: 100%;
                border-radius: 51px;
                text-align: center;
                line-height: 29px;
                border: none;
                font-size: 14px;
                font-family: map-get($map: $geist-fonts, $key: ui-semibold) !important;
                background-color: #dee2e6;
                color: #000;
              }
            }

            .border-style {
              background-color: rgb(243, 243, 243);
              border: 1px solid rgb(110 104 104);
            }
          }

          .border-style {
            display: flex;
            justify-content: end;

            .borders {
              background-color: rgb(243, 243, 243);
              width: 100%;
              border-bottom: 1px solid rgb(243, 243, 243);
            }
          }
        }

        //    estimate card
        .estimateCard {
          margin-top: 4px !important;
          border-radius: 0px !important;
          border-color: #fff;

          .estimate-detail {
            display: flex;
            justify-content: space-between;
            padding: 5px;
            border: 2px solid #f3f3f3;
            border-radius: 17px;
            margin-top: 11px;

            img {
              height: 32px;
              width: 32px;

              @include themed() {
                color: t("whitecolor");
              }
            }

            svg {
              margin: 1rem;
              font-size: 20px;
            }

            .estimate-icons {
              width: 10%;
            }

            .estimate-text {
              width: 50%;

              .estimate-text-postcode {
                font-weight: 600;
                margin-bottom: 0.2rem;
                // display: none;
              }

              .estimate-text-sub {
                margin-bottom: 0.1rem;
              }
            }

            .estimate-action-btn {
              width: 40%;
              text-align: end;

              button {
                border-radius: 51px;
                text-align: center;
                line-height: 29px;
                border: none;
                font-size: 14px;
                font-family: map-get($map: $geist-fonts, $key: ui-semibold) !important;
              }
            }
          }

          .estimate-detail.active {
            border: 2px solid #3f6e21;

            img {
              height: 32px;
              width: 32px;
              margin: 10px;

              @include themed() {
                color: t("whitecolor");
              }
            }

            svg {
              @include themed() {
                color: t("whitecolor") !important;
              }
            }
          }
        }

        //    payment card
        .paymentCard {
          margin-top: 4px !important;
          border-radius: 0px !important;
          border-color: #fff;
          display: none;

          .payment-detail {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 5px;
            gap: 5px;

            img {
              width: 30px;
              height: 20px;
              margin-top: 10px;
            }

            svg {
              font-size: 30px;
            }

            .payment-icons {
              width: 10%;
            }

            .payment-text {
              width: 80%;
              margin-top: 6px;

              .payment-text-postcode {
                font-weight: 600;
                margin-bottom: 0.4rem;
              }
            }

            .payment-action-btn {
              width: 40%;
              text-align: end;

              button {
                border-radius: 51px;
                text-align: center;
                line-height: 29px;
                border: none;
                width: 50%;
                font-size: 14px;
                font-family: map-get($map: $geist-fonts, $key: ui-semibold) !important;
              }
            }
          }

          .border-style {
            display: flex;
            justify-content: end;

            .borders {
              background-color: rgb(243, 243, 243);
              width: 100%;
              border-bottom: 1px solid rgb(243, 243, 243);
            }
          }
        }

        //    request card
        .requestCard {
          margin-top: 4px !important;
          border-radius: 0px !important;
          border-color: #fff;

          .requestTitle {
            img {
              height: 25px;

              @include themed() {
                color: t("whitecolor") !important;
              }
            }
          }

          .tip-text {
            display: flex;
            align-items: center;
            gap: 6px;
            margin-bottom: 0px !important;

            img {
              height: 25px;

              @include themed() {
                color: t("whitecolor") !important;
              }
            }

            svg {
              @include themed() {
                color: t("whitecolor") !important;
              }
            }

            .tip-subtext {
              font-size: 14px;
              color: #868686;
            }
          }

          .request-detail {
            display: flex;
            justify-content: space-between;

            //  padding: 10px;
            svg {
              margin: 5px;
              font-size: 20px;

              @include themed() {
                color: t("whitecolor") !important;
              }
            }

            .request-icons {
              width: 10%;
            }

            .request-text {
              width: 50%;
              font-size: 15px;

              .request-text-postcode {
                font-weight: 600;
                margin-bottom: 0.4rem;
                font-size: 15px;
              }
            }

            .request-action-btn {
              width: 40%;
              text-align: end;

              button {
                border-radius: 51px;
                text-align: center;
                line-height: 29px;
                border: none;
                font-size: 14px;
                font-family: map-get($map: $geist-fonts, $key: ui-semibold) !important;
              }
            }

            form.tips-form {
              display: flex;
              flex-direction: row;
              align-items: center;
              gap: 4px;

              label {
                @include themed() {
                  border-color: t("b-color");
                  color: t("whitecolor");
                }

                height: 30px;
                /* width: 40px; */
                /* vertical-align: middle; */
                cursor: pointer;
                /* border: 1px solid #d4d4d4; */
                padding: 1px 15px;
                font-size: 12px;
                color: #244453;
                font-family: map-get($map: $geist-fonts, $key: ui-semibold) !important;
                display: flex;
                flex-direction: row;
                align-items: center;
                border-radius: 6px;
                /* gap: 13px; */
                background-color: #eee;
                text-align: center;

                &:first-child {
                  border-right: none;
                }

                &:nth-child(3) {
                  border-left: none;
                  border-right: none;
                }

                &:last-child {
                  border-left: none;
                }

                &.active {
                  background-color: #9ee86f;
                  color: var(--theme-color) !important;
                  border-color: #9ee86f;

                  @include themed() {
                    border-color: t("b-color");
                    background-color: t("hoverbg");
                    color: t("hovercolor") !important;
                  }
                }

                &.tip-label {
                  padding: 0px;
                  border-right: 1px solid #d4d4d4;

                  @include themed() {
                    border-color: t("b-color");
                  }

                  input[type="text"] {
                    padding: 5px 30px;
                    display: block;
                    color: #000000;
                    font-family: map-get($map: $geist-fonts, $key: ui-thin);
                    font-size: 16px;
                    outline: none;
                    border: none;
                    box-shadow: none;
                    height: 38px;
                    background-color: transparent;
                    border-radius: 50px;

                    @include themed() {
                      background-color: t("dark5");
                      color: t("whitecolor");
                    }
                  }
                }

                &.label-btn {
                  color: #244453;
                  background-color: #eeeeee;
                }

                input {
                  display: none;
                }

                span {
                  vertical-align: middle;
                }
              }
            }

            .custom-tip {
              .accordion {
                margin-top: 15px;

                &.without-img {
                  margin-top: 10px;
                  margin-bottom: 20px;
                }

                .accordion-item {
                  @include themed() {
                    background-color: t("dark5");
                  }

                  border: none;

                  h2.accordion-header {
                    button {
                      font-family: map-get($map: $geist-fonts, $key: ui-semibold);
                      font-size: 15px;
                      padding: 0px;
                      gap: 10px;
                      border: none;
                      box-shadow: none;
                      outline: none;
                      margin-bottom: 0px;
                      color: #333333;

                      @include themed() {
                        color: t("hovercolor");
                        background-color: t("dark5");
                      }

                      &:focus {
                        border: none;
                        box-shadow: none;
                        outline: none;
                        background-color: transparent;
                      }

                      &::after {
                        display: none;
                      }

                      &:not(.collapsed) {
                        border: none;
                        box-shadow: none;
                        outline: none;
                        background-color: transparent;
                      }

                      span {
                        padding: 0px;
                        border: 1px solid #333333;
                        display: flex;
                        justify-content: center;
                        align-items: center;

                        @include themed() {
                          border-color: t("hovercolor");
                        }

                        svg {
                          fill: #333333;
                          font-size: 13px;

                          @include themed() {
                            fill: t("hovercolor");
                          }
                        }
                      }
                    }
                  }

                  .accordion-collapse {
                    .accordion-body {
                      padding: 10px 0px 0px 0px;

                      form.custom-tips-form {
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        gap: 10px;
                        margin-left: 0;

                        input {
                          color: #000000;
                          font-family: map-get($map: $geist-fonts, $key: ui-thin);
                          font-size: 16px;
                          outline: none;
                          border: 1px solid #d4d4d4;
                          box-shadow: none;
                          height: 40px;
                          width: auto;

                          @include themed() {
                            background-color: t("hoverbg");
                            border-color: t("b-color");
                            color: t("whitecolor");
                          }
                        }

                        button {
                          background-color: #000;
                          padding: 6px 25px 8px 25px;
                          color: #fff;
                          line-height: normal;
                          font-size: 15px;
                          font-family: map-get($map: $geist-fonts, $key: ui-semibold);
                          border: none;
                          outline: none;
                          box-shadow: none;
                          border-radius: 5px;
                          height: 38px;

                          @include themed() {
                            background-color: t("themelight");
                            color: t("themedark");
                          }
                        }
                      }
                    }
                  }
                }
              }
            }

            .donate-change {
              background-color: #ffffff;
              border-radius: 5px;
              box-shadow: rgb(149 157 165 / 8%) 0px 8px 24px;
              position: relative;

              @include themed() {
                background-color: t("dark5");
                // box-shadow: t("menu-shadow");
              }

              h3.donate-title {
                color: #333333;
                font-family: map-get($map: $geist-fonts, $key: ui-semibold);
                font-size: 20px;
                margin: 0px 0px 0px 0px;

                @include themed() {
                  color: t("whitecolor");
                }
              }

              .content {
                display: flex;
                flex-direction: row;
                justify-content: flex-start;
                align-items: center;
                gap: 10px;

                .form-check {
                  display: flex;
                  justify-content: flex-start;
                  align-items: center;
                  padding: 0px;
                  gap: 10px;

                  input[type="checkbox"] {
                    width: 30px;
                    border: 1px solid black;
                    border-radius: 0px;
                    margin: 0px;
                    cursor: pointer;
                    color: #9fe870;
                    font-family: "SegoeUI";
                    font-size: 16px;
                    outline: none;
                    box-shadow: none;

                    @include themed() {
                      color: t("themelight");
                      border: t("themelight");
                    }

                    &:checked {
                      background-color: #9ee86f;

                      @include themed() {
                        color: t("themelight");
                        background-color: t("themelight");
                      }
                    }
                  }

                  label {
                    color: #868686;
                    font-family: map-get($map: $geist-fonts, $key: ui-thin);
                    font-size: 14px;
                    margin: 0px;
                    line-height: normal;

                    @include themed() {
                      color: t("light-text");
                    }
                  }
                }

                span {
                  width: 35px;
                  height: 35px;
                  background-color: #28be21;
                  border-radius: 5px;
                  padding: 3px;
                  display: flex;
                  align-items: center;
                  justify-content: center;

                  @include themed() {
                    background-color: t("themelight");
                  }

                  svg {
                    color: #ffffff;
                    font-size: 20px;
                  }
                }
              }

              a {
                color: var(--theme-background);
                line-height: normal;
                font-size: 16px;
                font-family: map-get($map: $geist-fonts, $key: ui-semibold);
                text-decoration: none;
                text-align: right;
                display: flex;
                justify-content: flex-end;

                @include themed() {
                  color: t("themelight");
                }
              }
            }
          }

          .border-style {
            display: flex;
            justify-content: end;

            .borders {
              background-color: rgb(243, 243, 243);
              width: 100%;
              border-bottom: 1px solid rgb(243, 243, 243);
            }
          }
        }

        //   order card
        .orderCard {
          border-radius: 0;
          border-right: none;
          margin-top: 2px !important;
          border-color: #fff;

          .order-summary-title {
            display: flex;
            justify-content: space-between;

            .order-summary-btn {
              width: 40%;
              text-align: end;

              button {
                border-radius: 51px;
                width: 100%;
                text-align: center;
                line-height: 29px;
                border: none;
                font-size: 14px;
                font-family: map-get($map: $geist-fonts, $key: ui-semibold) !important;
              }
            }
          }

          .order-detail {
            display: flex;
            justify-content: space-between;
            padding: 2px;
            margin-top: 10px;

            .order-count {
              min-width: 24px;
              min-height: -17px;
              /* line-height: 8px; */
              background-color: rgb(232, 232, 232);
              height: 26px;
              text-align: center;
            }

            svg {
              margin: 5px;
              font-size: 20px;
            }

            .order-icons {
              width: 10%;
            }

            .order-text {
              width: 50%;
              margin-left: 16px;

              .order-text-postcode {
                font-weight: 600;
                margin-bottom: 0.4rem;
              }
            }

            .order-action-btn {
              width: 63%;
              text-align: end;

              button {
                border-radius: 51px;
                text-align: center;
                line-height: 29px;
                border: none;
                font-size: 14px;
                font-family: map-get($map: $geist-fonts, $key: ui-semibold) !important;
              }
            }

            .border-style {
              background-color: rgb(243, 243, 243);
              border: 1px solid rgb(110 104 104);
            }
          }

          .border-style {
            display: flex;
            justify-content: end;

            .borders {
              background-color: rgb(243, 243, 243);
              width: 100%;
              border-bottom: 1px solid rgb(243, 243, 243);
            }
          }
        }
      }

      .fus-payment-bar {
        margin-top: 2px;

        .fus-payment-card {
          border-radius: 0;
          border-right: none;
          border-color: #fff;

          .fus-bill-title {
            font-weight: bold;
            font-family: map-get($map: $geist-fonts, $key: ui-semibold) !important;
            margin-bottom: 1rem;
            display: flex;
            justify-content: space-around;

            @include themed() {
              color: t("whitecolor") !important;
            }

            .fus-order-summary-btn {
              width: 50%;
              /* float: right; */
              text-align: end;

              .fus-order-summary-sub-btn {
                border-radius: 51px;
                text-align: center;
                line-height: 29px;
                border: none;
                font-size: 14px;
                font-family: map-get($map: $geist-fonts, $key: ui-semibold) !important;
                width: 50%;
                background-color: #dee2e6;
                color: #000;
              }
            }
          }

          .fus-order-summary {
            display: flex;

            .fus-order-summary-count {
              min-width: 24px;
              min-height: -17px;
              // background-color: rgb(232, 232, 232);
              height: 26px;
              // text-align: center;
              width: 10%;
            }

            .fus-order-summary-text {
              margin-bottom: 0.4rem;
              font-family: "Geist-Regular";
              width: 60%;
              color: #868686;

              .fus-order-summary-image {
                margin-right: 10px;
              }
            }

            .fus-order-summary-price {
              width: 30%;
              text-align: right;
            }
          }

          .fus-bill-text {
            display: flex;
            justify-content: space-between;
            margin-bottom: 1px;
          }

          .fus-bill-total {
            display: flex;
            justify-content: space-between;
            border-top: 1px solid #d2d2d2;
            padding-top: 10px;

            h3 {
              font-size: 18px;
              font-family: map-get($map: $geist-fonts, $key: ui-semibold) !important;

              @include themed() {
                color: t("whitecolor") !important;
              }
            }
          }
        }
      }

      .order-place {
        margin: 0px;
        position: fixed;
        bottom: 0px;
        width: 100%;
        left: 0px;
        background-color: #ffffff;
        box-shadow:
          rgba(255, 255, 255, 0.412) 0px 1px 0px 0px,
          rgba(6, 77, 147, 0.4117647059) 0px 0px 0px 1px;
        border: solid 1px rgba(255, 255, 255, 0.412);
        border-radius: 25px 25px 0px 0px;

        @include themed() {
          background-color: t("hoverbg");
          box-shadow: t("div-shadow");
          color: t("whitecolor");
          // border: solid 1px rgba(6, 77, 147, 0.4117647059);
        }

        button {
          margin: 0px 11px 11px 11px;
          width: 92%;
          border-radius: 0px;
          padding: 10px 15px;
          font-size: 17px;
          border-radius: 25px;
          border: none;
          color: #000;
          background: #9ee86f;
          font-family: 'SegoeUI-Bold';
        }

        & button:disabled {
          margin: 15px;
          width: 92%;
          border-radius: 0px;
          padding: 10px 15px;
          font-size: 17px;
          border-radius: 25px;
          background-color: #dfdfdf;
        }

        .payment-btn {
          margin: 11px;

          @include themed() {
            color: t("whitecolor");
          }

          .spinner-container {
            display: flex;
            justify-content: center;
            align-items: center;
          }
        }

        p {
          font-family: map-get($map: $geist-fonts, $key: ui-thin);
        }

        .terms-text {
          display: block;
        }

        .total-price {
          padding: 10px 10px 0px 15px;
          display: flex;
          // margin: 0px -15px;
          display: flex;
          justify-content: space-between;
          align-items: center;

          span {
            font-family: map-get($map: $geist-fonts, $key: ui-semibold);
            font-size: 17px;
            color: #214457;

            @include themed() {
              color: t("hovercolor") !important;
            }
          }

          .paytype {
            font-size: 18px;
            margin: 0;
            color: #000;
            font-family: map-get($map: $geist-fonts, $key: ui-semibold) !important;
          }

          .total-pricechild p {
            color: #000;
            text-align: left;
            font-family: "SegoeUI";
            font-size: 16px;
            font-style: normal;
            font-weight: 100;
            line-height: normal;
            margin: 5px 2px 0px 0px;
          }
        }
      }
    }
  }

}

//md device
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .fus-checkout-page {
    background-color: #eee;

    .fus-checkout {
      .fus-top-title {
        display: flex !important;

        .fus-client-img {
          width: 4rem;
          height: 60px;
        }

        .fus-client-title {
          font-family: map-get($map: $geist-fonts, $key: ui-semibold) !important;
          text-align: center;
          margin: 1rem;
        }

        .fus-payment-btn1 {
          margin-top: 0.4rem;
          display: none;

          button {
            background: var(--theme-background);
            width: 90%;
            border: 1px;
            line-height: 34px;
            font-size: 18px;
            bottom: 0;
            position: fixed;
            font-family: map-get($map: $geist-fonts, $key: ui-semibold) !important;
            color: var(--theme-color);
          }
        }
      }

      .fus-payment-btn {
        margin-top: 1rem;

        button {
          background: #9ee86f;
          width: 90%;
          border: 1px;
          line-height: 34px;
          font-size: 18px;
          bottom: 0;
          position: fixed;
          font-family: map-get($map: $geist-fonts, $key: ui-semibold) !important;
          color: var(--theme-color);
        }
      }

      .fus-delivery-bar {
        margin-top: 2rem;

        .fus-delivery-title {
          font-weight: bold;
          font-family: map-get($map: $geist-fonts, $key: ui-semibold) !important;
          margin-bottom: 1rem;
        }

        //    delivery card
        .deliveryCard {
          border-bottom-right-radius: 0;
          border-bottom-left-radius: 0;
          border-color: #fff;

          .preorder-box {
            display: flex;
            justify-content: end;

            ul.nav.nav-tabs.nav-fill {
              width: 50%;
              background-color: #dee2e6;
              border-radius: 35px;
              padding: 4px;
              //   li.nav-item{
              //     &:first-child{
              //         border-radius: 50px 0 0 50px;
              //         border-right: none;
              //       }
              //       &:last-child{
              //         border-radius: 0 50px 50px 0;
              //         border-right: none;
              //       }
              //       button.nav-link {
              //         border-color: #173303 !important;
              //         border: 1px solid;
              //         border-radius: 50px;
              //         color: #173303;
              //         font-family: map-get($map:$geist-fonts, $key: ui-semibold) !important;
              //         font-size: 15px;
              //         height: 100%;
              //         line-height: normal;
              //       }
              //       button.nav-link.active {
              //         background: #173303;
              //         color:#fff;
              //       }

              //   }
              li.nav-item {
                &:first-child {
                  button.nav-link {
                    // border-radius: 50px 0px 0px 50px;
                    border: none;

                    &.disabled {
                      border-right: none !important;
                    }
                  }
                }

                &:last-child {
                  button.nav-link {

                    // border-radius: 0px 50px 50px 0px;
                    &.disabled {
                      border-left: 1px solid #6d6d6d !important;
                    }
                  }
                }

                button.nav-link {
                  // border-color: $new-green-dark !important;
                  // border: 1px solid;
                  border-radius: 50px;
                  color: $new-green-dark;
                  font-family: map-get($map: $geist-fonts, $key: ui-semibold);
                  font-size: 15px;
                  line-height: normal;
                  height: 100%;

                  &.disabled {
                    border: 1px solid #e0e0e0 !important;
                    color: #e0e0e0;
                  }

                  &.active {
                    // background-color: $new-green-dark;
                    // border: 1px solid $new-green-dark;
                    // color: $new-green;
                  }

                  &:hover {
                    // border-color: #102402 !important;
                    // background-color: #102402 !important;
                    color: $new-green;
                  }
                }
              }
            }
          }

          .delivery-detail {
            display: flex;
            justify-content: space-between;
            padding: 2px;
            margin-top: 10px;

            img {
              height: 32px;
              width: 30px;

              @include themed() {
                color: t("whitecolor");
              }
            }

            svg {
              margin: 5px;
            }

            .delivery-icons {
              width: 10%;
            }

            .delivery-text {
              width: 50%;

              .delivery-text-postcode {
                font-weight: 600;
                margin-bottom: 0.4rem;
              }
            }

            .delivery-action-btn {
              width: 40%;
              text-align: end;

              button {
                border-radius: 51px;
                text-align: center;
                line-height: 29px;
                border: none;
                font-size: 14px;
                font-family: map-get($map: $geist-fonts, $key: ui-semibold) !important;
                background-color: #dee2e6;
                color: #000;
              }
            }

            .border-style {
              max-width: calc(100% - 48px);
              background-color: rgb(243, 243, 243);
              border: 1px solid rgb(110 104 104);
            }
          }

          .border-style {
            display: flex;
            justify-content: end;

            .borders {
              max-width: calc(100% - 48px);
              background-color: rgb(243, 243, 243);
              width: 100%;
              border-bottom: 1px solid rgb(243, 243, 243);
            }
          }
        }

        //    estimate card
        .estimateCard {
          margin-top: 4px !important;
          border-radius: 0px !important;
          border-color: #fff;

          .estimate-detail {
            display: flex;
            justify-content: space-between;
            padding: 5px;
            border: 2px solid #f3f3f3;
            border-radius: 17px;
            margin-top: 11px;

            img {
              height: 32px;
              width: 30px;
              margin: 10px;

              @include themed() {
                color: t("whitecolor");
              }
            }

            svg {
              margin: 1rem;
            }

            .estimate-icons {
              width: 10%;
            }

            .estimate-text {
              width: 50%;

              .estimate-text-postcode {
                font-weight: 600;
                margin-bottom: 0.2rem;
              }

              .estimate-text-sub {
                margin-bottom: 0.1rem;
                font-size: 14px;
                color: #868686;
                font-family: map-get($map: $geist-fonts, $key: ui-semibold) !important;
              }
            }

            .estimate-action-btn {
              width: 40%;
              text-align: end;

              button {
                border-radius: 51px;
                text-align: center;
                line-height: 29px;
                border: none;
                font-size: 14px;
                font-family: map-get($map: $geist-fonts, $key: ui-semibold) !important;
              }
            }
          }

          .estimate-detail.active {
            border: 1px solid #3f6e21;

            svg {
              @include themed() {
                color: t("whitecolor") !important;
              }
            }
          }
        }

        //    payment card
        .paymentCard {
          margin-top: 4px !important;
          border-radius: 0px !important;
          border-color: #fff;

          .payment-detail {
            display: flex;
            justify-content: space-between;
            padding: 10px;

            svg {
              margin: 5px;
            }

            .payment-icons {
              width: 10%;
            }

            .payment-text {
              width: 50%;

              .payment-text-postcode {
                font-weight: 600;
                margin-bottom: 0.4rem;
              }
            }

            .payment-action-btn {
              width: 40%;
              text-align: end;

              button {
                border-radius: 51px;
                text-align: center;
                line-height: 29px;
                border: none;
                font-size: 14px;
                font-family: map-get($map: $geist-fonts, $key: ui-semibold) !important;
              }
            }
          }

          .border-style {
            display: flex;
            justify-content: end;

            .borders {
              max-width: calc(100% - 48px);
              background-color: rgb(243, 243, 243);
              width: 100%;
              border-bottom: 1px solid rgb(243, 243, 243);
            }
          }

          .payment-tab {
            .tabs-div {
              align-items: center;
              display: flex;
              flex-direction: row;
              gap: 20px;
              justify-content: flex-start;
              padding: 20px 0;

              label {
                align-items: center;
                background-color: #fff;
                border-radius: 10px;
                box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.25);
                cursor: pointer;
                display: flex;
                flex-direction: column;
                font-family: map-get($map: $geist-fonts, $key: ui-semibold) !important;
                font-size: 16px;
                gap: 5px;
                justify-content: center;
                padding: 7px 10px;
                position: relative;
                transition: all 0.3s ease;
                width: 150px;

                span {
                  align-items: center;
                  border: 1px solid #000;
                  border-radius: 50px;
                  display: none;
                  height: 22px;
                  justify-content: center;
                  left: 0;
                  position: absolute;
                  top: -10px;
                  width: 22px;
                }

                span.active {
                  background-color: #000;
                  display: flex;
                  color: #fff;
                }

                img {
                  height: 55px;
                  object-fit: contain;
                  width: 100%;
                }

                .cardimg {
                  width: 48px;
                }

                input {
                  display: none;
                }
              }
            }

            .selected-tab {
              .selected-tab-accord.accordion {
                .accordion-item {
                  .accordion-header button {
                    background-color: #fff;
                    border: none;
                    border-radius: 5px;
                    box-shadow: none;
                    color: #000;
                    font-family: map-get($map: $geist-fonts, $key: ui-semibold) !important;
                    font-size: 15px;
                    gap: 5px;
                    line-height: normal;
                    outline: none;
                    padding: 10px;
                  }

                  .accordion-button:not(.collapsed)::after {
                    display: none;
                  }

                  .accordion-collapse {
                    .accordion-body {
                      background-color: #f6f6f6;
                      border-radius: 5px;
                      padding: 15px;

                      form.add-card-form {
                        .StripeElement {
                          background: #fff;
                          border: 1px solid #fff;
                          border-radius: 4px;
                          box-shadow: none;
                          color: #000;
                          display: block;
                          font-family: SegoeUI;
                          font-size: 16px;
                          font-size: 1em;
                          margin: 0;
                          max-width: 500px;
                          outline: 0;
                          padding: 10px 14px;

                          @include themed() {
                            background: t("dark5");
                            border-color: t("b-color");
                          }
                        }
                      }

                      .saved-cards {
                        margin-top: 15px;

                        .save-card-form {
                          .StripeElement {
                            color: #000000;
                            font-family: map-get($map: $geist-fonts, $key: ui-thin);
                            font-size: 16px;
                            display: block;
                            margin: 0px 0 0px 0;
                            max-width: 500px;
                            padding: 10px 14px;
                            font-size: 1em;
                            box-shadow: none;
                            border: 1px solid #ffffff;
                            outline: 0;
                            border-radius: 4px;
                            background: white;
                            width: 100px;

                            @include themed() {
                              background: t("dark5");
                              border-color: t("b-color");
                            }
                          }

                          label {
                            width: 100%;
                            position: relative;
                            border: 1px solid #bbc6cc;
                            cursor: pointer;
                            margin-bottom: 15px;
                            display: flex;
                            flex-direction: row;
                            align-items: center;
                            gap: 10px;
                            padding: 5px 10px;
                            border-radius: 5px;
                            box-shadow:
                              #001e3c00 0px 1px 3px 0px,
                              #001e3c00 0px 0px 0px 1px;

                            @include themed() {
                              border: t("none");
                              background-color: t("dark5");
                              box-shadow: t("div-shadow");
                            }

                            &:last-child {
                              margin-bottom: 0px;
                            }

                            .main-data {
                              display: flex;
                              flex-direction: row;
                              align-items: center;
                              gap: 10px;

                              input {
                                display: none;
                              }

                              span.tick {
                                border: 1px solid #000;
                                width: 22px;
                                height: 22px;
                                border-radius: 50px;
                                display: flex;
                                justify-content: center;
                                align-items: center;

                                @include themed() {
                                  border-color: t("light-text");
                                }

                                &.active {
                                  background-color: #000000;

                                  svg {
                                    color: #fff;
                                  }
                                }

                                svg {
                                  color: #000;
                                  font-size: 17px;
                                }
                              }

                              img {
                                border: 1px solid #bbc6cc;
                                border-radius: 5px;
                                padding: 10px;
                                width: 70px;
                              }

                              .card-data {
                                p {
                                  margin: 0px;
                                  color: #384656;
                                  font-family: map-get($map: $geist-fonts, $key: ui-semibold);
                                  text-transform: uppercase;
                                  font-size: 16px;
                                  line-height: normal;

                                  @include themed() {
                                    color: t("hovercolor");
                                  }
                                }

                                span {
                                  color: #384656;
                                  font-family: map-get($map: $geist-fonts, $key: ui-thin);
                                  font-size: 14px;
                                  line-height: normal;

                                  @include themed() {
                                    color: t("light-text");
                                  }
                                }
                              }
                            }

                            .cvv-btn {
                              display: flex;
                              flex-direction: row;
                              align-items: center;
                              gap: 10px;
                              margin-left: auto;

                              input {
                                color: #000000;
                                font-family: map-get($map: $geist-fonts, $key: ui-thin);
                                font-size: 16px;
                                outline: none;
                                border: 1px solid #ffffff;
                                box-shadow: none;
                                height: 40px;

                                @include themed() {
                                  background-color: t("hoverbg");
                                  color: t("whitecolor");
                                  border-color: t("green3");
                                }
                              }

                              button {
                                background-color: #000;
                                padding: 6px 25px 8px 25px;
                                color: #fff;
                                line-height: normal;
                                font-size: 16px;
                                font-family: map-get($map: $geist-fonts, $key: ui-semibold);
                                border: none;
                                outline: none;
                                box-shadow: none;
                                border-radius: 5px;
                                height: 38px;
                                width: 100px;

                                @include themed() {
                                  background-color: t("themelight");
                                  color: t("themedark");
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }

              .wallet-tab {
                text-align: center;
              }
            }
          }
        }

        //    payment card
        .requestCard {
          margin-top: 4px !important;
          border-radius: 0px !important;
          border-color: #fff;

          .requestTitle {
            img {
              height: 25px;

              @include themed() {
                color: t("whitecolor") !important;
              }
            }
          }

          .tip-text {
            display: flex;
            align-items: center;
            gap: 6px;
            margin-bottom: 0px !important;

            img {
              height: 25px;

              @include themed() {
                color: t("whitecolor") !important;
              }
            }

            svg {
              @include themed() {
                color: t("whitecolor") !important;
              }
            }

            .tip-subtext {
              margin-top: 1rem;
            }
          }

          .request-detail {
            display: flex;
            justify-content: space-between;
            padding: 10px;

            form.tips-form {
              display: flex;
              flex-direction: row;
              align-items: center;
              gap: 4px;

              label {
                @include themed() {
                  border-color: t("b-color");
                  color: t("whitecolor");
                }

                height: 40px;
                vertical-align: middle;
                cursor: pointer;
                /* border: 1px solid #d4d4d4; */
                padding: 5px 30px;
                font-size: 12px;
                color: #244453;
                font-family: map-get($map: $geist-fonts, $key: ui-semibold);
                display: flex;
                flex-direction: row;
                align-items: center;
                border-radius: 6px;
                /* gap: 13px; */
                background-color: #eee;

                &:first-child {
                  border-right: none;
                }

                &:nth-child(3) {
                  border-left: none;
                  border-right: none;
                }

                &:last-child {
                  border-left: none;
                }

                &.active {
                  background-color: #9ee86f;
                  color: var(--theme-color) !important;
                  border-color: #9ee86f;

                  @include themed() {
                    border-color: t("b-color");
                    background-color: t("hoverbg");
                    color: t("hovercolor") !important;
                  }
                }

                &.tip-label {
                  padding: 0px;
                  border-right: 1px solid #d4d4d4;

                  @include themed() {
                    border-color: t("b-color");
                  }

                  input[type="text"] {
                    padding: 5px 30px;
                    display: block;
                    color: #000000;
                    font-family: map-get($map: $geist-fonts, $key: ui-thin);
                    font-size: 16px;
                    outline: none;
                    border: none;
                    box-shadow: none;
                    height: 38px;
                    background-color: transparent;
                    border-radius: 50px;

                    @include themed() {
                      background-color: t("dark5");
                      color: t("whitecolor");
                    }
                  }
                }

                &.label-btn {
                  color: #244453;
                  background-color: #eeeeee;
                }

                input {
                  display: none;
                }

                span {
                  vertical-align: middle;
                }
              }
            }

            .custom-tip {
              .accordion {
                margin-top: 15px;

                &.without-img {
                  margin-top: 10px;
                  margin-bottom: 20px;
                }

                .accordion-item {
                  @include themed() {
                    background-color: t("dark5");
                  }

                  border: none;

                  h2.accordion-header {
                    button {
                      font-family: map-get($map: $geist-fonts, $key: ui-semibold);
                      font-size: 15px;
                      padding: 0px;
                      gap: 10px;
                      border: none;
                      box-shadow: none;
                      outline: none;
                      margin-bottom: 0px;
                      color: #333333;

                      @include themed() {
                        color: t("hovercolor");
                        background-color: t("dark5");
                      }

                      &:focus {
                        border: none;
                        box-shadow: none;
                        outline: none;
                        background-color: transparent;
                      }

                      &::after {
                        display: none;
                      }

                      &:not(.collapsed) {
                        border: none;
                        box-shadow: none;
                        outline: none;
                        background-color: transparent;
                      }

                      span {
                        padding: 0px;
                        border: 1px solid #333333;
                        display: flex;
                        justify-content: center;
                        align-items: center;

                        @include themed() {
                          border-color: t("hovercolor");
                        }

                        svg {
                          fill: #333333;
                          font-size: 13px;

                          @include themed() {
                            fill: t("hovercolor");
                          }
                        }
                      }
                    }
                  }

                  .accordion-collapse {
                    .accordion-body {
                      padding: 10px 0px 0px 0px;

                      form.custom-tips-form {
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        gap: 10px;
                        margin-left: 0;

                        input {
                          color: #000000;
                          font-family: map-get($map: $geist-fonts, $key: ui-thin);
                          font-size: 16px;
                          outline: none;
                          border: 1px solid #d4d4d4;
                          box-shadow: none;
                          height: 40px;
                          width: auto;

                          @include themed() {
                            background-color: t("hoverbg");
                            border-color: t("b-color");
                            color: t("whitecolor");
                          }
                        }

                        button {
                          background-color: #000;
                          padding: 6px 25px 8px 25px;
                          color: #fff;
                          line-height: normal;
                          font-size: 15px;
                          font-family: map-get($map: $geist-fonts, $key: ui-semibold);
                          border: none;
                          outline: none;
                          box-shadow: none;
                          border-radius: 5px;
                          height: 38px;

                          @include themed() {
                            background-color: t("themelight");
                            color: t("themedark");
                          }
                        }
                      }
                    }
                  }
                }
              }
            }

            .donate-change {
              background-color: #ffffff;
              border-radius: 5px;
              box-shadow: rgb(149 157 165 / 8%) 0px 8px 24px;
              position: relative;

              @include themed() {
                background-color: t("dark5");
                // box-shadow: t("menu-shadow");
              }

              h3.donate-title {
                color: #333333;
                font-family: map-get($map: $geist-fonts, $key: ui-semibold);
                font-size: 20px;
                margin: 0px 0px 0px 0px;

                @include themed() {
                  color: t("whitecolor");
                }
              }

              .content {
                display: flex;
                flex-direction: row;
                justify-content: flex-start;
                align-items: center;
                gap: 10px;

                .form-check {
                  display: flex;
                  justify-content: flex-start;
                  align-items: center;
                  padding: 0px;
                  gap: 10px;

                  input[type="checkbox"] {
                    width: 30px;
                    height: 30px;
                    border: 1px solid black;
                    border-radius: 0px;
                    margin: 0px;
                    cursor: pointer;
                    color: #9fe870;
                    font-family: "SegoeUI";
                    font-size: 16px;
                    outline: none;
                    box-shadow: none;

                    @include themed() {
                      color: t("themelight");
                      border: t("themelight");
                    }

                    &:checked {
                      background-color: #9ee86f;

                      @include themed() {
                        color: t("themelight");
                        background-color: t("themelight");
                      }
                    }
                  }

                  label {
                    color: #333333;
                    font-family: map-get($map: $geist-fonts, $key: ui-thin);
                    font-size: 16px;
                    margin: 0px;
                    line-height: normal;

                    @include themed() {
                      color: t("light-text");
                    }
                  }
                }

                span {
                  width: 35px;
                  height: 35px;
                  background-color: #28be21;
                  border-radius: 5px;
                  padding: 3px;
                  display: flex;
                  align-items: center;
                  justify-content: center;

                  @include themed() {
                    background-color: t("themelight");
                  }

                  svg {
                    color: #ffffff;
                    font-size: 20px;
                  }
                }
              }

              a {
                color: var(--theme-background);
                line-height: normal;
                font-size: 16px;
                font-family: map-get($map: $geist-fonts, $key: ui-semibold);
                text-decoration: none;
                text-align: right;
                display: flex;
                justify-content: flex-end;

                @include themed() {
                  color: t("themelight");
                }
              }
            }
          }

          .border-style {
            display: flex;
            justify-content: end;

            .borders {
              max-width: calc(100% - 48px);
              background-color: rgb(243, 243, 243);
              width: 100%;
              border-bottom: 1px solid rgb(243, 243, 243);
            }
          }
        }

        .orderCard {
          border-top-right-radius: 0;
          border-top-left-radius: 0;
          margin-top: 4px !important;
          border-color: #fff;

          .order-summary-title {
            display: flex;
            justify-content: space-between;

            .order-summary-btn {
              width: 60%;
              text-align: end;

              button {
                border-radius: 51px;
                text-align: center;
                line-height: 29px;
                border: none;
                font-size: 14px;
                font-family: map-get($map: $geist-fonts, $key: ui-semibold) !important;
              }
            }
          }

          .order-detail {
            display: flex;
            justify-content: space-between;
            padding: 2px;
            margin-top: 10px;

            .order-count {
              min-width: 24px;
              min-height: -17px;
              /* line-height: 8px; */
              background-color: rgb(232, 232, 232);
              height: 26px;
              text-align: center;
            }

            svg {
              margin: 5px;
            }

            .order-icons {
              width: 10%;
            }

            .order-text {
              width: 50%;
              margin-left: 16px;

              .order-text-postcode {
                font-weight: 600;
                margin-bottom: 0.4rem;
              }
            }

            .order-action-btn {
              width: 63%;
              text-align: end;

              button {
                border-radius: 51px;
                text-align: center;
                line-height: 29px;
                border: none;
                font-size: 14px;
                font-family: map-get($map: $geist-fonts, $key: ui-semibold) !important;
              }
            }

            .border-style {
              max-width: calc(100% - 48px);
              background-color: rgb(243, 243, 243);
              border: 1px solid rgb(110 104 104);
            }
          }

          .border-style {
            display: flex;
            justify-content: end;

            .borders {
              max-width: calc(100% - 48px);
              background-color: rgb(243, 243, 243);
              width: 100%;
              border-bottom: 1px solid rgb(243, 243, 243);
            }
          }
        }
      }

      .fus-payment-bar {
        margin-top: 2rem;

        .fus-bill-title {
          font-weight: bold;
          font-family: map-get($map: $geist-fonts, $key: ui-semibold) !important;
          margin-bottom: 1rem;
          display: flex;
          justify-content: space-around;

          @include themed() {
            color: t("whitecolor");
          }

          .fus-order-summary-btn {
            width: 50%;
            /* float: right; */
            text-align: end;

            .fus-order-summary-sub-btn {
              border-radius: 51px;
              text-align: center;
              line-height: 29px;
              border: none;
              font-size: 14px;
              font-family: map-get($map: $geist-fonts, $key: ui-semibold) !important;
              width: 50%;
              background-color: #dee2e6;
              color: #000;
            }
          }
        }

        .fus-order-summary {
          display: flex;

          .fus-order-summary-count {
            min-width: 24px;
            min-height: -17px;
            // background-color: rgb(232, 232, 232);
            height: 26px;
            // text-align: center;
            width: 10%;
          }

          .fus-order-summary-text {
            margin-bottom: 0.4rem;
            font-family: "Geist-Regular";
            width: 60%;

            .fus-order-summary-image {
              margin-right: 10px;
            }
          }

          .fus-order-summary-price {
            width: 30%;
            text-align: right;
          }
        }

        .fus-bill-text {
          display: flex;
          justify-content: space-between;
          margin-bottom: 1px;
        }

        .fus-bill-total {
          display: flex;
          justify-content: space-between;
          border-top: 1px solid #d2d2d2;
          padding-top: 10px;

          h3 {
            font-size: 18px;
            font-family: map-get($map: $geist-fonts, $key: ui-semibold) !important;

            @include themed() {
              color: t("whitecolor") !important;
            }
          }
        }
      }
    }
  }
}

//lg device
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .fus-checkout-page {
    background-color: #eee;

    .fus-checkout {
      .fus-top-title {
        display: flex !important;

        .fus-client-img {
          width: 4rem;
          height: 60px;
        }

        .fus-client-title {
          font-family: map-get($map: $geist-fonts, $key: ui-semibold) !important;
          text-align: center;
          margin: 1rem;
        }

        .fus-payment-btn1 {
          margin-top: 0.4rem;

          button {
            background: var(--theme-background);
            width: 100%;
            border: 1px;
            line-height: 34px;
            font-size: 18px;
            font-family: map-get($map: $geist-fonts, $key: ui-semibold) !important;
            color: var(--theme-color);
          }
        }
      }

      .fus-payment-btn {
        margin-top: 1rem;

        button {
          background: #9ee86f;
          width: 100%;
          border: 1px;
          line-height: 34px;
          font-size: 18px;
          font-family: map-get($map: $geist-fonts, $key: ui-semibold) !important;
          color: var(--theme-color);
        }
      }

      .fus-delivery-bar {
        margin-top: 2rem;

        .fus-payment-card {
          width: 22rem;
          border-color: #fff !important;
        }

        .fus-delivery-title {
          font-weight: bold;
          font-family: map-get($map: $geist-fonts, $key: ui-semibold) !important;
          margin-bottom: 1rem;
        }

        //    delivery card
        .deliveryCard {
          border-bottom-right-radius: 0;
          border-bottom-left-radius: 0;
          width: 100%;
          border-color: #fff;

          .preorder-box {
            display: flex;
            justify-content: end;

            .card-title h5 {
              width: 50%;
              font-family: map-get($map: $geist-fonts, $key: ui-semibold);
            }

            ul.nav.nav-tabs.nav-fill {
              width: 50%;
              background-color: #dee2e6;
              border-radius: 35px;
              padding: 4px;
              //   li.nav-item{
              //     &:first-child{
              //         border-radius: 50px 0 0 50px;
              //         border-right: none;
              //       }
              //       &:last-child{
              //         border-radius: 0 50px 50px 0;
              //         border-right: none;
              //       }
              //       button.nav-link {
              //         border-color: #173303 !important;
              //         border: 1px solid;
              //         border-radius: 50px;
              //         color: #173303;
              //         font-family: map-get($map:$geist-fonts, $key: ui-semibold) !important;
              //         font-size: 15px;
              //         height: 100%;
              //         line-height: normal;
              //       }
              //       button.nav-link.active {
              //         background: #173303;
              //         color:#fff;
              //       }

              //   }
              li.nav-item {
                &:first-child {
                  button.nav-link {
                    // border-radius: 50px 0px 0px 50px;
                    border-right: none;

                    &.disabled {
                      border-right: none !important;
                    }
                  }
                }

                &:last-child {
                  button.nav-link {

                    // border-radius: 0px 50px 50px 0px;
                    &.disabled {
                      border-left: 1px solid #6d6d6d !important;
                    }
                  }
                }

                button.nav-link {
                  // border-color: $new-green-dark !important;
                  // border: 1px solid;
                  border-radius: 50px;
                  color: $new-green-dark;
                  font-family: map-get($map: $geist-fonts, $key: ui-semibold);
                  font-size: 15px;
                  line-height: normal;
                  height: 100%;

                  &.disabled {
                    border: 1px solid #e0e0e0 !important;
                    color: #e0e0e0;
                  }

                  &.active {
                    // background-color: $new-green-dark;
                    // border: 1px solid $new-green-dark;
                    // color: $new-green;
                  }

                  &:hover {
                    // border-color: #102402 !important;
                    // background-color: #102402 !important;
                    color: $new-green;
                  }
                }
              }
            }
          }

          .delivery-detail {
            display: flex;
            justify-content: space-between;
            padding: 2px;
            margin-top: 13px;

            img {
              height: 32px;
              width: 30px;

              @include themed() {
                color: t("whitecolor");
              }
            }

            svg {
              margin: 5px;
            }

            .delivery-icons {
              width: 10%;
            }

            .delivery-text {
              width: 50%;

              .delivery-text-postcode {
                font-weight: 600;
                margin-bottom: 0.4rem;
              }
            }

            .delivery-action-btn {
              width: 40%;
              text-align: end;

              button {
                border-radius: 51px;
                text-align: center;
                line-height: 29px;
                border: none;
                font-size: 14px;
                font-family: map-get($map: $geist-fonts, $key: ui-semibold) !important;
                background-color: #dee2e6;
                color: #000;
              }
            }

            .border-style {
              max-width: calc(100% - 48px);
              background-color: rgb(243, 243, 243);
              border: 1px solid rgb(110 104 104);
            }
          }

          .border-style {
            display: flex;
            justify-content: end;

            .borders {
              max-width: calc(100% - 48px);
              background-color: rgb(243, 243, 243);
              width: 100%;
              border-bottom: 1px solid rgb(243, 243, 243);
            }
          }
        }

        //    estimate card
        .estimateCard {
          margin-top: 4px !important;
          border-radius: 0px !important;
          width: 100%;
          border-color: #fff;

          .estimate-detail {
            display: flex;
            justify-content: space-between;
            padding: 5px;
            border: 2px solid #f3f3f3;
            border-radius: 17px;
            margin-top: 11px;

            img {
              height: 30px;
              width: 30px;
              margin: 10px;

              @include themed() {
                color: t("whitecolor");
              }
            }

            svg {
              margin: 1rem;
            }

            .estimate-icons {
              width: 10%;
            }

            .estimate-text {
              width: 50%;

              .estimate-text-postcode {
                font-weight: 600;
                margin-bottom: 0.2rem;
              }

              .estimate-text-sub {
                margin-bottom: 0.1rem;
              }
            }

            .estimate-action-btn {
              width: 40%;
              text-align: end;

              button {
                border-radius: 51px;
                text-align: center;
                line-height: 29px;
                border: none;
                font-size: 14px;
                font-family: map-get($map: $geist-fonts, $key: ui-semibold) !important;
              }
            }
          }

          &.active {
            border: 1px solid #9fe870;
          }
        }

        //    payment card
        .paymentCard {
          margin-top: 4px !important;
          border-radius: 0px !important;
          width: 100%;
          border-color: #fff;

          .payment-detail {
            display: flex;
            justify-content: space-between;
            padding: 10px;

            svg {
              margin: 5px;
            }

            .payment-icons {
              width: 10%;
            }

            .payment-text {
              width: 50%;

              .payment-text-postcode {
                font-weight: 600;
                margin-bottom: 0.4rem;
              }
            }

            .payment-action-btn {
              width: 40%;
              text-align: end;

              button {
                border-radius: 51px;
                text-align: center;
                line-height: 29px;
                border: none;
                font-size: 14px;
                font-family: map-get($map: $geist-fonts, $key: ui-semibold) !important;
              }
            }

            img {
              height: 20px;
            }
          }

          .border-style {
            display: flex;
            justify-content: end;

            .borders {
              max-width: calc(100% - 48px);
              background-color: rgb(243, 243, 243);
              width: 100%;
              border-bottom: 1px solid rgb(243, 243, 243);
            }
          }

          .payment-tab {
            .tabs-div {
              align-items: center;
              display: flex;
              flex-direction: row;
              gap: 20px;
              justify-content: flex-start;
              padding: 20px 0;

              label {
                align-items: center;
                background-color: #fff;
                border-radius: 10px;
                box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.25);
                cursor: pointer;
                display: flex;
                flex-direction: column;
                font-family: map-get($map: $geist-fonts, $key: ui-semibold) !important;
                font-size: 16px;
                gap: 5px;
                justify-content: center;
                padding: 7px 10px;
                position: relative;
                transition: all 0.3s ease;
                width: 150px;

                span {
                  align-items: center;
                  border: 1px solid #000;
                  border-radius: 50px;
                  display: none;
                  height: 22px;
                  justify-content: center;
                  left: 0;
                  position: absolute;
                  top: -10px;
                  width: 22px;
                }

                span.active {
                  background-color: #000;
                  display: flex;
                  color: #fff;
                }

                img {
                  height: 55px;
                  object-fit: contain;
                  width: 100%;
                }

                .cardimg {
                  width: 48px;
                }

                input {
                  display: none;
                }
              }
            }

            .selected-tab {
              .selected-tab-accord.accordion {
                .accordion-item {
                  .accordion-header button {
                    background-color: #fff;
                    border: none;
                    border-radius: 5px;
                    box-shadow: none;
                    color: #000;
                    font-family: map-get($map: $geist-fonts, $key: ui-semibold) !important;
                    font-size: 15px;
                    gap: 5px;
                    line-height: normal;
                    outline: none;
                    padding: 10px;
                  }

                  .accordion-button:not(.collapsed)::after {
                    display: none;
                  }

                  .accordion-collapse {
                    .accordion-body {
                      background-color: #f6f6f6;
                      border-radius: 5px;
                      padding: 15px;

                      form.add-card-form {
                        .StripeElement {
                          background: #fff;
                          border: 1px solid #fff;
                          border-radius: 4px;
                          box-shadow: none;
                          color: #000;
                          display: block;
                          font-family: SegoeUI;
                          font-size: 16px;
                          font-size: 1em;
                          margin: 0;
                          max-width: 500px;
                          outline: 0;
                          padding: 10px 14px;

                          @include themed() {
                            background: t("dark5");
                            border-color: t("b-color");
                          }
                        }
                      }

                      .saved-cards {
                        margin-top: 15px;

                        .save-card-form label {
                          align-items: center;
                          border: 1px solid #bbc6cc;
                          border-radius: 5px;
                          box-shadow:
                            0 1px 3px 0 rgba(0, 30, 60, 0),
                            0 0 0 1px rgba(0, 30, 60, 0);
                          cursor: pointer;
                          display: flex;
                          flex-direction: row;
                          gap: 10px;
                          margin-bottom: 15px;
                          padding: 5px 10px;
                          position: relative;
                          width: 100%;

                          .main-data {
                            align-items: center;
                            display: flex;
                            flex-direction: row;
                            gap: 10px;
                          }
                        }
                      }
                    }
                  }
                }
              }

              .wallet-tab {
                text-align: center;
              }
            }
          }
        }

        //    payment card
        .requestCard {
          margin-top: 4px !important;
          border-radius: 0px !important;
          width: 100%;
          border-color: #fff;

          .request-detail {
            display: flex;
            justify-content: space-between;
            padding: 10px;

            svg {
              margin: 5px;
            }

            .request-icons {
              width: 10%;
            }

            .request-text {
              width: 50%;
              font-size: 15px;

              .request-text-postcode {
                font-weight: 600;
                margin-bottom: 0.4rem;
                font-size: 15px;
              }
            }

            .request-action-btn {
              width: 40%;
              text-align: end;

              button {
                border-radius: 51px;
                text-align: center;
                line-height: 29px;
                border: none;
                font-size: 14px;
                font-family: map-get($map: $geist-fonts, $key: ui-semibold) !important;
              }
            }
          }

          .border-style {
            display: flex;
            justify-content: end;

            .borders {
              max-width: calc(100% - 48px);
              background-color: rgb(243, 243, 243);
              width: 100%;
              border-bottom: 1px solid rgb(243, 243, 243);
            }
          }
        }

        //tip card
        .requestCard {
          margin-top: 4px !important;
          border-radius: 0px !important;
          width: 100%;
          border-color: #fff;

          .requestTitle {
            img {
              height: 25px;

              @include themed() {
                color: t("whitecolor") !important;
              }
            }
          }

          .tip-text {
            display: flex;
            align-items: center;
            gap: 6px;
            margin-bottom: 0px !important;

            img {
              height: 25px;

              @include themed() {
                color: t("whitecolor") !important;
              }
            }

            svg {
              @include themed() {
                color: t("whitecolor") !important;
              }
            }

            .tip-subtext {
              margin-top: 1rem;
            }
          }

          .request-detail {
            display: flex;
            justify-content: space-between;
            padding: 10px;

            form.tips-form {
              display: flex;
              flex-direction: row;
              align-items: center;
              gap: 4px;

              label {
                @include themed() {
                  border-color: t("b-color");
                  color: t("whitecolor");
                }

                height: 40px;
                vertical-align: middle;
                cursor: pointer;
                /* border: 1px solid #d4d4d4; */
                padding: 5px 30px;
                font-size: 12px;
                color: #244453;
                font-family: map-get($map: $geist-fonts, $key: ui-semibold);
                display: flex;
                flex-direction: row;
                align-items: center;
                border-radius: 6px;
                /* gap: 13px; */
                background-color: #eee;

                &:first-child {
                  border-right: none;
                }

                &:nth-child(3) {
                  border-left: none;
                  border-right: none;
                }

                &:last-child {
                  border-left: none;
                }

                &.active {
                  background-color: #9ee86f;
                  color: var(--theme-color) !important;
                  border-color: #9ee86f;

                  @include themed() {
                    border-color: t("b-color");
                    background-color: t("hoverbg");
                    color: t("hovercolor") !important;
                  }
                }

                &.tip-label {
                  padding: 0px;
                  border-right: 1px solid #d4d4d4;

                  @include themed() {
                    border-color: t("b-color");
                  }

                  input[type="text"] {
                    padding: 5px 30px;
                    display: block;
                    color: #000000;
                    font-family: map-get($map: $geist-fonts, $key: ui-thin);
                    font-size: 16px;
                    outline: none;
                    border: none;
                    box-shadow: none;
                    height: 38px;
                    background-color: transparent;
                    border-radius: 50px;

                    @include themed() {
                      background-color: t("dark5");
                      color: t("whitecolor");
                    }
                  }
                }

                &.label-btn {
                  color: #244453;
                  background-color: #eeeeee;
                }

                input {
                  display: none;
                }

                span {
                  vertical-align: middle;
                }
              }
            }

            .custom-tip {
              .accordion {
                margin-top: 15px;

                &.without-img {
                  margin-top: 10px;
                  margin-bottom: 20px;
                }

                .accordion-item {
                  @include themed() {
                    background-color: t("dark5");
                  }

                  border: none;

                  h2.accordion-header {
                    button {
                      font-family: map-get($map: $geist-fonts, $key: ui-semibold);
                      font-size: 15px;
                      padding: 0px;
                      gap: 10px;
                      border: none;
                      box-shadow: none;
                      outline: none;
                      margin-bottom: 0px;
                      color: #333333;

                      @include themed() {
                        color: t("hovercolor");
                        background-color: t("dark5");
                      }

                      &:focus {
                        border: none;
                        box-shadow: none;
                        outline: none;
                        background-color: transparent;
                      }

                      &::after {
                        display: none;
                      }

                      &:not(.collapsed) {
                        border: none;
                        box-shadow: none;
                        outline: none;
                        background-color: transparent;
                      }

                      span {
                        padding: 0px;
                        border: 1px solid #333333;
                        display: flex;
                        justify-content: center;
                        align-items: center;

                        @include themed() {
                          border-color: t("hovercolor");
                        }

                        svg {
                          fill: #333333;
                          font-size: 13px;

                          @include themed() {
                            fill: t("hovercolor");
                          }
                        }
                      }
                    }
                  }

                  .accordion-collapse {
                    .accordion-body {
                      padding: 10px 0px 0px 0px;

                      form.custom-tips-form {
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        gap: 10px;
                        margin-left: 0;

                        input {
                          color: #000000;
                          font-family: map-get($map: $geist-fonts, $key: ui-thin);
                          font-size: 16px;
                          outline: none;
                          border: 1px solid #d4d4d4;
                          box-shadow: none;
                          height: 40px;
                          width: auto;

                          @include themed() {
                            background-color: t("hoverbg");
                            border-color: t("b-color");
                            color: t("whitecolor");
                          }
                        }

                        button {
                          background-color: #000;
                          padding: 6px 25px 8px 25px;
                          color: #fff;
                          line-height: normal;
                          font-size: 15px;
                          font-family: map-get($map: $geist-fonts, $key: ui-semibold);
                          border: none;
                          outline: none;
                          box-shadow: none;
                          border-radius: 5px;
                          height: 38px;

                          @include themed() {
                            background-color: t("themelight");
                            color: t("themedark");
                          }
                        }
                      }
                    }
                  }
                }
              }
            }

            .donate-change {
              background-color: #ffffff;
              border-radius: 5px;
              box-shadow: rgb(149 157 165 / 8%) 0px 8px 24px;
              position: relative;

              @include themed() {
                background-color: t("dark5");
                // box-shadow: t("menu-shadow");
              }

              h3.donate-title {
                color: #333333;
                font-family: map-get($map: $geist-fonts, $key: ui-semibold);
                font-size: 20px;
                margin: 0px 0px 0px 0px;

                @include themed() {
                  color: t("whitecolor");
                }
              }

              .content {
                display: flex;
                flex-direction: row;
                justify-content: flex-start;
                align-items: center;
                gap: 10px;

                .form-check {
                  display: flex;
                  justify-content: flex-start;
                  align-items: center;
                  padding: 0px;
                  gap: 10px;

                  input[type="checkbox"] {
                    width: 30px;
                    height: 30px;
                    border: 1px solid black;
                    border-radius: 0px;
                    margin: 0px;
                    cursor: pointer;
                    color: #9fe870;
                    font-family: "SegoeUI";
                    font-size: 16px;
                    outline: none;
                    box-shadow: none;

                    @include themed() {
                      color: t("themelight");
                      border: t("themelight");
                    }

                    &:checked {
                      background-color: #9ee86f;

                      @include themed() {
                        color: t("themelight");
                        background-color: t("themelight");
                      }
                    }
                  }

                  label {
                    color: #333333;
                    font-family: map-get($map: $geist-fonts, $key: ui-thin);
                    font-size: 16px;
                    margin: 0px;
                    line-height: normal;

                    @include themed() {
                      color: t("light-text");
                    }
                  }
                }

                span {
                  width: 35px;
                  height: 35px;
                  background-color: #28be21;
                  border-radius: 5px;
                  padding: 3px;
                  display: flex;
                  align-items: center;
                  justify-content: center;

                  @include themed() {
                    background-color: t("themelight");
                  }

                  svg {
                    color: #ffffff;
                    font-size: 20px;
                  }
                }
              }

              a {
                color: var(--theme-background);
                line-height: normal;
                font-size: 16px;
                font-family: map-get($map: $geist-fonts, $key: ui-semibold);
                text-decoration: none;
                text-align: right;
                display: flex;
                justify-content: flex-end;

                @include themed() {
                  color: t("themelight");
                }
              }
            }
          }

          .border-style {
            display: flex;
            justify-content: end;

            .borders {
              max-width: calc(100% - 48px);
              background-color: rgb(243, 243, 243);
              width: 100%;
              border-bottom: 1px solid rgb(243, 243, 243);
            }
          }
        }

        .orderCard {
          border-top-right-radius: 0;
          border-top-left-radius: 0;
          margin-top: 4px !important;
          width: 100%;
          border-color: #fff;

          .order-summary-title {
            display: flex;
            justify-content: space-between;

            .order-summary-btn {
              width: 50%;
              text-align: end;

              button {
                border-radius: 51px;
                text-align: center;
                line-height: 29px;
                border: none;
                font-size: 14px;
                font-family: map-get($map: $geist-fonts, $key: ui-semibold) !important;
              }
            }
          }

          .order-detail {
            display: flex;
            justify-content: space-between;
            padding: 2px;
            margin-top: 10px;

            .order-count {
              min-width: 24px;
              min-height: -17px;
              /* line-height: 8px; */
              background-color: rgb(232, 232, 232);
              height: 26px;
              text-align: center;
            }

            svg {
              margin: 5px;
            }

            .order-icons {
              width: 10%;
            }

            .order-text {
              width: 50%;

              .order-text-postcode {
                font-weight: 600;
                margin-bottom: 0.4rem;
              }
            }

            .order-action-btn {
              width: 40%;
              text-align: end;

              button {
                border-radius: 51px;
                text-align: center;
                line-height: 29px;
                border: none;
                font-size: 14px;
                font-family: map-get($map: $geist-fonts, $key: ui-semibold) !important;
              }
            }

            .border-style {
              max-width: calc(100% - 48px);
              background-color: rgb(243, 243, 243);
              border: 1px solid rgb(110 104 104);
            }
          }

          .border-style {
            display: flex;
            justify-content: end;

            .borders {
              max-width: calc(100% - 48px);
              background-color: rgb(243, 243, 243);
              width: 100%;
              border-bottom: 1px solid rgb(243, 243, 243);
            }
          }
        }
      }

      .fus-payment-bar {
        margin-top: 2rem;

        .fus-bill-title {
          font-weight: bold;
          font-family: map-get($map: $geist-fonts, $key: ui-semibold) !important;
          margin-bottom: 1rem;
          display: flex;
          justify-content: space-around;
          gap: 5px;

          @include themed() {
            color: t("whitecolor");
          }

          .fus-order-summary-btn {
            width: 100%;
            /* float: right; */
            text-align: end;

            .fus-order-summary-sub-btn {
              border-radius: 51px;
              text-align: center;
              line-height: 29px;
              border: none;
              font-size: 14px;
              font-family: map-get($map: $geist-fonts, $key: ui-semibold) !important;
              width: 50%;
              background-color: #dee2e6;
              color: #000;
            }
          }
        }

        .fus-order-summary {
          display: flex;

          .fus-order-summary-count {
            min-width: 24px;
            min-height: -17px;
            // background-color: rgb(232, 232, 232);
            height: 26px;
            // text-align: center;
            width: 10%;
          }

          .fus-order-summary-text {
            // margin-bottom: 0.4rem;
            font-family: "Geist-Regular";
            width: 60%;

            .fus-order-summary-image {
              margin-right: 10px;
            }
          }

          .fus-order-summary-price {
            width: 30%;
            text-align: center;
          }
        }

        .fus-bill-text {
          display: flex;
          justify-content: space-between;
          margin-bottom: 1px;
        }

        .fus-bill-total {
          display: flex;
          justify-content: space-between;
          border-top: 1px solid #d2d2d2;
          padding-top: 10px;

          h3 {
            font-size: 18px;
            font-family: map-get($map: $geist-fonts, $key: ui-semibold) !important;

            @include themed() {
              color: t("whitecolor") !important;
            }
          }
        }
      }
    }
  }
}

//xl device
@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .fus-checkout-page {
    background-color: #eee;

    .fus-checkout {
      .fus-top-title {
        display: flex !important;
        border: 1px solid #fff;
        background: #fff;
        border-radius: 16px;
        justify-content: space-between;
        padding: 10px;
        margin-top: 1rem;

        .fus-client-img {
          width: 4rem;
          height: 60px;
        }

        .fus-client-title {
          font-family: map-get($map: $geist-fonts, $key: ui-semibold) !important;
          text-align: center;
          margin: 1rem;
        }

        .fus-payment-btn1 {
          margin-top: 0.4rem;

          button {
            background-color: var(--theme-background);
            width: 100%;
            border: 1px;
            line-height: 34px;
            font-size: 18px;
            font-family: map-get($map: $geist-fonts, $key: ui-semibold) !important;
            color: var(--theme-color);
          }
        }
      }

      .topscrolledClass {
        position: fixed;
        z-index: 1;
        right: 0px;
        top: 66px;
        border-radius: 0px;
        margin-top: 0px;
      }

      .fus-payment-btn {
        margin-top: 1rem;

        button {
          background: #9ee86f;
          width: 100%;
          border: 1px;
          line-height: 34px;
          font-size: 18px;
          font-family: map-get($map: $geist-fonts, $key: ui-semibold) !important;
          color: var(--theme-color);
        }
      }

      .lg-style-1 {
        padding: 0px;

        .fus-delivery-bar {
          margin-top: 2rem;

          .fus-payment-card {
            width: 22rem;
            border-color: #fff !important;
          }

          .fus-delivery-title {
            font-weight: bold;
            font-family: map-get($map: $geist-fonts, $key: ui-semibold) !important;
            margin-bottom: 1rem;
          }

          //    delivery card
          .deliveryCard {
            border-bottom-right-radius: 0;
            border-bottom-left-radius: 0;
            width: 100%;
            border-color: #fff;

            .preorder-box {
              display: flex;
              justify-content: end;

              ul.nav.nav-tabs.nav-fill {
                width: 50%;
                background-color: #dee2e6;
                border-radius: 35px;
                padding: 4px;
                //   li.nav-item{
                //     &:first-child{
                //         border-radius: 50px 0 0 50px;
                //         border-right: none;
                //       }
                //       &:last-child{
                //         border-radius: 0 50px 50px 0;
                //         border-right: none;
                //       }
                //       button.nav-link {
                //         border-color: #173303 !important;
                //         border: 1px solid;
                //         border-radius: 50px;
                //         color: #173303;
                //         font-family: map-get($map:$geist-fonts, $key: ui-semibold) !important;
                //         font-size: 15px;
                //         height: 100%;
                //         line-height: normal;
                //       }
                //       button.nav-link.active {
                //         background: #173303;
                //         color:#fff;
                //       }

                //   }
                li.nav-item {
                  &:first-child {
                    button.nav-link {
                      // border-radius: 50px 0px 0px 50px;
                      border-right: none;

                      &.disabled {
                        border-right: none !important;
                      }
                    }
                  }

                  &:last-child {
                    button.nav-link {

                      // border-radius: 0px 50px 50px 0px;
                      &.disabled {
                        border-left: 1px solid #6d6d6d !important;
                      }
                    }
                  }

                  button.nav-link {
                    // border-color: $new-green-dark !important;
                    // border: 1px solid;
                    border-radius: 50px;
                    color: $new-green-dark;
                    font-family: map-get($map: $geist-fonts, $key: ui-semibold);
                    font-size: 15px;
                    line-height: normal;
                    height: 100%;

                    &.disabled {
                      border: 1px solid #e0e0e0 !important;
                      color: #e0e0e0;
                    }

                    &.active {
                      // background-color: $new-green-dark;
                      // border: 1px solid $new-green-dark;
                      // color: $new-green;
                    }

                    &:hover {
                      // border-color: #102402 !important;
                      // background-color: #102402 !important;
                      color: $new-green;
                    }
                  }
                }
              }
            }

            .delivery-detail {
              display: flex;
              justify-content: space-between;
              padding: 2px;
              margin-top: 10px;

              img {
                height: 32px;
                width: 30px;

                @include themed() {
                  color: t("whitecolor");
                }
              }

              svg {
                // margin: 5px;
                font-size: 21px;
              }

              .delivery-icons {
                width: 10%;
              }

              .delivery-text {
                width: 50%;

                .delivery-text-postcode {
                  font-weight: 600;
                  margin-bottom: 0.4rem;
                }
              }

              .delivery-action-btn {
                width: 40%;
                text-align: end;

                button {
                  border-radius: 51px;
                  text-align: center;
                  line-height: 29px;
                  border: none;
                  font-size: 14px;
                  font-family: map-get($map: $geist-fonts, $key: ui-semibold) !important;
                  background-color: #dee2e6;
                  color: #000;
                }
              }

              .border-style {
                max-width: calc(100% - 48px);
                background-color: rgb(243, 243, 243);
                border: 1px solid rgb(110 104 104);
              }
            }

            .border-style {
              display: flex;
              justify-content: end;

              .borders {
                max-width: calc(100% - 48px);
                background-color: rgb(243, 243, 243);
                width: 100%;
                border-bottom: 1px solid rgb(243, 243, 243);
              }
            }
          }

          //    estimate card
          .estimateCard {
            margin-top: 4px !important;
            border-radius: 0px !important;
            width: 100%;
            border-color: #fff;

            .estimate-detail {
              display: flex;
              justify-content: space-between;
              padding: 5px;
              border: 2px solid #f3f3f3;
              border-radius: 17px;
              margin-top: 11px;

              img {
                height: 30px;
                width: 30px;
                margin: 10px;

                @include themed() {
                  color: t("whitecolor");
                }
              }

              svg {
                margin: 1rem;
              }

              .estimate-icons {
                width: 10%;
              }

              .estimate-text {
                width: 50%;

                .estimate-text-postcode {
                  font-weight: 600;
                  margin-bottom: 0.2rem;
                }

                .estimate-text-sub {
                  margin-bottom: 0.1rem;
                }
              }

              .estimate-action-btn {
                width: 40%;
                text-align: end;

                button {
                  border-radius: 51px;
                  text-align: center;
                  line-height: 29px;
                  border: none;
                  font-size: 14px;
                  font-family: map-get($map: $geist-fonts, $key: ui-semibold) !important;
                }
              }
            }

            .estimate-detail.active {
              border: 2px solid #3f6e21;

              svg {
                @include themed() {
                  color: t("whitecolor") !important;
                }
              }
            }
          }

          //    payment card
          .paymentCard {
            margin-top: 4px !important;
            border-radius: 0px !important;
            width: 100%;
            border-color: #fff;

            .payment-detail {
              display: flex;
              justify-content: space-between;
              padding: 10px;

              svg {
                // margin: 5px;
                font-size: 27px;
              }

              .payment-icons {
                width: 10%;
              }

              .payment-text {
                width: 50%;

                .payment-text-postcode {
                  font-weight: 600;
                  margin-bottom: 0.4rem;
                }
              }

              .payment-action-btn {
                width: 40%;
                text-align: end;

                button {
                  border-radius: 51px;
                  text-align: center;
                  line-height: 29px;
                  border: none;
                  font-size: 14px;
                  font-family: map-get($map: $geist-fonts, $key: ui-semibold) !important;
                }
              }

              img {
                height: 20px;
              }
            }

            .border-style {
              display: flex;
              justify-content: end;

              .borders {
                max-width: calc(100% - 48px);
                background-color: rgb(243, 243, 243);
                width: 100%;
                border-bottom: 1px solid rgb(243, 243, 243);
              }
            }

            .payment-tab {
              .tabs-div {
                align-items: center;
                display: flex;
                flex-direction: row;
                gap: 20px;
                justify-content: flex-start;
                padding: 20px 0;

                label {
                  align-items: center;
                  background-color: #fff;
                  border-radius: 10px;
                  box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.25);
                  cursor: pointer;
                  display: flex;
                  flex-direction: column;
                  font-family: map-get($map: $geist-fonts, $key: ui-semibold) !important;
                  font-size: 16px;
                  gap: 5px;
                  justify-content: center;
                  padding: 2px;
                  position: relative;
                  transition: all 0.3s ease;
                  width: 130px;

                  span {
                    align-items: center;
                    border: 1px solid #000;
                    border-radius: 50px;
                    display: none;
                    height: 22px;
                    justify-content: center;
                    left: 0;
                    position: absolute;
                    top: -10px;
                    width: 22px;
                  }

                  span.active {
                    background-color: #000;
                    display: flex;
                    color: #fff;
                  }

                  img {
                    height: 55px;
                    object-fit: contain;
                    width: 100%;
                  }

                  .cardimg {
                    width: 48px;
                  }

                  input {
                    display: none;
                  }

                  p {
                    margin: 2px;
                  }
                }
              }

              .selected-tab {
                .selected-tab-accord.accordion {
                  .accordion-item {
                    .accordion-header button {
                      background-color: #fff;
                      border: none;
                      border-radius: 5px;
                      box-shadow: none;
                      color: #000;
                      font-family: map-get($map: $geist-fonts, $key: ui-semibold) !important;
                      font-size: 15px;
                      gap: 5px;
                      line-height: normal;
                      outline: none;
                      padding: 10px;
                    }

                    .accordion-button:not(.collapsed)::after {
                      display: none;
                    }

                    .accordion-collapse {
                      .accordion-body {
                        background-color: #f6f6f6;
                        border-radius: 5px;
                        padding: 15px;

                        form.add-card-form {
                          .StripeElement {
                            background: #fff;
                            border: 1px solid #fff;
                            border-radius: 4px;
                            box-shadow: none;
                            color: #000;
                            display: block;
                            font-family: SegoeUI;
                            font-size: 16px;
                            font-size: 1em;
                            margin: 0;
                            max-width: 500px;
                            outline: 0;
                            padding: 10px 14px;

                            @include themed() {
                              background: t("dark5");
                              border-color: t("b-color");
                            }
                          }
                        }

                        .saved-cards {
                          margin-top: 15px;

                          .save-card-form {
                            .StripeElement {
                              color: #000000;
                              font-family: map-get($map: $geist-fonts, $key: ui-thin);
                              font-size: 16px;
                              display: block;
                              margin: 0px 0 0px 0;
                              max-width: 500px;
                              padding: 10px 14px;
                              font-size: 1em;
                              box-shadow: none;
                              border: 1px solid #ffffff;
                              outline: 0;
                              border-radius: 4px;
                              background: white;
                              width: 100px;

                              @include themed() {
                                background: t("dark5");
                                border-color: t("b-color");
                              }
                            }

                            label {
                              width: 100%;
                              position: relative;
                              border: 1px solid #bbc6cc;
                              cursor: pointer;
                              margin-bottom: 15px;
                              display: flex;
                              flex-direction: row;
                              align-items: center;
                              gap: 10px;
                              padding: 5px 10px;
                              border-radius: 5px;
                              box-shadow:
                                #001e3c00 0px 1px 3px 0px,
                                #001e3c00 0px 0px 0px 1px;

                              @include themed() {
                                border: t("none");
                                background-color: t("dark5");
                                box-shadow: t("div-shadow");
                              }

                              &:last-child {
                                margin-bottom: 0px;
                              }

                              .main-data {
                                display: flex;
                                flex-direction: row;
                                align-items: center;
                                gap: 10px;

                                input {
                                  display: none;
                                }

                                span.tick {
                                  border: 1px solid #000;
                                  width: 22px;
                                  height: 22px;
                                  border-radius: 50px;
                                  display: flex;
                                  justify-content: center;
                                  align-items: center;

                                  @include themed() {
                                    border-color: t("light-text");
                                  }

                                  &.active {
                                    background-color: #000000;

                                    svg {
                                      color: #fff;
                                    }
                                  }

                                  svg {
                                    color: #000;
                                    font-size: 17px;
                                  }
                                }

                                img {
                                  border: 1px solid #bbc6cc;
                                  border-radius: 5px;
                                  padding: 10px;
                                  width: 70px;
                                }

                                .card-data {
                                  p {
                                    margin: 0px;
                                    color: #384656;
                                    font-family: map-get($map: $geist-fonts, $key: ui-semibold);
                                    text-transform: uppercase;
                                    font-size: 16px;
                                    line-height: normal;

                                    @include themed() {
                                      color: t("hovercolor");
                                    }
                                  }

                                  span {
                                    color: #384656;
                                    font-family: map-get($map: $geist-fonts, $key: ui-thin);
                                    font-size: 14px;
                                    line-height: normal;

                                    @include themed() {
                                      color: t("light-text");
                                    }
                                  }
                                }
                              }

                              .cvv-btn {
                                display: flex;
                                flex-direction: row;
                                align-items: center;
                                gap: 10px;
                                margin-left: auto;

                                input {
                                  color: #000000;
                                  font-family: map-get($map: $geist-fonts, $key: ui-thin);
                                  font-size: 16px;
                                  outline: none;
                                  border: 1px solid #ffffff;
                                  box-shadow: none;
                                  height: 40px;

                                  @include themed() {
                                    background-color: t("hoverbg");
                                    color: t("whitecolor");
                                    border-color: t("green3");
                                  }
                                }

                                button {
                                  background-color: #000;
                                  padding: 6px 25px 8px 25px;
                                  color: #fff;
                                  line-height: normal;
                                  font-size: 16px;
                                  font-family: map-get($map: $geist-fonts, $key: ui-semibold);
                                  border: none;
                                  outline: none;
                                  box-shadow: none;
                                  border-radius: 5px;
                                  height: 38px;
                                  width: 100px;

                                  @include themed() {
                                    background-color: t("themelight");
                                    color: t("themedark");
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }

                .wallet-tab {
                  text-align: center;
                }
              }
            }
          }

          //    payment card
          .requestCard {
            margin-top: 4px !important;
            border-radius: 0px !important;
            width: 100%;
            border-color: #fff;

            .request-detail {
              display: flex;
              justify-content: space-between;
              padding: 10px;

              svg {
                margin: 5px;
              }

              .request-icons {
                width: 10%;
              }

              .request-text {
                width: 50%;
                font-size: 15px;

                .request-text-postcode {
                  font-weight: 600;
                  margin-bottom: 0.4rem;
                  font-size: 15px;
                }
              }

              .request-action-btn {
                width: 40%;
                text-align: end;

                button {
                  border-radius: 51px;
                  text-align: center;
                  line-height: 29px;
                  border: none;
                  font-size: 14px;
                  font-family: map-get($map: $geist-fonts, $key: ui-semibold) !important;
                }
              }
            }

            .border-style {
              display: flex;
              justify-content: end;

              .borders {
                max-width: calc(100% - 48px);
                background-color: rgb(243, 243, 243);
                width: 100%;
                border-bottom: 1px solid rgb(243, 243, 243);
              }
            }
          }

          //tip card
          .requestCard {
            margin-top: 4px !important;
            border-radius: 0px !important;
            width: 100%;
            border-color: #fff;

            .requestTitle {
              img {
                height: 25px;

                @include themed() {
                  color: t("whitecolor") !important;
                }
              }
            }

            .tip-text {
              display: flex;
              align-items: center;
              gap: 6px;
              margin-bottom: 0px !important;

              img {
                height: 25px;

                @include themed() {
                  color: t("whitecolor") !important;
                }
              }

              svg {
                @include themed() {
                  color: t("whitecolor") !important;
                }
              }

              .tip-subtext {
                margin-top: 1rem;
              }
            }

            .request-detail {
              display: flex;
              justify-content: space-between;
              padding: 10px;

              form.tips-form {
                display: flex;
                flex-direction: row;
                align-items: center;
                gap: 10px;

                label {
                  @include themed() {
                    border-color: t("b-color");
                    color: t("whitecolor");
                  }

                  height: 40px;
                  vertical-align: middle;
                  cursor: pointer;
                  /* border: 1px solid #d4d4d4; */
                  padding: 5px 30px;
                  font-size: 16px;
                  color: #244453;
                  font-family: map-get($map: $geist-fonts, $key: ui-semibold);
                  display: flex;
                  flex-direction: row;
                  align-items: center;
                  border-radius: 6px;
                  /* gap: 13px; */
                  background-color: #eee;

                  &:first-child {
                    border-right: none;
                  }

                  &:nth-child(3) {
                    border-left: none;
                    border-right: none;
                  }

                  &:last-child {
                    border-left: none;
                  }

                  &.active {
                    background-color: #9ee86f;
                    color: var(--theme-color) !important;
                    border-color: #9ee86f;

                    @include themed() {
                      border-color: t("b-color");
                      background-color: t("hoverbg");
                      color: t("hovercolor") !important;
                    }
                  }

                  &.tip-label {
                    padding: 0px;
                    border-right: 1px solid #d4d4d4;

                    @include themed() {
                      border-color: t("b-color");
                    }

                    input[type="text"] {
                      padding: 5px 30px;
                      display: block;
                      color: #000000;
                      font-family: map-get($map: $geist-fonts, $key: ui-thin);
                      font-size: 16px;
                      outline: none;
                      border: none;
                      box-shadow: none;
                      height: 38px;
                      background-color: transparent;
                      border-radius: 50px;

                      @include themed() {
                        background-color: t("dark5");
                        color: t("whitecolor");
                      }
                    }
                  }

                  &.label-btn {
                    color: #244453;
                    background-color: #eeeeee;
                  }

                  input {
                    display: none;
                  }

                  span {
                    vertical-align: middle;
                  }
                }
              }

              .custom-tip {
                .accordion {
                  margin-top: 15px;

                  &.without-img {
                    margin-top: 10px;
                    margin-bottom: 20px;
                  }

                  .accordion-item {
                    @include themed() {
                      background-color: t("dark5");
                    }

                    border: none;

                    h2.accordion-header {
                      button {
                        font-family: map-get($map: $geist-fonts, $key: ui-semibold);
                        font-size: 15px;
                        padding: 0px;
                        gap: 10px;
                        border: none;
                        box-shadow: none;
                        outline: none;
                        margin-bottom: 0px;
                        color: #333333;

                        @include themed() {
                          color: t("hovercolor");
                          background-color: t("dark5");
                        }

                        &:focus {
                          border: none;
                          box-shadow: none;
                          outline: none;
                          background-color: transparent;
                        }

                        &::after {
                          display: none;
                        }

                        &:not(.collapsed) {
                          border: none;
                          box-shadow: none;
                          outline: none;
                          background-color: transparent;
                        }

                        span {
                          padding: 0px;
                          border: 1px solid #333333;
                          display: flex;
                          justify-content: center;
                          align-items: center;

                          @include themed() {
                            border-color: t("hovercolor");
                          }

                          svg {
                            fill: #333333;
                            font-size: 13px;

                            @include themed() {
                              fill: t("hovercolor");
                            }
                          }
                        }
                      }
                    }

                    .accordion-collapse {
                      .accordion-body {
                        padding: 10px 0px 0px 0px;

                        form.custom-tips-form {
                          display: flex;
                          flex-direction: row;
                          align-items: center;
                          gap: 10px;
                          margin-left: 0;

                          input {
                            color: #000000;
                            font-family: map-get($map: $geist-fonts, $key: ui-thin);
                            font-size: 16px;
                            outline: none;
                            border: 1px solid #d4d4d4;
                            box-shadow: none;
                            height: 40px;
                            width: auto;

                            @include themed() {
                              background-color: t("hoverbg");
                              border-color: t("b-color");
                              color: t("whitecolor");
                            }
                          }

                          button {
                            background-color: #000;
                            padding: 6px 25px 8px 25px;
                            color: #fff;
                            line-height: normal;
                            font-size: 15px;
                            font-family: map-get($map: $geist-fonts, $key: ui-semibold);
                            border: none;
                            outline: none;
                            box-shadow: none;
                            border-radius: 5px;
                            height: 38px;

                            @include themed() {
                              background-color: t("themelight");
                              color: t("themedark");
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }

              .donate-change {
                background-color: #ffffff;
                border-radius: 5px;
                box-shadow: rgb(149 157 165 / 8%) 0px 8px 24px;
                position: relative;

                @include themed() {
                  background-color: t("dark5");
                  box-shadow: none !important;
                  // box-shadow: t("menu-shadow");
                }

                h3.donate-title {
                  color: #333333;
                  font-family: map-get($map: $geist-fonts, $key: ui-semibold);
                  font-size: 20px;
                  margin: 0px 0px 0px 0px;

                  @include themed() {
                    color: t("whitecolor");
                  }
                }

                .content {
                  display: flex;
                  flex-direction: row;
                  justify-content: flex-start;
                  align-items: center;
                  gap: 10px;

                  .form-check {
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    padding: 0px;
                    gap: 10px;

                    input[type="checkbox"] {
                      width: 30px;
                      height: 20px;
                      border: 1px solid black;
                      border-radius: 0px;
                      margin: 0px;
                      cursor: pointer;
                      color: #9fe870;
                      font-family: "SegoeUI";
                      font-size: 16px;
                      outline: none;
                      box-shadow: none;

                      @include themed() {
                        color: t("themelight");
                        border: t("themelight");
                      }

                      &:checked {
                        background-color: #9ee86f;

                        @include themed() {
                          color: t("themelight");
                          background-color: t("themelight");
                        }
                      }
                    }

                    label {
                      color: #333333;
                      font-family: map-get($map: $geist-fonts, $key: ui-thin);
                      font-size: 16px;
                      margin: 0px;
                      line-height: normal;

                      @include themed() {
                        color: t("light-text");
                      }
                    }
                  }

                  span {
                    width: 35px;
                    height: 35px;
                    background-color: #28be21;
                    border-radius: 5px;
                    padding: 3px;
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    @include themed() {
                      background-color: t("themelight");
                    }

                    svg {
                      color: #ffffff;
                      font-size: 20px;
                    }
                  }
                }

                a {
                  color: var(--theme-background);
                  line-height: normal;
                  font-size: 16px;
                  font-family: map-get($map: $geist-fonts, $key: ui-semibold);
                  text-decoration: none;
                  text-align: right;
                  display: flex;
                  justify-content: flex-end;

                  @include themed() {
                    color: t("themelight");
                  }
                }
              }
            }

            .border-style {
              display: flex;
              justify-content: end;

              .borders {
                max-width: calc(100% - 48px);
                background-color: rgb(243, 243, 243);
                width: 100%;
                border-bottom: 1px solid rgb(243, 243, 243);
              }
            }
          }

          .orderCard {
            border-top-right-radius: 0;
            border-top-left-radius: 0;
            margin-top: 4px !important;
            width: 100%;
            border-color: #fff;

            .order-summary-title {
              display: flex;
              justify-content: space-between;

              .order-summary-btn {
                width: 50%;
                text-align: end;

                button {
                  border-radius: 51px;
                  text-align: center;
                  line-height: 29px;
                  border: none;
                  font-size: 14px;
                  font-family: map-get($map: $geist-fonts, $key: ui-semibold) !important;
                }
              }
            }

            .order-detail {
              display: flex;
              justify-content: space-between;
              padding: 2px;
              margin-top: 10px;

              .order-count {
                min-width: 24px;
                min-height: -17px;
                /* line-height: 8px; */
                background-color: rgb(232, 232, 232);
                height: 26px;
                text-align: center;
              }

              svg {
                margin: 5px;
              }

              .order-icons {
                width: 10%;
              }

              .order-text {
                width: 50%;

                .order-text-postcode {
                  font-weight: 600;
                  margin-bottom: 0.4rem;
                }
              }

              .order-action-btn {
                width: 40%;
                text-align: end;

                button {
                  border-radius: 51px;
                  text-align: center;
                  line-height: 29px;
                  border: none;
                  font-size: 14px;
                  font-family: map-get($map: $geist-fonts, $key: ui-semibold) !important;
                }
              }

              .border-style {
                max-width: calc(100% - 48px);
                background-color: rgb(243, 243, 243);
                border: 1px solid rgb(110 104 104);
              }
            }

            .border-style {
              display: flex;
              justify-content: end;

              .borders {
                max-width: calc(100% - 48px);
                background-color: rgb(243, 243, 243);
                width: 100%;
                border-bottom: 1px solid rgb(243, 243, 243);
              }
            }
          }
        }
      }

      .lg-style-2 {
        padding: 0px 7px 0px 15px;

        .fus-payment-bar {
          margin-top: 2rem;

          .fus-bill-title {
            font-weight: bold;
            font-family: map-get($map: $geist-fonts, $key: ui-semibold) !important;
            margin-bottom: 1rem;
            display: flex;
            gap: 5px;

            @include themed() {
              color: t("whitecolor");
            }

            // justify-content: space-around;
            .fus-order-summary-btn {
              width: 50%;
              /* float: right; */
              text-align: end;

              .fus-order-summary-sub-btn {
                border-radius: 51px;
                text-align: center;
                line-height: 29px;
                border: none;
                font-size: 14px;
                font-family: map-get($map: $geist-fonts, $key: ui-semibold) !important;
                width: 50%;
                background-color: #dee2e6;
                color: #000;
              }
            }
          }

          .fus-order-summary {
            display: flex;
            align-items: center;

            .fus-order-summary-count {
              min-width: 24px;
              min-height: -17px;
              // background-color: rgb(232, 232, 232);
              height: 26px;
              // text-align: center;
              width: 10%;
              font-family: "Geist-Regular";
            }

            .fus-order-summary-text {
              // font-weight: 600;
              // margin-bottom: 0.4rem;
              font-family: "Geist-Regular";
              width: 60%;
              color: #868686;

              .fus-order-summary-image {
                margin-right: 10px;
              }
            }

            .fus-order-summary-price {
              width: 30%;
              text-align: right;
              margin-bottom: 1rem;
              font-family: "Geist-Regular";
            }
          }

          .fus-bill-text {
            display: flex;
            justify-content: space-between;
            margin-bottom: 1px;
          }

          .fus-bill-total {
            display: flex;
            justify-content: space-between;
            border-top: 1px solid #d2d2d2;
            padding-top: 10px;

            h3 {
              font-size: 18px;
              font-family: map-get($map: $geist-fonts, $key: ui-semibold) !important;

              @include themed() {
                color: t("whitecolor");
              }
            }
          }
        }
      }

      .scrolledClass {
        position: fixed;
        right: 0;
        top: 150px;
      }
    }
  }
}

//xxl device
@media only screen and (min-width: 1440px) {
  .fus-checkout-page {
    background-color: #eee;

    .fus-checkout {
      .fus-top-title {
        display: flex !important;

        .fus-client-img {
          width: 4rem;
          height: 60px;
        }

        .fus-client-title {
          font-family: map-get($map: $geist-fonts, $key: ui-semibold) !important;
          text-align: center;
          margin: 1rem;
        }

        .fus-payment-btn1 {
          margin-top: 0.4rem;

          button {
            background-color: var(--theme-background);
            width: 100%;
            border: 1px;
            line-height: 34px;
            font-size: 18px;
            font-family: map-get($map: $geist-fonts, $key: ui-semibold) !important;
            color: var(--theme-color);
          }
        }

        .fus-payment-btn {
          margin-top: 0.4rem;

          button {
            background: #9ee86f;
            width: 100%;
            border: 1px;
            line-height: 34px;
            font-size: 18px;
            font-family: map-get($map: $geist-fonts, $key: ui-semibold) !important;
            color: var(--theme-color);
          }
        }
      }

      .topscrolledClass {
        position: fixed;
        z-index: 1;
        right: 0px;
        top: 66px;
        border-radius: 0px;
        margin-top: 0px;
      }

      .fus-payment-btn {
        margin-top: 1rem;

        button {
          background: #9ee86f;
          width: 100%;
          border: 1px;
          line-height: 34px;
          font-size: 18px;
          font-family: map-get($map: $geist-fonts, $key: ui-semibold) !important;
          color: var(--theme-color);
        }
      }

      .fus-delivery-bar {
        margin-top: 2rem;

        .fus-payment-card {
          width: 22rem;
          border-color: #fff !important;
        }

        .fus-delivery-title {
          font-weight: bold;
          font-family: map-get($map: $geist-fonts, $key: ui-semibold) !important;
          margin-bottom: 1rem;
        }

        //    delivery card
        .deliveryCard {
          border-bottom-right-radius: 0;
          border-bottom-left-radius: 0;
          width: 100%;
          border-color: #fff;

          .preorder-box {
            display: flex;
            justify-content: end;

            ul.nav.nav-tabs.nav-fill {
              width: 50%;
              background-color: #dee2e6;
              border-radius: 35px;
              padding: 4px;
              //   li.nav-item{
              //     &:first-child{
              //         border-radius: 50px 0 0 50px;
              //         border-right: none;
              //       }
              //       &:last-child{
              //         border-radius: 0 50px 50px 0;
              //         border-right: none;
              //       }
              //       button.nav-link {
              //         border-color: #173303 !important;
              //         border: 1px solid;
              //         border-radius: 50px;
              //         color: #173303;
              //         font-family: map-get($map:$geist-fonts, $key: ui-semibold) !important;
              //         font-size: 15px;
              //         height: 100%;
              //         line-height: normal;
              //       }
              //       button.nav-link.active {
              //         background: #173303;
              //         color:#fff;
              //       }

              //   }
              li.nav-item {
                &:first-child {
                  button.nav-link {
                    // border-radius: 50px 0px 0px 50px;
                    border-right: none;

                    &.disabled {
                      border-right: none !important;
                    }
                  }
                }

                &:last-child {
                  button.nav-link {

                    // border-radius: 0px 50px 50px 0px;
                    &.disabled {
                      border-left: 1px solid #6d6d6d !important;
                    }
                  }
                }

                button.nav-link {
                  // border-color: $new-green-dark !important;
                  // border: 1px solid;
                  border-radius: 50px;
                  color: $new-green-dark;
                  font-family: map-get($map: $geist-fonts, $key: ui-semibold);
                  font-size: 15px;
                  line-height: normal;
                  height: 100%;

                  &.disabled {
                    border: 1px solid #e0e0e0 !important;
                    color: #e0e0e0;
                  }

                  &.active {
                    // background-color: $new-green-dark;
                    // border: 1px solid $new-green-dark;
                    // color: $new-green;
                  }

                  &:hover {
                    // border-color: #102402 !important;
                    // background-color: #102402 !important;
                    color: $new-green;
                  }
                }
              }
            }
          }

          .delivery-detail {
            display: flex;
            justify-content: space-between;
            padding: 2px;
            margin-top: 10px;

            img {
              height: 32px;
              width: 30px;

              @include themed() {
                color: t("whitecolor");
              }
            }

            svg {
              margin: 5px;
            }

            .delivery-icons {
              width: 10%;
            }

            .delivery-text {
              width: 50%;

              .delivery-text-postcode {
                font-weight: 600;
                margin-bottom: 0.4rem;
              }
            }

            .delivery-action-btn {
              width: 40%;
              text-align: end;

              button {
                border-radius: 51px;
                text-align: center;
                line-height: 29px;
                border: none;
                font-size: 14px;
                font-family: map-get($map: $geist-fonts, $key: ui-semibold) !important;
                background-color: #dee2e6;
                color: #000;
              }
            }

            .border-style {
              max-width: calc(100% - 48px);
              background-color: rgb(243, 243, 243);
              border: 1px solid rgb(110 104 104);
            }
          }

          .border-style {
            display: flex;
            justify-content: end;

            .borders {
              max-width: calc(100% - 48px);
              background-color: rgb(243, 243, 243);
              width: 100%;
              border-bottom: 1px solid rgb(243, 243, 243);
            }
          }
        }

        //    estimate card
        .estimateCard {
          margin-top: 4px !important;
          border-radius: 0px !important;
          width: 100%;
          border-color: #fff;

          .estimate-detail {
            display: flex;
            justify-content: space-between;
            padding: 5px;
            border: 2px solid #f3f3f3;
            border-radius: 17px;
            margin-top: 11px;

            img {
              height: 30px;
              width: 30px;
              margin: 10px;

              @include themed() {
                color: t("whitecolor");
              }
            }

            svg {
              margin: 1rem;
            }

            .estimate-icons {
              width: 10%;
            }

            .estimate-text {
              width: 50%;

              .estimate-text-postcode {
                font-weight: 600;
                margin-bottom: 0.2rem;
              }

              .estimate-text-sub {
                margin-bottom: 0.1rem;
              }
            }

            .estimate-action-btn {
              width: 40%;
              text-align: end;

              button {
                border-radius: 51px;
                text-align: center;
                line-height: 29px;
                border: none;
                font-size: 14px;
                font-family: map-get($map: $geist-fonts, $key: ui-semibold) !important;
              }
            }
          }

          .estimate-detail.active {
            border: 2px solid #3f6e21;

            svg {
              @include themed() {
                color: t("whitecolor") !important;
              }
            }
          }
        }

        //    payment card
        .paymentCard {
          margin-top: 4px !important;
          border-radius: 0px !important;
          width: 100%;
          border-color: #fff;

          .payment-detail {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 10px;

            svg {
              margin: 5px;
            }

            .payment-icons {
              width: 10%;
            }

            .payment-text {
              width: 50%;

              .payment-text-postcode {
                font-weight: 600;
                margin-bottom: 0.4rem;
              }
            }

            .payment-action-btn {
              width: 40%;
              text-align: end;

              button {
                border-radius: 51px;
                text-align: center;
                line-height: 29px;
                border: none;
                font-size: 14px;
                font-family: map-get($map: $geist-fonts, $key: ui-semibold) !important;
              }
            }

            img {
              height: 20px;
            }
          }

          .border-style {
            display: flex;
            justify-content: end;

            .borders {
              max-width: calc(100% - 48px);
              background-color: rgb(243, 243, 243);
              width: 100%;
              border-bottom: 1px solid rgb(243, 243, 243);
            }
          }

          .payment-tab {
            .tabs-div {
              align-items: center;
              display: flex;
              flex-direction: row;
              gap: 20px;
              justify-content: flex-start;
              padding: 20px 0;

              label {
                align-items: center;
                background-color: #fff;
                border-radius: 10px;
                box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.25);
                cursor: pointer;
                display: flex;
                flex-direction: column;
                font-family: map-get($map: $geist-fonts, $key: ui-semibold) !important;
                font-size: 16px;
                gap: 5px;
                justify-content: center;
                padding: 2px;
                position: relative;
                transition: all 0.3s ease;
                width: 130px;

                span {
                  align-items: center;
                  border: 1px solid #000;
                  border-radius: 50px;
                  display: none;
                  height: 22px;
                  justify-content: center;
                  left: 0;
                  position: absolute;
                  top: -10px;
                  width: 22px;
                }

                span.active {
                  background-color: #000;
                  display: flex;
                  color: #fff;
                }

                img {
                  height: 55px;
                  object-fit: contain;
                  width: 100%;
                }

                .cardimg {
                  width: 48px;
                }

                input {
                  display: none;
                }

                p {
                  margin: 2px;
                }
              }
            }

            .selected-tab {
              .selected-tab-accord.accordion {
                .accordion-item {
                  .accordion-header button {
                    background-color: #fff;
                    border: none;
                    border-radius: 5px;
                    box-shadow: none;
                    color: #000;
                    font-family: map-get($map: $geist-fonts, $key: ui-semibold) !important;
                    font-size: 15px;
                    gap: 5px;
                    line-height: normal;
                    outline: none;
                    padding: 10px;
                  }

                  .accordion-button:not(.collapsed)::after {
                    display: none;
                  }

                  .accordion-collapse {
                    .accordion-body {
                      background-color: #f6f6f6;
                      border-radius: 5px;
                      padding: 15px;

                      form.add-card-form {
                        .StripeElement {
                          background: #fff;
                          border: 1px solid #fff;
                          border-radius: 4px;
                          box-shadow: none;
                          color: #000;
                          display: block;
                          font-family: SegoeUI;
                          font-size: 16px;
                          font-size: 1em;
                          margin: 0;
                          max-width: 500px;
                          outline: 0;
                          padding: 10px 14px;

                          @include themed() {
                            background: t("dark5");
                            border-color: t("b-color");
                          }
                        }
                      }
                    }
                  }
                }
              }

              .wallet-tab {
                text-align: center;
              }
            }
          }
        }

        //    payment card
        .requestCard {
          margin-top: 4px !important;
          border-radius: 0px !important;
          width: 100%;
          border-color: #fff;

          .request-detail {
            display: flex;
            justify-content: space-between;
            padding: 10px;

            svg {
              margin: 5px;
            }

            .request-icons {
              width: 10%;
            }

            .request-text {
              width: 50%;
              font-size: 15px;

              .request-text-postcode {
                font-weight: 600;
                margin-bottom: 0.4rem;
                font-size: 15px;
              }
            }

            .request-action-btn {
              width: 40%;
              text-align: end;

              button {
                border-radius: 51px;
                text-align: center;
                line-height: 29px;
                border: none;
                font-size: 14px;
                font-family: map-get($map: $geist-fonts, $key: ui-semibold) !important;
              }
            }
          }

          .border-style {
            display: flex;
            justify-content: end;

            .borders {
              max-width: calc(100% - 48px);
              background-color: rgb(243, 243, 243);
              width: 100%;
              border-bottom: 1px solid rgb(243, 243, 243);
            }
          }
        }

        //tip card
        .requestCard {
          margin-top: 4px !important;
          border-radius: 0px !important;
          width: 100%;
          border-color: #fff;

          .requestTitle {
            img {
              height: 25px;

              @include themed() {
                color: t("whitecolor") !important;
              }
            }
          }

          .tip-text {
            display: flex;
            align-items: center;
            gap: 6px;
            margin-bottom: 0px !important;

            img {
              height: 25px;

              @include themed() {
                color: t("whitecolor") !important;
              }
            }

            svg {
              @include themed() {
                color: t("whitecolor") !important;
              }
            }

            .tip-subtext {
              margin-top: 1rem;
            }
          }

          .request-detail {
            display: flex;
            justify-content: space-between;
            padding: 10px;

            form.tips-form {
              display: flex;
              flex-direction: row;
              align-items: center;
              gap: 10px;

              label {
                @include themed() {
                  border-color: t("b-color");
                  color: t("whitecolor");
                }

                height: 40px;
                vertical-align: middle;
                cursor: pointer;
                /* border: 1px solid #d4d4d4; */
                padding: 5px 30px;
                font-size: 16px;
                color: #244453;
                font-family: map-get($map: $geist-fonts, $key: ui-semibold);
                display: flex;
                flex-direction: row;
                align-items: center;
                border-radius: 6px;
                /* gap: 13px; */
                background-color: #eee;

                &:first-child {
                  border-right: none;
                }

                &:nth-child(3) {
                  border-left: none;
                  border-right: none;
                }

                &:last-child {
                  border-left: none;
                }

                &.active {
                  background-color: var(--theme-background);
                  color: var(--theme-color) !important;
                  border-color: var(--theme-background);

                  @include themed() {
                    border-color: t("b-color");
                    background-color: t("hoverbg");
                    color: t("hovercolor") !important;
                  }
                }

                &.tip-label {
                  padding: 0px;
                  border-right: 1px solid #d4d4d4;

                  @include themed() {
                    border-color: t("b-color");
                  }

                  input[type="text"] {
                    padding: 5px 30px;
                    display: block;
                    color: #000000;
                    font-family: map-get($map: $geist-fonts, $key: ui-thin);
                    font-size: 16px;
                    outline: none;
                    border: none;
                    box-shadow: none;
                    height: 38px;
                    background-color: transparent;
                    border-radius: 50px;

                    @include themed() {
                      background-color: t("dark5");
                      color: t("whitecolor");
                    }
                  }
                }

                &.label-btn {
                  color: #244453;
                  background-color: #eeeeee;
                }

                input {
                  display: none;
                }

                span {
                  vertical-align: middle;
                }
              }
            }

            .custom-tip {
              .accordion {
                margin-top: 15px;

                &.without-img {
                  margin-top: 10px;
                  margin-bottom: 20px;
                }

                .accordion-item {
                  @include themed() {
                    background-color: t("dark5");
                  }

                  border: none;

                  h2.accordion-header {
                    button {
                      font-family: map-get($map: $geist-fonts, $key: ui-semibold);
                      font-size: 15px;
                      padding: 0px;
                      gap: 10px;
                      border: none;
                      box-shadow: none;
                      outline: none;
                      margin-bottom: 0px;
                      color: #333333;

                      @include themed() {
                        color: t("hovercolor");
                        background-color: t("dark5");
                      }

                      &:focus {
                        border: none;
                        box-shadow: none;
                        outline: none;
                        background-color: transparent;
                      }

                      &::after {
                        display: none;
                      }

                      &:not(.collapsed) {
                        border: none;
                        box-shadow: none;
                        outline: none;
                        background-color: transparent;
                      }

                      span {
                        padding: 0px;
                        border: 1px solid #333333;
                        display: flex;
                        justify-content: center;
                        align-items: center;

                        @include themed() {
                          border-color: t("hovercolor");
                        }

                        svg {
                          fill: #333333;
                          font-size: 13px;

                          @include themed() {
                            fill: t("hovercolor");
                          }
                        }
                      }
                    }
                  }

                  .accordion-collapse {
                    .accordion-body {
                      padding: 10px 0px 0px 0px;

                      form.custom-tips-form {
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        gap: 10px;
                        margin-left: 0;

                        input {
                          color: #000000;
                          font-family: map-get($map: $geist-fonts, $key: ui-thin);
                          font-size: 16px;
                          outline: none;
                          border: 1px solid #d4d4d4;
                          box-shadow: none;
                          height: 40px;
                          width: auto;

                          @include themed() {
                            background-color: t("hoverbg");
                            border-color: t("b-color");
                            color: t("whitecolor");
                          }
                        }

                        button {
                          background-color: #000;
                          padding: 6px 25px 8px 25px;
                          color: #fff;
                          line-height: normal;
                          font-size: 15px;
                          font-family: map-get($map: $geist-fonts, $key: ui-semibold);
                          border: none;
                          outline: none;
                          box-shadow: none;
                          border-radius: 5px;
                          height: 38px;

                          @include themed() {
                            background-color: t("themelight");
                            color: t("themedark");
                          }
                        }
                      }
                    }
                  }
                }
              }
            }

            .donate-change {
              background-color: #ffffff;
              border-radius: 5px;
              box-shadow: rgb(149 157 165 / 8%) 0px 8px 24px;
              position: relative;

              @include themed() {
                background-color: t("dark5");
                box-shadow: none !important;
                // box-shadow: t("menu-shadow");
              }

              h3.donate-title {
                color: #333333;
                font-family: map-get($map: $geist-fonts, $key: ui-semibold);
                font-size: 20px;
                margin: 0px 0px 0px 0px;

                @include themed() {
                  color: t("whitecolor");
                }
              }

              .content {
                display: flex;
                flex-direction: row;
                justify-content: flex-start;
                align-items: center;
                gap: 10px;

                .form-check {
                  display: flex;
                  justify-content: flex-start;
                  align-items: center;
                  padding: 0px;
                  gap: 10px;

                  input[type="checkbox"] {
                    width: 30px;
                    height: 20px;
                    border: 1px solid black;
                    border-radius: 0px;
                    margin: 0px;
                    cursor: pointer;
                    color:#9fe870;
                    font-family: "SegoeUI";
                    font-size: 16px;
                    outline: none;
                    box-shadow: none;

                    @include themed() {
                      color: t("themelight");
                      border: t("themelight");
                    }

                    &:checked {
                      background-color: #9ee86f;

                      @include themed() {
                        color: t("themelight");
                        background-color: t("themelight");
                      }
                    }
                  }

                  label {
                    color: #333333;
                    font-family: map-get($map: $geist-fonts, $key: ui-thin);
                    font-size: 16px;
                    margin: 0px;
                    line-height: normal;

                    @include themed() {
                      color: t("light-text");
                    }
                  }
                }

                span {
                  width: 35px;
                  height: 35px;
                  background-color: #28be21;
                  border-radius: 5px;
                  padding: 3px;
                  display: flex;
                  align-items: center;
                  justify-content: center;

                  @include themed() {
                    background-color: t("themelight");
                  }

                  svg {
                    color: #ffffff;
                    font-size: 20px;
                  }
                }
              }

              a {
                color: var(--theme-background);
                line-height: normal;
                font-size: 16px;
                font-family: map-get($map: $geist-fonts, $key: ui-semibold);
                text-decoration: none;
                text-align: right;
                display: flex;
                justify-content: flex-end;

                @include themed() {
                  color: t("themelight");
                }
              }
            }
          }

          .border-style {
            display: flex;
            justify-content: end;

            .borders {
              max-width: calc(100% - 48px);
              background-color: rgb(243, 243, 243);
              width: 100%;
              border-bottom: 1px solid rgb(243, 243, 243);
            }
          }
        }

        .orderCard {
          border-top-right-radius: 0;
          border-top-left-radius: 0;
          margin-top: 4px !important;
          width: 100%;
          border-color: #fff;

          .order-summary-title {
            display: flex;
            justify-content: space-between;

            .order-summary-btn {
              width: 50%;
              text-align: end;

              button {
                border-radius: 51px;
                text-align: center;
                line-height: 29px;
                border: none;
                font-size: 14px;
                font-family: map-get($map: $geist-fonts, $key: ui-semibold) !important;
              }
            }
          }

          .order-detail {
            display: flex;
            justify-content: space-between;
            padding: 2px;
            margin-top: 10px;

            .order-count {
              min-width: 24px;
              min-height: -17px;
              /* line-height: 8px; */
              background-color: rgb(232, 232, 232);
              height: 26px;
              text-align: center;
            }

            svg {
              margin: 5px;
            }

            .order-icons {
              width: 10%;
            }

            .order-text {
              width: 50%;

              .order-text-postcode {
                font-weight: 600;
                margin-bottom: 0.4rem;
              }
            }

            .order-action-btn {
              width: 40%;
              text-align: end;

              button {
                border-radius: 51px;
                text-align: center;
                line-height: 29px;
                border: none;
                font-size: 14px;
                font-family: map-get($map: $geist-fonts, $key: ui-semibold) !important;
              }
            }

            .border-style {
              max-width: calc(100% - 48px);
              background-color: rgb(243, 243, 243);
              border: 1px solid rgb(110 104 104);
            }
          }

          .border-style {
            display: flex;
            justify-content: end;

            .borders {
              max-width: calc(100% - 48px);
              background-color: rgb(243, 243, 243);
              width: 100%;
              border-bottom: 1px solid rgb(243, 243, 243);
            }
          }
        }
      }

      .fus-payment-bar {
        margin-top: 2rem;

        .fus-bill-title {
          font-weight: bold;
          font-family: map-get($map: $geist-fonts, $key: ui-semibold) !important;
          margin-bottom: 1rem;
          display: flex;

          @include themed() {
            color: t("whitecolor");
          }

          // justify-content: space-around;
          .fus-order-summary-btn {
            width: 50%;
            /* float: right; */
            text-align: end;

            .fus-order-summary-sub-btn {
              border-radius: 51px;
              text-align: center;
              line-height: 29px;
              border: none;
              font-size: 14px;
              font-family: map-get($map: $geist-fonts, $key: ui-semibold) !important;
              width: 50%;
              background-color: #dee2e6;
              color: #000;
            }
          }
        }

        .fus-order-summary {
          display: flex;
          align-items: center;

          .fus-order-summary-count {
            min-width: 24px;
            min-height: -17px;
            // background-color: rgb(232, 232, 232);
            height: 26px;
            // text-align: center;
            width: 10%;
            font-family: "Geist-Regular";

            .fus-order-summary-image {
              margin-right: 10px;
            }
          }

          .fus-order-summary-text {
            // margin-bottom: 0.4rem;
            font-family: "Geist-Regular";
            width: 60%;
            color: #868686;
          }

          .fus-order-summary-price {
            width: 30%;
            text-align: end;
            font-family: "Geist-Regular";
          }
        }

        .fus-bill-text {
          display: flex;
          justify-content: space-between;
          margin-bottom: 1px;
        }

        .fus-bill-total {
          display: flex;
          justify-content: space-between;
          border-top: 1px solid #d2d2d2;
          padding-top: 10px;

          h3 {
            font-size: 18px;
            font-family: map-get($map: $geist-fonts, $key: ui-semibold) !important;

            @include themed() {
              color: t("whitecolor");
            }
          }
        }
      }

      .scrolledClass {
        position: fixed;
        right: 0;
        top: 150px;
      }
    }
  }
}

//modal estimation
.estimateBody {
  .swiper-button-next {
    font-size: 12px !important;
    /* border: 2px solid #eee !important; */
    border-radius: 23px;
    width: 40px !important;
    height: 40px !important;
    color: #000 !important;
    background: #fff;
    top: 45px !important;
    position: absolute;
    right: 0px !important;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 8px, rgba(0, 0, 0, 0.04) 0px 4px 4px;

    svg {
      font-size: 16px;
    }
  }

  .swiper-button-next:after {
    // content: "next";
    font-size: 15px !important;
    content: none !important;
  }

  .swiper-button-prev {
    font-size: 12px !important;
    /* border: 2px solid #eee !important; */
    border-radius: 23px;
    width: 40px !important;
    height: 40px !important;
    color: #000 !important;
    background: #fff;
    top: 45px !important;
    position: absolute;
    left: -8px !important;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 8px, rgba(0, 0, 0, 0.04) 0px 4px 4px;

    svg {
      font-size: 16px;
    }
  }

  .swiper-button-prev:after {
    font-size: 14px !important;
    content: none !important;
  }

  .swiper-button-disabled {
    display: none !important;
  }
}

.estimateTimetable {
  height: 300px !important;
  overflow-y: scroll !important;
  margin-top: 1rem;
}

.estimateTiming {
  display: flex;
  justify-content: space-between;
  margin: 20px;
  align-items: center;
  border-bottom: 1px solid hsla(0, 0%, 93%, 0.933);
  padding-bottom: 20px;

  svg {}

  label {
    font-family: map-get($map: $geist-fonts, $key: ui-semibold);
    font-size: 16px !important;
  }

  input[type="radio"] {
    box-shadow: 0px 0px 0px 1px #e8e8e8;
    font-size: 3em;
    margin-right: 7px;
    border: 2px solid #fff;
    background-clip: border-box;
    border-radius: 50%;
    appearance: none;
    background-color: #e8e8e8;
    transition:
      background-color 0.3s,
      box-shadow 0.3s;
    width: 20px;
    height: 20px;

    &:checked {
      box-shadow: 0px 0px 0px 2px #e8e8e8;
      background-color: #9ee86f;
    }
  }
}

.scheduleBtn {
  float: right;
  margin-top: 2rem;
  width: 100%;

  button.estimateCancelBtn {
    background-color: #eeeeee !important;
    color: #000;
    font-family: map-get($map: $geist-fonts, $key: ui-semibold);

    &:focus {
      background: #eeeeee !important;
    }

    &:focus {
      border: none;
      box-shadow: none;
      outline: none;
      background-color: #eeeeee !important;
    }
  }

  button {
    background: #9ee86f;
    width: 100%;
    border: 1px;
    line-height: 34px;
    font-size: 18px;
    color: var(--theme-color);
    font-family: map-get($map: $geist-fonts, $key: ui-semibold);

    &:focus {
      background: var(--theme-background);
    }

    &:focus {
      border: none;
      box-shadow: none;
      outline: none;
      background-color: var(--theme-background);
    }

    &::after {
      display: none;
    }

    &:not(.collapsed) {
      border: none;
      box-shadow: none;
      outline: none;
      background-color:#9ee86f;
    }

    &:disabled {
      color: #000;
      background-color: #eeeeee !important;
    }
  }
}

.estimateHeader {
  border-bottom: 1px solid #ffff !important;
  text-align: left;
  flex-direction: row-reverse !important;

  .modal-title {
    font-family: map-get($map: $geist-fonts, $key: ui-semibold) !important;
    text-align: center;
    width: 90%;
  }

  button {
    margin: 0px;
  }
}

.estimateTimeCard {
  background-color: #fff;
  border: 2px solid #f1f1f1;
  height: 80px;

  &.cardactive {
    border: 2px solid #000;
  }

  p {
    font-family: map-get($map: $geist-fonts, $key: ui-thin);
    margin-bottom: 0px;
    text-align: left;
  }

  .card-body {
    .card-text {
      font-family: map-get($map: $geist-fonts, $key: ui-semibold);
    }
  }
}

//estimate card xs device
@media only screen and (min-width: 320px) and (max-width: 420px) {
  .estimateModal {
    .modal-dialog.modal-md {
      height: 100% !important;
      margin: 0;
    }
  }

  .estimateBody {
    .swiper-button-next {
      display: none !important;
    }

    .swiper-button-prev {
      display: none !important;
    }

    .swiper-button-next:after {
      display: none !important;
    }

    .swiper-button-prev:after {
      display: none !important;
    }

    .card-text {
      font-size: 15px;
      font-family: map-get($map: $geist-fonts, $key: ui-semibold) !important;
    }

    .card-title {
      font-family: map-get($map: $geist-fonts, $key: ui-semibold) !important;
    }
  }
}

@media only screen and (min-width: 420px) and (max-width: 567px) {
  .estimateModal {
    .modal-dialog.modal-md {
      height: 100% !important;
      margin: 0;
    }
  }

  .estimateTimetable {
    height: 350px !important;
    overflow-y: scroll !important;
    margin-top: 1rem;
  }

}

//modal estimation error
.estimateErrorModal {
  .estimateErrorHeader {
    .btn-close {
      margin: 0 !important;
      color: #000 !important;
      font-size: 17px !important;
      font-weight: bold !important;
    }

    .estimateErrorText {
      font-size: 18px;
      font-weight: 500;
    }
  }
}

//
@media only screen and (min-width: 991px) {
  .estimateModal {
    text-align: center;
  }
}