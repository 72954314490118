@import "../../assets/scss/main.scss";
/* Burger Menu css */
nav {
  .navbar-toggler {
    padding: 0px;
    border: none;
    box-shadow: none;
  }
  .navbar-toggler:focus {
    box-shadow: none;
  }
  .burger-menu {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    -ms-flex-pack: justify;
    justify-content: space-between;
    width: 24px;
    height: 20px;
    cursor: pointer;
    transition: -webkit-transform 330ms ease-out;
    transition: transform 330ms ease-out;
    transition: transform 330ms ease-out, -webkit-transform 330ms ease-out;
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
  }
  .line-menu.first-line {
    -webkit-transform-origin: right;
    transform-origin: right;
    transition: -webkit-transform 330ms cubic-bezier(0.54, -0.81, 0.57, 0.57);
    transition: transform 330ms cubic-bezier(0.54, -0.81, 0.57, 0.57);
    transition: transform 330ms cubic-bezier(0.54, -0.81, 0.57, 0.57),
      -webkit-transform 330ms cubic-bezier(0.54, -0.81, 0.57, 0.57);
    -webkit-transform: rotate(-90deg) translateX(3px);
    transform: rotate(-90deg) translateX(3px);
  }
  .line-menu.line-half {
    width: 50%;
  }
  .line-menu {
    width: 100%;
    height: 3px;
    background-color: #204356;
    border-radius: 2px;
    @include themed() {
      background-color: t("whitecolor");
    }
  }
  .navbar-toggler.collapsed .burger-menu .line-menu {
    background-color: #204356;
    @include themed() {
      background-color: t("whitecolor");
    }
  }
  .line-menu.last-line {
    -ms-flex-item-align: end;
    align-self: flex-end;
    -webkit-transform-origin: left;
    transform-origin: left;
    transition: -webkit-transform 330ms cubic-bezier(0.54, -0.81, 0.57, 0.57);
    transition: transform 330ms cubic-bezier(0.54, -0.81, 0.57, 0.57);
    transition: transform 330ms cubic-bezier(0.54, -0.81, 0.57, 0.57),
      -webkit-transform 330ms cubic-bezier(0.54, -0.81, 0.57, 0.57);
    -webkit-transform: rotate(-90deg) translateX(-3px);
    transform: rotate(-90deg) translateX(-3px);
  }
  /* normal */
  .navbar-toggler.collapsed .burger-menu {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  .navbar-toggler.collapsed .burger-menu .line-menu.first-line {
    -webkit-transform: rotate(0deg) translateX(0px);
    transform: rotate(0deg) translateX(0px);
  }
  .navbar-toggler.collapsed .burger-menu .line-menu.last-line {
    -webkit-transform: rotate(0deg) translateX(0px);
    transform: rotate(0deg) translateX(0px);
  }
}
/* Burger Menu End */
