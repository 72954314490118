body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

body {
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none;
}
body::-webkit-scrollbar {
  display: none; /* Safari and Chrome */
}
::-moz-selection {
  /* Code for Firefox */
  color: #173303;
  background: #9fe870;
}

::selection {
  color: #173303;
  background: #9fe870;
}
