@import "../../../../common//assets/scss//main.scss";
// Wallet Fusion 
/* Define the dark theme background color */
:root .theme--dark {
  --background-color: #0a1929;
}

/* Apply the background color */
body {
  background-color: var(--background-color, #ffffff); 
  margin: 0;
  height: 100vh;
}
.theme--dark .wallet-module{
  background-color: #0a1929;
  height: 100vh;
}

.kyc-container {
    background-color: white;
    padding: 3%;
    width: 100%;
    border-radius: 5px;
    box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.15);
  }
  .theme--dark .kyc-container{
    background-color: #02101F;
  }
  
  .form-header {
    text-align: center;
    margin-bottom: 20px;
  }
  .theme--dark .form-header{
    color: white;
  }
  
  .wallet-image {
    width: 100px;
    margin-bottom: 10px;
  }
  
  .submit-buttonwallet {
    margin-top: 2%;
    background-color: #006B04 !important;
    border: #006B04 !important;
    width: 100%;
    margin-bottom: 0%;
    color: white;
    font-weight: 500;
  }
  
  .privacy-policy {
    color: #006B04;
  }
  
 
.textinputwallet .MuiInputBase-input {
  font-weight: 600;
  font-size: 15px;
  border-radius: 10px !important;
  // padding: 5%;
}

.textinputwallet .MuiOutlinedInput-root {
  border-radius: 10px !important;
  margin-bottom: 5px !important;
}

.textinputwallettdark.MuiOutlinedInput-root {
  border-color: #D1D4DB;
  color: white;
  font-weight: 400;
  border-radius: 10px !important;
  margin-bottom: 5px !important;
}

.textinputwallettdark.MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline {
  border-color: #D1D4DB;
  color: white;
  font-weight: 300;
}

.textinputwallettdark .MuiInputLabel-root {
  color: gray;
}
  .accept-text {
    font-size: 10px;
    font-weight: 600;
    margin-top: 7%;
  }
  .theme--dark .accept-text{
    color: white;
  }
  
  .text-walletarrow {
    font-weight: 600;
    padding-top: 10%;
    margin-bottom: 10%;
  }
  .theme--dark .text-walletarrow{
    color: white;
  }