

.money-page {
  padding: 6px 12px !important;
}

.money-filefull {
  background-color: white;
}

.theme--dark .money-filefull {
  background-color: #0a1929;
}

.money-text {
  background-color: #010726;
  padding: 10px;
  border-radius: 0 0 20px 20px;
}

.arrow-icons {
  margin-top: 3%;
}

.money-htag {
  text-align: center;
  color: white;
  margin-top: 10%;
  font-family: "SegoeUI-SemiBold";
}

.card-moneypage {
  margin-bottom: 20px;
  background-color: #085E00;
  color: white;
  font-weight: 500;
  border: none;
  border-radius: 10px;
  padding: 2% !important;
}

.wallet-image {
  display: inline-flex;
  align-items: center;
}

.card-textptag {
  font-size: 12px;
  margin-top: 10%;
  font-family: "SegoeUI";
}

.card-spantet {
  font-size: 20px !important;
}

.link-text {
  color: #006B04;
  margin-top: 4%;
  font-weight: 500;
}

.ptag-verify {
  font-size: 9px;
  padding: 3px !important;
  background-color: #FFF0D4;
  color: #C35E00;
  border-radius: 3px;
  font-weight: 500;
  margin-top: 5px;
  font-family: "SegoeUI";
}

.Fusion-card {
  box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.15);
  margin-top: 5%;
  border-radius: 10px;
  background-color: white !important;
}

.theme--dark .Fusion-card {
  background-color: #02101F !important;
  box-shadow:none;
}

.ptag-money {
  margin-top: 8% !important;
  font-family: "SegoeUI-SemiBold";
}
.theme--dark .ptag-money{
  color: white;
}

.line-container {
  display: flex;
  align-items: center;
  width: 100%;
  margin-top: 10%;
  margin-bottom: 10%;
}

.line {
  border-top: 0.5px solid #767C8A;
  flex-grow: 1;
}

.theme--dark.line {
  border-top: 0.5px solid white;
}

.text {
  margin: 0 15px;
  font-size: 16px;
  color: #767C8A;
  text-align: center;
  font-weight: 500;
  font-family: "SegoeUI";
}

.theme--dark .text {
  color: white;
}

.create-text {
  color: #085E00;
  text-align: center;
  font-weight: 500;
  margin-top: 5px;
  margin-bottom: -2%;
  font-family: "SegoeUI";
}
.theme--dark .create-text{
  color: white;
}

.click-payment1 {
  background-color: #FFEDED;
  border-radius: 7px;
}

.click-payment {
  box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.15);
  padding: 6%;
  border-radius: 7px;
}

.theme--dark .click-payment {
  background-color: #02101F !important;
  box-shadow: none;
}

.image-col {
  display: flex;
  margin-left: -18%;
  height: 90%;
}

.htagfusion-wallet {
  font-size: 18px;
  margin-top: 16%;
  font-weight: 500;
  font-family: "SegoeUI-SemiBold";
}

.ptagfusion-wallet {
  font-size: 12px;
  font-weight: 500;
  color: #767C8A;
  font-family: "SegoeUI";
}
.theme--dark .ptagfusion-wallet{
  color: black;
}

.Gift-cards {
  background-color: #F4F4F4;
}

.claim-text {
  border-bottom: 1px solid #D1D1D1;
}

.claim-textdiv {
  margin-top: 5%;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.15);
  padding: 10px;
}

.food-order {
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.15);
  position: relative;
  padding: 8px;
  margin-top: 10%;
}

.theme--dark .food-order {
  background-color: #02101F !important;
  color: white !important;

}

.food-order h2 {
  font-size: 20px;
  padding-left: 10px;
  margin-left: -7%;
  border-left: 4px solid #218000;
}

.food-items {
  display: flex;
  align-items: center;
  padding: 8px 0;
  border-bottom: 1px solid #DEDEDE;
}

.food-itemslast {
  display: flex;
  align-items: center;
  padding: 8px 0;
}

.text-content {
  flex: 1;
}

.Food-title {
  color: #767C8A;
  font-weight: 500;
}

.theme--dark .Food-title {
  color: white;
}

.payment-settings {
  font-weight: 500;
}

.theme--dark .payment-settings {
  color: white;
}

.payments-full {
  display: flex;
  align-items: center;
  padding: 10px 10px;
  border-radius: 5px;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.15);
  margin-bottom: 10% !important;
}

.theme--dark .payments-full {
  background-color: #02101F !important;
  margin-bottom: 5%;
}
.wallet-image {
  width: 100px;
  margin-bottom: 10px;
  margin-left: none !important;
}
@media only screen and (min-width: 768px) and (max-width: 2440px) {
  .wallet-image {
    width: 100px;
    margin-bottom: 10px;
    margin-left: 10% !important;
  }
  .card-textptag {
    font-size: 16px;
    margin-top: 5%;
    font-family: "SegoeUI";
  }
  .card-spantet {
    font-size: 25px !important;
  }

  .ptag-money {
    margin-top: 3% !important;
    font-size: 20px !important;
    font-family: "SegoeUI-SemiBold";
  }
  .ptag-verify {
    font-size: 12px;
    padding: 4px 20px !important;
    background-color: #FFF0D4;
    color: #C35E00;
    border-radius: 3px;
    font-weight: 500;
    margin-top: 5px; 
    width: max-content;
    font-family: "SegoeUI";
  }
  .Fusion-card {
    box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.15);
    margin-top: 5%;
    border-radius: 10px;
    background-color: white !important;
    margin: 0% !important;
    margin-top: 5% !important;
  }
  
}


.arrow-iconwallet{
  float: left;
  width: 25px !important;
  height: 25px !important;
  margin-right: 1%;
  margin-bottom: auto;
  // margin-top: 8px;
}