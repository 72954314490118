@import "../../../../common//assets/scss//main.scss";

.button-custom {
  background-color: #173303 !important;
  color: white;
  border-color: #173303 !important;
  cursor: pointer;
  font-weight: bold;
  width: 100%;
  margin-bottom: 5% !important;
  margin-top: 10% !important;
}

.theme--dark .rating-module {
  background-color: #02101F !important;
}

.rating-para {
  font-size: 12px;
  font-weight: 500;
  margin: 5px;
  font-family: "SegoeUI";
}

.theme--dark .rating-para {
  color: white;
  font-weight: 400;
}

.rating-htag {
  font-size: 16px;
  margin: 5px;
  font-weight: 600;
  margin-bottom: 5% !important;
  font-family: "SegoeUI-SemiBold";
}

.theme--dark .rating-htag {
  color: white;
}

.center-image {
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.center-imagetop {
  text-align: center;
}

.arrow-rating {
  padding-top: 5%;
  margin-bottom: 4%;
}

@media only screen and (min-width: 540px) and (max-width: 768px) {
  .rating-para {
    font-size: 16px;
    font-weight: 500;
    margin: 5px;
  }

  .rating-htag {
    font-size: 18px;
    margin: 5px;
    font-weight: 600;
    margin-bottom: 5% !important;

  }

  .button-surface {
    text-align: center;
  }

}

@media only screen and (min-width: 769px) and (max-width: 1200px) {
  .rating-para {
    font-size: 18px;
    font-weight: 400;
    margin: 5px;
  }

  .rating-htag {
    font-size: 22px;
    margin: 5px;
    font-weight: 600;
    margin-bottom: 2% !important;
  }

  .button-custom {
    background-color: #173303 !important;
    color: white;
    border-color: #173303 !important;
    cursor: pointer;
    font-weight: bold;
    width: 50% !important;
    margin-bottom: 5%;
    margin-top: 10%;
  }

  .button-surface {
    text-align: center;
  }

  .ratingdiv {
    display: flow !important;
    align-content: center !important;
  }
}

@media only screen and (min-width: 1201px) and (max-width: 2440px) {
  .rating-para {
    font-size: 18px;
    font-weight: 400;
    margin: 5px;
  }

  .rating-htag {
    font-size: 22px;
    margin: 5px;
    font-weight: 600;
    margin-bottom: 2% !important;
  }

  .button-custom {
    background-color: #173303 !important;
    color: white;
    border-color: #173303 !important;
    cursor: pointer;
    font-weight: bold;
    width: 68% !important;
    margin-bottom: 5% !important;
    margin-top: 5% !important;
  }

  .button-surface {
    text-align: center;
  }

  .ratingdiv {
    display: flow !important;
    align-content: center !important;
  }

}

.arrowtop-iconsrating {
  background-color: #e5dddd;
  padding: 4px;
  border-radius: 50px;
  width: 25px !important;
  height: 25px !important;
  margin-top: 10px !important;
  margin-bottom: 10px !important;
  margin-right: 2%;
}

.arrow-iconrating {
  float: left;
  width: 25px !important;
  height: 25px !important;
  margin-right: 1%;
  margin-bottom: auto;
}