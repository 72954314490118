@import "../../../../common/assets/scss/main.scss";
.dark-profile-save #profile-save {
  .modal-content {
    background-color: #0a1929;
    .modal-body {
      padding: 15px;

      p {
        color: #99ccf3;
      }
    }
  }
}
#profile-save {
  @media only screen and (min-width: 350px) {
    max-width: 350px;
    margin-right: auto;
    margin-left: auto;
  }
  border: none;

  .modal-content {
    padding: 10px 10px;
    border: none;
    background-color: #ffffff;
    .modal-body {
      padding: 15px;
      h3 {
        color: $light-green;
        font-family: map-get($map: $segoe-fonts, $key: semibold-600);
        text-align: center;
        font-size: 25px;
        margin: 0px 0px 10px 0px;
        @media only screen and (min-width: 320px) and (max-width: 575px) {
          font-size: 22px;
        }
      }
      p {
        color: $theme-dark1;
        font-family: map-get($map: $segoe-fonts, $key: ui-thin);
        text-align: center;
        font-size: 16px;
        margin: 0px;
      }
    }
    .modal-footer {
      padding: 15px 15px 15px 15px;
      border: none;
      justify-content: center;
      gap: 20px;
      button {
        font-family: map-get($map: $segoe-fonts, $key: semibold-600);
        max-width: 100px;
        width: 100%;
        border: none;
        outline: none;
        font-size: 15px;
        padding: 6px 15px 8px 15px;
        // box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
        &.cancel-btn {
          background-color: $gray-bg;
          color: #444;
        }
        &.save-btn {
          background-color: $light-green;
          color: #fff;
        }
      }
    }
  }
}
