@import "../../common/assets/scss/main.scss";

.leadsignup {
  padding: 80px 0;
  @include themed() {
    background-color: t("dark1");
  }

  .reg-img {
    width: 100%;
  }
  .cooking1 {
    margin-bottom: 2em;
  }
  @media only screen and (min-width: 1024px) {
    .reg-img-res-div {
      display: contents;
    }
  }
  @media only screen and (min-width: 320px) and (max-width: 991px) {
    .cooking1 {
      display: block !important;
      width: 100%;
    }
  }
  @media only screen and (min-width: 992px) {
    .cooking1 {
      display: none !important;
      width: 100%;
    }
  }
  @media only screen and (min-width: 992px) {
    .cooking2 {
      display: block !important;
      width: 100%;
    }
  }
  @media only screen and (min-width: 320px) and (max-width: 991px) {
    .cooking2 {
      display: none !important;
    }
  }
  .form {
    background-color: #f7f7f7;
    padding: 35px 40px;
    border-radius: 5px;
    @include themed() {
      background-color: t("dark3");
    }
    h3 {
      font-family: "Segoe-UI-400";

      text-align: center;
      font-weight: 600;
      color: #204356;
      font-size: 32px;
      margin-bottom: 15px;
      @include themed() {
        color: t("whitecolor");
      }
    }
    p {
      text-align: center;
      color: #204356;
      font-size: 17px;
      margin-bottom: 35px;
      font-family: "Segoe-UI-400";
      @include themed() {
        color: t("whitecolor");
      }
    }

    .form-control {
      padding: 10px 10px 10px 40px;
      color: #b8b8bf;
      box-shadow: none;
      height: 45px;
      border: none;
      font-size: 15px;
      font-weight: 500;
      border-radius: 3px;
      display: block;
    }
    .form-control:invalid {
      padding-right: calc(1.5em + 0.75rem);
      background-repeat: no-repeat;
      background-position: right calc(0.375em + 0.1875rem) center;
      background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
    }
    .submit-form-buttom {
      text-align: center;
    }
    .userimg {
      position: absolute;
      top: 11px;
      left: 8px;
      width: 25px;
    }
    .mailimg {
      position: absolute;
      width: 25px;
      top: 13px;
      left: 8px;
    }
    .formphone {
      margin-bottom: 30px;
      .phoneimg {
        left: 8px;
        position: absolute;
        width: 25px;
        top: 10px;
      }
    }
    h4 {
      font-family: "Segoe-UI-400";
      text-align: center;
      font-weight: 600;
      color: #204356;
      font-size: 30px;
      @include themed() {
        color: t("whitecolor");
      }
    }
    .radio-with-Icon {
      display: flex;
      flex-direction: row;
      justify-content: space-evenly;
      align-items: center;
      .radioOption-Item {
        display: inline-block;
        box-sizing: border-box;
        border: none;
        text-align: center;
        color: #204356;
        font-size: 17px;
        margin-bottom: 35px;
        input.empty {
          opacity: 0 !important;
          width: 0 !important;
          height: 0 !important;
        }
        label {
          display: flex;
          flex-direction: row;
          align-items: center;
          padding: 8px 10px;
          border-radius: 5px;
          border: 1px solid #d7d7d7;
          color: #204356;
          cursor: pointer;
          opacity: 1;
          transition: none;
          font-size: 13px;
          /* padding-top: 25px; */
          text-align: center;
          margin: 0 !important;
          img {
            width: 25px;
            margin-right: 5px;
          }
          .others {
            font-size: 25px;
            @include themed() {
              color: t("pale-green");
            }
          }
          span {
            color: #28be21;
            @include themed() {
              color: t("pale-green");
            }
          }
        }

        input[type="radio"]:checked ~ label {
          opacity: 1;
          border: none;
          background-color: #fff;
          color: #204356;
          box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
          border: 1px solid #204356 !important;
        }
      }
    }
    .submit-form-buttom {
      display: flex;
      flex-direction: row;
      justify-content: center;
      margin-top: 10px;
      button.submit {
        padding: 7px 15px;
        color: #fff;
        background-color: #28be21;
        border: none;
        border-radius: 5px;
        font-size: 16px;
        @include themed() {
          background-color: t("pale-green");
          color: t("dark-green");
        }
      }
    }
  }
  .left-img {
    .reg-img {
      width: 100%;
    }
  }
  @media only screen and (min-width: 320px) and (max-width: 767px) {
    .BannerType1 {
      padding: 8px 4px !important;
    }
  }
  .box-img {
    position: relative;
    padding-bottom: 11px;
  }
}
