.settings-heading {
  font-weight: 500;
  margin-top: 5%;
}

.deleteaccount-modal {
  margin-top: 5%;
}
.delectcontentTag{
  margin-bottom: 15px;
}
.delectcontent-ptag {
  flex: 1;
  font-size: 14px;
  font-weight: 500;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

.arrow-settings {
  float: right;
}

.backto-button {
  color: #006b04 !important;
  background-color: white !important;
  border: none !important;
  width: 100%;
  font-weight: 600;
}

.delete-button {
  color: white !important;
  background-color: #006b04 !important;
  border: #006b04;
  width: 100%;
  margin-bottom: 5%;
}
.delete-contentptag {
  font-weight: 400;
}

.settings-modal {
  position: fixed;
  bottom: -100%;
  left: 0;
  right: 0;
  margin: auto;
  animation: slide-up 0.5s forwards;
  container: 2px;
  background-color: white;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
}

.settings-modaldark {
  background-color: #02111f;
  position: fixed;
  bottom: -100%;
  left: 0;
  right: 0;
  margin: auto;
  animation: slide-up 0.5s forwards;
  container: 2px;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  color: white;
}

@keyframes slide-up {
  from {
    bottom: -100%;
  }

  to {
    bottom: 0%;
  }
}

.backarrow-setting {
  float: left;
    width: 25px !important;
    height: 25px !important;
    margin-right: 1%;
    margin-bottom: auto;
}
.settings-modalwebdark {
  background-color: #02111f;
  color: white;
}
.settings-modalweb {
  background-color: white;
}
// .bottom-modalsettingdesktop{
// // display: inline;
// justify-content: center;
// align-items: center;
// margin-top: 18% !important;
// border-radius: 5px !important;
// }
.card-item {
  // display: flex;
  align-items: center;
  background-color: rgb(243, 243, 243) !important;
  border-radius: 10px;
  margin-bottom: 15px;
  padding: 3%;
  margin-top: 5%;
  font-family: "SegoeUI";
}
.title1{
  display: block;
  font-size: 16px;
  margin-bottom: 5px;
  margin-left: 0px !important;
  font-weight: 600;
  font-family: "SegoeUI";
}