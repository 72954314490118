@import "../../../../common//assets/scss//main.scss";
.theme--dark .language-module{
  background-color: #0a1929;
}
.Arrow-icon-select {
    display: block;
    padding: 5%;
    margin-bottom: -10%;
  }
  
  .language {
    padding: 5%;
  }
  .theme--dark .language{
    color: white;
  }
  
  .estimate {
    margin-top: 5px;
  
    h3 {
      margin-bottom: 10px;
    }
  
    .languageptag {
      display: flex;
      align-items: center;
      background-color: #F5F6FB;
      padding: 10px;
      border-radius: 10px;
      font-weight: 500;
  
      label {
        font-size: 16px !important;
        font-family: "Geist-Regular";
      }
  
      input[type="radio"] {
        box-shadow: 0px 0px 0px 1px #e8e8e8;
        font-size: 3em;
        margin-right: 7px;
        border: 2px solid #fff;
        background-clip: border-box;
        border-radius: 50%;
        appearance: none;
        background-color: #e8e8e8;
        transition: background-color 0.3s, box-shadow 0.3s;
        width: 20px;
        height: 20px;
  
        &:checked {
          // box-shadow: 0px 0px 0px 2px #00eb27;
          // background-color: #51ff6e;
          box-shadow: 0px 0px 0px 2px #173303;
          background-color: #173303;
        }
      }
    }
  }
  .theme--dark .languageptag{
    background-color: #02101F;
    input[type="radio"] {
      box-shadow:none !important;
      font-size: 3em;
      margin-right: 7px;
      border: 2px solid #006B04;
      background-clip: border-box;
      border-radius: 50%;
      appearance: none;
      background-color:#0a1929;
      transition: background-color 0.3s, box-shadow 0.3s;
      width: 20px;
      height: 20px;

      &:checked {
        // box-shadow: 0px 0px 0px 2px #00eb27;
        // background-color: #51ff6e;
        box-shadow: 0px 0px 0px 2px #173303;
        background-color: #006B04;
      }
    }
  }