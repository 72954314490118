//favouriteorder-scss
.favourite-full {
  background-color: white;
}
.arrowtop-icon{
  background-color: #e5dddd;
  padding: 4px;
  border-radius: 50px;
  width: 25px !important;
  height: 25px !important;
  margin-right: 2%;
}
.theme--dark .favourite-full {
  background-color: #0a1929;
  color: white;
}

.favourite-text {
  padding-top: 5%;
  margin-bottom: 3%;
  font-family: "SegoeUI-SemiBold";
}

.favourite-deliverybutton {
  display: flex;
  width: 100%;
  border: 1px solid #7C7C7C;
  border-radius: 10px;
  cursor: pointer;
  color: #7C7C7C;
  text-align: center;
}
.line-container {
  display: flex;
  align-items: center;
  width: 100%;
  margin: 4%;
  // margin-top: 5%;
  // margin-bottom: 5%;
}

.line {
  border-top: 0.5px solid #767C8A;
  flex-grow: 1;
}

.theme--dark.line {
  border-top: 0.5px solid white;
}

.text {
  margin: 0 15px;
  font-size: 16px;
  color: #767C8A;
  text-align: center;
  font-family: 'Geist-UltraLight';
  font-weight: bold;
}

.theme--dark .text {
  color: white;
}
.favourite-option {
  flex: 1;
  padding: 3px;
  transition: background-color 0.3s;
  font-weight: 600;
  font-family: "SegoeUI";
}

.favourite-option.active {
  color: black;
  border: 1px solid #006B04;
  border-radius: 10px;
  font-family: "SegoeUI";
}

.theme--dark .favourite-option.active {
  color: black !important;
  background-color: #F5FFF6 !important;
  border-radius: 10px;
  font-family: "SegoeUI";
}

.favourite-image {
  // width: 18px;
  // height: auto;
  // gap: 3px !important;
}

.restaurant-card {
  border: none;
  position: relative;
  overflow: hidden;
  box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.15) !important;
  margin-bottom: 5% !important;
}

.card-bodyfavourite {
  box-shadow: inset 0px -1px 1px 1px rgba(107, 104, 104, 0.15) !important;
  border-bottom-left-radius: 10px !important;
  border-bottom-right-radius: 10px !important;
}

.theme--dark .card-bodyfavourite {
  background-color: #02101F;
  box-shadow: inset 0 1px 5px 0px #218000 !important;
}

.image-container {
  position: relative;
}

.restaurant-image {
  height: 250px;
  object-fit: cover;
}

.heart-icon {
  position: absolute;
  top: 10px;
  right: 10px;
  color: red;
  font-size: 1.5rem;
  cursor: pointer;
}

.text-description {
  font-size: 12px;
  color: #7C7C7C;
  border-bottom: 1px dotted #7C7C7C !important;


}

// .info {
//   display: flex;
//   justify-content: space-between;
//   margin-top: 0.5rem;
// }

.info-item {
  position: absolute;
  font-size: 12px;
  color: gray;
  top: 91%;
  background-color: white;
  border-top-right-radius: 10px;
  padding: 0 8px 0px 10px;
 border-left:1px solid #e7e0e0 ; 
}

.theme--dark .info-item {
  background-color: #02101F;
  color: gray;
}

.ratingstar {
  float: right;
  font-weight: 400;
  color: white !important;
  background-color: #006B04;
  border-radius: 4px;
  margin: 0px;
  padding: 2px 4px;
  text-align: center;
}
.star-iconsrating{
  margin-bottom: 3px;
}

.restaurant-namerating {
  margin: 0px !important;
  margin-bottom: 0px !important;
  font-family: 'Geist-Regular';
}

.discount-btn {
  color: #1170EF;
  font-size: 13px;
  margin-bottom: 2px;
  font-weight: 700;
  margin-top: 3%;
  font-family: "SegoeUI";
}

.discount-image {
  margin-right: 2%;
}

.text-res {
  font-size: 12px;
  border-bottom: 1px dotted;
  margin: 0px;
  padding: 3%;
  color: #7C7C7C;
  font-family: "SegoeUI-Light";
}
.card-container {
  display: grid;
  grid-template-columns: 1fr;
  gap: 20px;
}

@media (min-width: 768px) {
  .card-container {
      grid-template-columns: 1fr 1fr;
  }
}

@media (min-width: 1024px) {
  .card-container {
      grid-template-columns: 1fr 1fr;
  }
}

.arrow-iconfavourite{
  float: left;
  width: 25px !important;
  height: 25px !important;
  margin-right: 1%;
  margin-bottom: auto;
}