@import "../../assets/scss/main.scss";
.feedback{
    // position: fixed;
    // z-index: 999;
    // top: 45%;
    // right: -2%;
    // float: right !important;
    // display: flex;
    // background-color: black;
    // border: 0px solid black;
    // color: white;
    // -webkit-transform: rotate(-90deg);
    position: fixed;
    z-index: 10001;
    top: 45%;
    right: 0%;
.feedback-button{
    background-color: rgb(17, 17, 17);
    color: white;
    -webkit-transform: rotate(-90deg);
    height: 7%;
    transform-origin: right bottom;
    border-top-right-radius: 15px;
    border-top-left-radius: 15px;
    font-size: 14px;
    font-family: 'Segoe-UI-Semibold-600';
    padding: 11px;
}
.form {
    // gggg
    padding: 15px;
    width: 225px;
    min-height: 200px;
    border-radius: 10px;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.1);
    background-color: #fff;
    form {
      margin: 0;
    }
    .close-btn{
        float: right;
        svg{
            color: #dc3545;
        }
    }
    .form-text{
        font-size: 14px;
    }
    textarea {
      margin: 0;
      height: auto;
      padding: 0.5rem 0.75rem;
      display: block;
      max-width: 100%;
      border: 1px solid #d2ddec;
      background-clip: padding-box;
      color: #12263f;
      background-color: #fff;
      border-radius: 0.375rem;
      overflow: auto;
      resize: vertical;
      height: 80px;
      font-size: 0.9em;
      &:focus {
        color: #12263f;
        background-color: #fff;
        border-color: #2c7be5;
        outline: 0;
        box-shadow: transparent;
      }
    }
    button {
      margin-top: 10px;
      display: block;
      width: 100%;
      color: #fff;
      cursor: pointer;
      background-color: #28be21;
      padding: 0.125rem 0.5rem;
      font-size: 0.8125rem;
      line-height: 1.75;
      border-radius: 0.25rem;
      text-align: center;
      vertical-align: middle;
      border: 1px solid transparent;
    }
  }

}