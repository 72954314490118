@import "../../../../../common/assets/scss/main.scss";

.address-book {
  padding: 12px 0px;
  .no-address-img {
    padding: 130px 0px 25px 0px;
    display: flex;
    flex-direction: column;
    align-items: center;
    img {
      width: 250px;
    }
    h3 {
      font-family: map-get($map: $segoe-fonts, $key: ui-bold);
      color: $theme-dark1;
      font-size: 24px;
      margin: 10px 0px;
      text-align: center;
      @include themed() {
        color: t("light-text");
      }
    }
  }
  .address-box {
    border: 1px solid $gray-bg;
    margin-bottom: 30px;
    border-radius: 5px;
    @include themed() {
      border-color: t("b-color");
    }
    &:hover {
      box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
      @include themed() {
        box-shadow: t("none");
      }
    }
    .table-responsive {
      table {
        margin: 0px;
        tbody {
          tr {
            &:first-child {
              td {
                &:nth-child(1) {
                  width: 15%;
                  background-color: #eef6ff;
                  border-radius: 5px 0px 0px 5px;
                  @include themed() {
                    background-color: t("hoverbg");
                  }
                }
                &:nth-child(2) {
                  width: 25%;
                  border-radius: 5px 0px 0px 5px;
                }
                &:nth-child(3) {
                  width: 35%;
                }
                &:nth-child(4) {
                  width: 25%;
                }
              }
            }
            td {
              border: none;
              padding: 12px;
              vertical-align: middle;
              .img-box {
                display: flex;
                flex-direction: column;
                align-items: center;
                padding: 10px 0px;
                .address-type {
                  background-color: transparent;
                  padding: 0px;
                  margin: 10px 0px 0px 0px;
                  font-family: map-get($map: $segoe-fonts, $key: ui-bold);
                  display: flex;
                  flex-direction: row;
                  align-items: center;
                  gap: 5px;
                  border: none;
                  outline: none;
                  &.home {
                    color: #32c070;
                    svg {
                      color: #32c070;
                    }
                  }
                  &.office {
                    color: #276cf6;
                    svg {
                      color: #276cf6;
                    }
                  }
                  &.other {
                    color: #d92662;
                    svg {
                      color: #d92662;
                    }
                  }
                  // &:hover{

                  // }
                  svg {
                    cursor: pointer;
                    color: $theme-dark1;
                  }
                }
              }
              .primary-btn {
                background-color: #204356;
                padding: 9px;
                display: block;
                text-align: center;
                margin: 0px -12px -12px -12px;
                border-radius: 0px 0px 0px 5px;
                color: #fff;
                font-family: map-get($map: $segoe-fonts, $key: ui-semibold);
                @include themed() {
                  background-color: t("green2");
                }
              }
              .text-group {
                span {
                  font-family: map-get($map: $segoe-fonts, $key: ui-semibold);
                  color: #939393;
                  font-size: 14.5px;
                  @include themed() {
                    color: t("hovercolor");
                  }
                }
                p {
                  margin: 0px;
                  font-family: map-get($map: $segoe-fonts, $key: ui-semibold);
                  color: $theme-dark1;
                  font-size: 16px;
                  @include themed() {
                    color: t("whitecolor");
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  .add-address-div {
    display: flex;
    justify-content: center;
    margin: 10px 0px;
    .addr-add-btn {
      color: #173303;
      background-color: #9fe870;
      padding: 10px 30px;
      text-decoration: none;
      font-family: map-get($map: $segoe-fonts, $key: ui-semibold);
      border-radius: 5px;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 10px;
      font-size: 17px;
      border: none;
      outline: none;

      svg {
        color: #173303;
        fill: #173303;
      }
    }
  }

  .address-box-mob {
    margin-bottom: 20px;
    border: 1px solid #eef6ff;
    border-radius: 5px;
    @include themed() {
      border-color: t("green2");
    }
    .heading-box {
      display: flex;
      flex-direction: row;
      .img-box {
        background-color: #eef6ff;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 40%;
        padding: 10px 0px 0px 0px;
        height: 150px;
        border-radius: 5px 0px 0px 5px;
        @include themed() {
          background-color: t("hoverbg");
        }
        button.address-type {
          background-color: transparent;
          padding: 0px;
          margin: 10px 0px 0px 0px;
          font-family: map-get($map: $segoe-fonts, $key: ui-bold);
          display: flex;
          flex-direction: row;
          align-items: center;
          gap: 5px;
          border: none;
          outline: none;
          font-size: 15px;
          &.home {
            color: #32c070;
            svg {
              color: #32c070;
            }
          }
          &.office {
            color: #276cf6;
            svg {
              color: #276cf6;
            }
          }
          &.other {
            color: #d92662;
            svg {
              color: #d92662;
            }
          }
          // &:hover{

          // }
          svg {
            cursor: pointer;
            color: $theme-dark1;
          }
        }
        .primary-btn {
          background-color: #204356;
          padding: 6px;
          display: block;
          text-align: center;
          margin: 0px;
          color: #fff;
          font-family: map-get($map: $segoe-fonts, $key: ui-semibold);
          width: 100%;
          font-size: 14px;
          margin-top: 5px;
          border-radius: 0px 0px 0px 5px;
          margin-bottom: -1px;
          @include themed() {
            background-color: t("green2");
          }
        }
      }
      .name-number {
        width: 60%;
        padding-left: 10px;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        span {
          border-bottom: 0px;
          margin: 0px;
          font-family: map-get($map: $segoe-fonts, $key: ui-semibold);
          color: $theme-dark1;
          font-size: 14px;
          @include themed() {
            color: t("whitecolor");
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 320px) and (max-width: 420px) {
  .address-book {
    @include themed() {
      background-color: t("dark4");
    }
    .address-box-mob .heading-box {
      .name-number {
        padding-left: 25px;
      }
      .img-box {
        img {
          width: 65px;
          height: 65px;
          object-fit: contain;
        }
        .primary-btn {
          margin-top: 8px;
        }
      }
    }
    .add-address-div .addr-add-btn {
      font-size: 15px;
      padding: 8px 20px;
    }
    .no-address-img {
      padding: 25px 0px;

      img {
        width: 150px;
      }
      h3 {
        font-size: 16px;
      }
    }
  }
}
@media only screen and (min-width: 421px) and (max-width: 767px) {
  .address-book {
    @include themed() {
      background-color: t("dark4");
    }
    .address-box-mob .heading-box {
      .name-number {
        padding-left: 25px;
      }
      .img-box {
        img {
          width: 65px;
          height: 65px;
          object-fit: contain;
        }
        .primary-btn {
          margin-top: 9px;
        }
      }
    }
    .add-address-div .addr-add-btn {
      font-size: 16px;
      padding: 8px 20px;
    }
    .no-address-img {
      padding: 25px 0px;

      img {
        width: 150px;
      }
      h3 {
        font-size: 18px;
      }
    }
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .address-book {
    .address-box .table-responsive table tbody tr {
      &:first-child {
        td {
          &:nth-child(1) {
            vertical-align: bottom;
          }
        }
      }
      td {
        padding: 8px;
        vertical-align: top;
        .img-box {
          img {
            width: 50px;
          }
          button.address-type {
            font-size: 14px;
          }
        }
        .text-group {
          span {
            font-size: 12.5px;
          }
          p {
            font-size: 13px;
          }
        }
        .primary-btn {
          padding: 5px 10px;
          margin: 0px -8px -8px -8px;
          font-size: 14px;
        }
      }
    }
    .add-address-div .addr-add-btn {
      font-size: 15px;
      padding: 8px 22px;
    }
    .no-address-img {
      padding: 185px 0px 25px 0px;

      img {
        width: 150px;
      }
      h3 {
        font-size: 18px;
      }
    }
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .address-book {
    .address-box .table-responsive table tbody tr {
      &:first-child {
        td {
          &:nth-child(1) {
            vertical-align: bottom;
          }
        }
      }
      td {
        padding: 10px;
        vertical-align: top;
        .img-box {
          img {
            width: 55px;
          }
          button.address-type {
            font-size: 15px;
          }
        }
        .text-group {
          span {
            font-size: 13.5px;
          }
          p {
            font-size: 14px;
          }
        }
        .primary-btn {
          padding: 5px 10px;
          margin: 0px -10px -10px -10px;
          font-size: 14.5px;
        }
      }
    }
    .add-address-div .addr-add-btn {
      font-size: 15.5px;
      padding: 10px 22px;
    }
    .no-address-img {
      padding: 150px 0px 25px 0px;

      img {
        width: 200px;
      }
      h3 {
        font-size: 20px;
      }
    }
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .address-book {
    .add-address-div .addr-add-btn {
      font-size: 16px;
      padding: 10px 20px;
    }
    .no-address-img {
      padding: 150px 0px 25px 0px;

      img {
        width: 225px;
      }
      h3 {
        font-size: 23px;
      }
    }
  }
}

