.top-to-btm {
  position: relative;
}
.top-to-btm .icon-position {
  position: fixed;
  bottom: 40px;
  right: 25px;
  z-index: 20;
}
.top-to-btm .icon-style {
  /* background-color: var(--fgreen2); */
  background: -webkit-linear-gradient(top, rgb(69, 206, 62) 0.96%, rgb(69, 206, 62) 0.96%);
  background: -o-linear-gradient(top, rgb(69, 206, 62) 0.96%, rgb(69, 206, 62) 0.96%);
  background: linear-gradient(to top, rgb(69, 206, 62) 0.96%, rgb(69, 206, 62) 0.96%);
  border-radius: 50%;
  height: 40px;
  width: 40px;
  color: #fff;
  cursor: pointer;
  animation: movebtn 3s ease-in-out infinite;
  transition: all 0.5s ease-in-out;
  border: none;
  /* box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px; */
  display: flex;
  justify-content: center;
  align-items: center;
}
.top-to-btm .icon-style:hover {
  animation: none;
  /* background: var(--fgreen); */
  background: -webkit-linear-gradient(bottom, rgb(69, 206, 62) 0.96%, rgb(69, 206, 62) 0.96%);
  background: -o-linear-gradient(bottom, rgb(69, 206, 62) 0.96%, rgb(69, 206, 62) 0.96%);
  background: linear-gradient(to bottom, rgb(69, 206, 62) 0.96%, rgb(69, 206, 62) 0.96%);
}
@keyframes movebtn {
  0% {
    transform: translateY(0px);
  }
  25% {
    transform: translateY(15px);
  }
  50% {
    transform: translateY(0px);
  }
  75% {
    transform: translateY(-15px);
  }
  100% {
    transform: translateY(0px);
  }
}
.top-to-btm button svg {
  width: 13px;
  fill: #fff;
}
@media only screen and (min-width: 320px) and (max-width: 752px) {
  .top-to-btm .icon-position {
    position: fixed;
    bottom: 86px;
    right: 25px;
    z-index: 20;
  }
}