@import "../../../../common//assets/scss//main.scss";
.your-profile {
    background-color: white;
    padding: 3%;
    border-radius: 20px;
    box-shadow: 0px 0px 13px 0px rgba(0, 0, 0, 0.15);
  // margin-top: 10%;
  }
  .theme--dark .your-profile{
    background-color: #02101F;
    color: white;
    box-shadow: none;
  }
  
.theme--dark .userprofilefull{
  background-color: #0a1929 !important;
  color: white !important;
  height: 100vh !important; 
}

 
  .arrow-iconprofile{
    float: left;
    width: 25px !important;
    height: 25px !important;
    margin-right: 1%;
    margin-bottom: auto;
  }
  
  .arrow-text {
    padding-top: 10%;
    margin-bottom: 10%;
    margin-left: 10px;
  }
  .profile-imageupload{
    display: flex;
    justify-content: center;
  }
  
  .profile-picture {
    position: relative ;
    width: 100px;
    height: 100px;
    border-radius: 50%;
    background-color: #DCE8FF;
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    margin-bottom: 10%;
    border: white;
  }
  
  .profile-initial {
    font-size: 41px;
    color: #666;
    margin-bottom: 5px;
  }
  
  .edit-icon {
    margin-top: 30%;
    background-color: white;
    padding: 2px;
    margin-top: 90px;
    border-radius: 5px;
  
  }
  
  .text-success {
    color: #28a745 !important;
    text-transform: none;
    font-size: 14px;
    margin-top: 14px;
    font-weight: 600;
  }
  
  .custom-clear-icon {
    background-color: #B0B4C0 !important;
    border-radius: 50%;
    color: white !important;
    padding: 1px !important;
  }
  
  .form-group-spacing {
    margin-bottom: 1rem;
  
  }
  
  .textinputprofile .MuiInputBase-input {
    font-weight: 600;
    font-size: 16px;
    border-radius: 10px !important;
    font-family: "SegoeUI-Light"; 
    // padding: 5%;
  }

  .textinputprofile .MuiOutlinedInput-root{
    border-radius: 10px !important;
    margin-bottom: 7% !important;
    font-family: "SegoeUI-Light";
  }

.theme--dark .textinputprofile .MuiOutlinedInput-root {
  border-color: #D1D4DB;
  color: white;
  font-weight: 400;
}

.theme--dark .textinputprofile .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline {
  border-color: #D1D4DB;
  color: white;
  font-weight: 300;
}

.theme--dark .textinputprofile .MuiInputLabel-root {
  color: gray;
}
  
  .Update-profile {
    background-color: #173303 !important;
    color: white !important;
    border: #173303 !important;
    width: 100% !important;
    margin-top: 5%;
    font-weight: 600;
    margin-bottom: 5% !important;
    font-family: "SegoeUI";
    border-radius: 12px;
  }
  @media only screen and (min-width: 768px) and (max-width: 2440px) {
    .Update-profile {
      background-color: #173303 !important;
      color: white !important;
      font-size:larger !important;
      border: #173303 !important;
      width: 50% !important;
      margin-top: 5%;
      font-weight: 600;
      margin-bottom: 5% !important;
      padding: 1.5% !important;
      font-family: "SegoeUI";
    }
    .textinputprofile .MuiInputBase-input {
      font-weight: 600;
      font-size: 16px;
      border-radius: 10px !important;
      padding: 4% !important;
      font-family: "SegoeUI-Light";
    }
  }