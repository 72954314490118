// addcardV3.scss

.add-card {
    .heading {
      margin-bottom: 20px !important;
      margin-top: 10px !important;
    }
  
    .form-addcard {
      background: #fff;
      padding: 20px;
      border-radius: 10px;
      box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
      max-width: 400px;
      margin: 0 auto;
  
      .mb-3 {
        margin-bottom: 15px;
  
        label {
          display: block;
          margin-bottom: 5px;
          font-weight: bold;
        }
  
        input {
          width: 100%;
          padding: 10px;
          border: 1px solid #ddd;
          border-radius: 5px;
        }
      }
  
      .submit-btn {
        text-align: center;
  
        button {
          width: 100%;
          padding: 10px;
          background-color: #015F00;
          border: none;
          border-radius: 5px;
          color: #fff;
          font-size: 16px;
          display: flex;
          align-items: center;
          justify-content: center;
          font-weight: 500;
  
          svg {
            margin-right: 5px;
          }
        }
      }
    }
  }

  .theme--dark .form-addcard{
    background-color: #15283b !important;
    color: white;
  
  }
  .theme--dark .heading{
    color: white;
  }
  
   .card-image {
    position: relative;
    bottom: 0;
    right: 0;
    width: auto;
    padding: 20px;
  }
  .theme--dark .add-card{
    background-color: #0a1929;
    color: white;
  }
  
    .InputElement {
      font-size: 16px;
      font-family: 'SegoeUI-SemiBold';
      color: #204356 !important;
  
      &::placeholder {
        color: #aab7c4; // Adjust placeholder color if needed
      }
  
      &:focus {
        border-color: #66afe9;
        outline: none;
        box-shadow: 0 0 5px rgba(102, 175, 233, 0.6);
      }
  
      &.invalid {
        color: #fa755a;
        border-color: #fa755a;
  
        &::placeholder {
          color: #fa755a;
        }
      }
    }
    .theme--dark .InputElement{
      font-size: 16px;
      font-family: 'SegoeUI-SemiBold';
      color: white !important;
  
      &::placeholder {
        color: #aab7c4;
      }
  
      &:focus {
        border-color: #66afe9;
        outline: none;
        box-shadow: 0 0 5px rgba(102, 175, 233, 0.6);
      }
  
      &.invalid {
        color: #fa755a;
        border-color: #fa755a;
  
        &::placeholder {
          color: #fa755a;
        }
      }
    }

  
@media only screen and (min-width: 768px) and (max-width: 1440px) {
    .add-card {
        .heading {
          margin-bottom: 20px !important;
          margin-top: 10px !important;
        }
      
        .form-addcard {
    //   background: #fff;
          padding: 20px;
          border-radius: 10px;
        //   box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
          max-width: 400px;
          margin: 0 auto;
          background-color: #FAFAFA;
          margin-top: 15% !important;
      
          .mb-3 {
            margin-bottom: 15px;
      
            label {
              display: block;
              margin-bottom: 5px;
              font-weight: bold;
              font-size: 20px !important;
            }
      
            input {
              width: 100%;
              padding: 10px;
              border: 1px solid #ddd;
              border-radius: 5px;
            }
          }
      
          .submit-btn {
            text-align: center;
      
            button {
              width: 100%;
              padding: 10px;
              background-color: #015F00;
              border: none;
              border-radius: 5px;
              color: #fff;
              font-size: 20px !important;
              display: flex;
              align-items: center;
              justify-content: center;
              font-weight: 500;
      
              svg {
                margin-right: 5px;
              }
            }
          }
        }
      }
      .card-image {
        position: absolute;
width: 70%;
      }
      }