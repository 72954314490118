@import "../../../../common//assets/scss//main.scss"; 
.feedback-form-container {
    margin: auto;
    padding: 20px;
    border-radius: 10px;
    background-color: #ffffff;
  }
  .theme--dark .feedback-form-container{
    background-color: #0a1929;
    color: white;
    border-radius: 0%;
  } 
  .feedback-form-container h2 {
    margin-bottom: 10px;
    font-size: 20px !important;
    margin-top: 5%;
    font-family: "SegoeUI-SemiBold";
  }
  
  .feedback-form-container p {
    color: #767C8A;
    font-size: 14px !important;
    font-weight: 500;
    font-family: "SegoeUI";
  }
  
  .form-groupfeedback{
    margin-bottom: 20px;
    border-bottom: 1px solid #E8E9EE !important;
  }
  .theme--dark .form-groupfeedback{
    border-bottom: 1px solid #767C8A;
  }
  
  .form-controlfeedback {
    width: 100%;
    padding: 10px;
    border: none !important;
    border-radius: none !important;
    font-family: "SegoeUI";
  }
   .theme--dark .form-controlfeedback{
    background-color:#0a1929 !important; 
    border: none !important;
    color: white;
    border-radius: none !important;
   }

  .help-card {
    margin-bottom: 60% !important;
    padding: 3%;
    box-shadow: 0px 0px 13px 0px rgba(0, 0, 0, 0.15) !important;
    border-radius: 15px !important;
    background-color: white !important;
    display: flex;
    align-items: center;
  
  }
  .theme--dark .help-card{
    background-color: #02111f !important;
    color: white !important;
  } 
  
  .help-card h3 {
    margin-top: 0;
    font-size: 14px;
    margin-bottom: 0px;
    font-family: "SegoeUI-SemiBold";
  }
  
  .help-card p {
    margin-top: 0;
    font-size: 12px;
    font-family: "SegoeUI";
  }
  
  .feedback-icon {
    height: auto;
    margin-right: 10px;
  }
  
  .text-container {
    flex: 1;
    margin-top: 2%;
  }
  
  .submitbuttonnew {
    width: 100%;
    padding: 10px;
    border: none;
    border-radius: 12px;
    background-color: #173303 !important; 
    color: #ffffff !important;
    font-size: 16px;
    cursor: pointer;
    font-weight: 500;
    margin-bottom: 3%;
    font-family: "SegoeUI-SemiBold";
  }
  
  .submitbuttonnew:hover {
    background-color: #218838;
  }
  .arrowtop-icon{
    background-color: #e5dddd;
    padding: 3px ;
    border-radius: 50px;
    margin-right: 4px;
  }
  @media only screen and (min-width:768px) and (max-width: 2440px) {
    .submitbuttonnew {
      display: block;
      justify-content: center;
      width: 70%;
      padding: 10px;
      border: none;
      border-radius: 12px;
      background-color: #173303 !important; 
      color: #ffffff !important;
      font-size:large;
      cursor: pointer;
      font-weight: 500;
      margin-bottom: 3%;
    }
    .submit-feedback{
    display: flex;
    justify-content: center;
    }
    .help-card {
      margin-bottom: 20% !important;
      padding: 3% !important;
      box-shadow: none !important;
      background-color: #F8F8F8 !important;
      border-radius: 15px !important;
      background-color: white;
      display: flex;
      align-items: center;
    
    }
    .form-controlfeedback {
      width: 100%;
      padding: 10px;
      border: none !important;
      background-color: #F8F8F8 !important;
      border-radius: 10px !important;
      font-size: large !important;
    }
    .feedback-form-container h2 {
     font-size: x-large !important;
      
    }
    .feedback-form-container p {
      font-size: medium !important;
    }
    .form-groupfeedback{
      margin-bottom: 20px;
      border-bottom: none !important;
    }
    }
  .arrow-iconfeedback{
    float: left;
    width: 25px !important;
    height: 25px !important;
    margin-right: 1%;
    margin-bottom: auto;
  }
