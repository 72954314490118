@import "../../../common/assets/scss/main.scss";

.top-to-btmss {
    position: relative;

    .showcartitem {
      position: fixed;
      bottom: 6px;
      left: 6px;
      width: 97%;
      background-color: #ffffff;
      box-shadow: 0 -2px 6px rgba(0, 0, 0, 0.1);
      z-index: 999;
      border-radius: 10px;
      display: none;
      transition: opacity 0.3s ease-in-out;
      @include themed() {
        background-color: t('dark4');
      }
      display: none;
      transition: opacity 0.3s ease-in-out;

      .showcartitemchild{
        display: flex;
        justify-content: space-evenly;
        height: 58px;
        align-items: center;
        gap: 23px;
        background: #9fe870;
        box-shadow: 0px 0px 10px #ccc;
        border-radius: 11px;


        .closicon{
          background: #ffeef0;
          color: #ef5f8a;
          border-radius: 50%;
          width: 26px;
          height: 26px;
          display: flex;
          justify-content: center;
          align-items: center;
        }
        .cartbtn{
          background: #ffffff;
          padding: 4px;
          border-radius: 10px;
          text-align: center;
      
          h6{
            font-size: 14px;
            margin: 0px;
            font-family: map-get($map: $segoe-fonts, $key: ui-semibold);

          }
        }
      }

      .shocartimg{
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 14px;

        svg{
          font-size: 22px;
        }

        .restname{
          display: flex;
          flex-direction: column;

          h6{
            font-size: 14px;
            margin: 0px;
            font-family: map-get($map: $segoe-fonts, $key: ui-semibold);
          }
          .menuitme{
            display: flex;
            justify-content: center;
            align-items: baseline;
            gap: 6px;
          }
        }
        img {
          border-radius: 50%;
          height: 50px;
        };  
      }
    }
    .showcartitem.show {
      display: block;
      animation: slideIn 0.3s forwards;
    }
    @keyframes slideIn {
      from {
        transform: translateY(100%);
        opacity: 0;
      }
      to {
        transform: translateY(0);
        opacity: 1;
      }
    }
    span{
      background: #173303;
      position: absolute;
      top: -9px;
      right: -1px;
      border-radius: 50%;
      width: 21px;
      height: 21px;
      font-size: 13px;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #fff;
        @include themed() {
        background: t("dark4");
        color: t("whitecolor");
      }
    }
  }
  .top-to-btmss .icon-position {
    position: fixed;
    bottom: 10px;
    right: 10px;
    z-index: 20;
  }
  .top-to-btmss .icon-style {
    /* background-color: var(--fgreen2); */
    background: -webkit-linear-gradient(bottom, #9fe870 0%, #9fe870 100%);
    background: -o-linear-gradient(bottom, #9fe870 0%, #9fe870 100%);
    background: linear-gradient(to bottom, #9fe870 0%, #9fe870 100%);
    border-radius: 50%;
    height: 45px;
    width: 45px;
    color: #173303;
    cursor: pointer;
    // animation: movebtn 3s ease-in-out infinite;
    transition: all 0.5s ease-in-out;
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
    /* box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px; */
  }
  .top-to-btmss .icon-style:hover {
    animation: none;
    /* background: var(--fgreen); */
    background: -webkit-linear-gradient(top, #9fe870 0%, #9fe870 100%);
    background: -o-linear-gradient(top, #9fe870 0%, #9fe870 100%);
    background: linear-gradient(to top, #9fe870 0%, #9fe870 100%);
  }
  @keyframes movebtn {
    0% {
      transform: translateY(0px);
    }
    25% {
      transform: translateY(15px);
    }
    50% {
      transform: translateY(0px);
    }
    75% {
      transform: translateY(-15px);
    }
    100% {
      transform: translateY(0px);
    }
  }
  .top-to-btmss button svg {
    font-size: 19px;
    fill: #fff;
    // transform: rotate(270deg);
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%) ;
    @include themed(){
      fill: #173303;
    }
  }
  